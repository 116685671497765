// About.js
import React, {useContext} from 'react';
import {View, Text, ScrollView} from 'react-native';
import {
  widthPercentageToDP as wp,
  heightPercentageToDP as hp,
} from 'react-native-responsive-screen';

import {AuthContext} from '../AuthProvider';

import Header from './Header';
import Footer from './Footer';

import {default as commonStylesDesktop} from '../../styles/common';
import {default as commonStylesMobile} from '../../styles/common_mobile';

const About = ({navigation}) => {
  const {t, theme, screenWidth} = useContext(AuthContext);
  let commonStyles =
  screenWidth < 1080
    ? commonStylesMobile(wp, hp, theme)
    : commonStylesDesktop(wp, hp, theme);
  return (
    <ScrollView contentContainerStyle={commonStyles.container}>
      <Header navigation={navigation} />
      <View style={commonStyles.contentContainer}>
        <Text>Про нас</Text>
      </View>
      <Footer navigation={navigation} />
    </ScrollView>
  );
};

export default About;