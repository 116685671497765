import {Dimensions, Platform, StyleSheet} from 'react-native';

let screenHeight = Dimensions.get('screen').height - 200;
// console.log('load invitation styles');

const platformStyles = {
  web: (wp, hp, theme) =>
    StyleSheet.create({
      modalContainer: {
        flex: 1,
        position: 'fixed',
        top: 0,
        alignItems: 'center',
        alignSelf: 'center',
        width: wp('100%'),
        minHeight: screenHeight,
        height: '100%',
        backgroundColor: '#0000001A',
        zIndex: 10,
      },
      modalContent: {
        top: '5%',
        width: 360,
        // height: 307,
        borderRadius: 40,
        backgroundColor: '#FFF',
        zIndex: 11,
        paddingBottom: 64,
      },
      modalHead: {
        alignItems: 'flex-end',
        height: 64,
      },
      modalBody: {
        width: 267,
        // height: 222,
        alignItems: 'center',
        // justifyContent: 'center',
        alignSelf: 'center',
        gap: 23,
      },
      modalCaption: {
        height: 48,
        fontFamily: theme.FONT_FAMILY_MEDIUM,
        fontSize: 40,
        fontWeight: 'normal',
        lineHeight: 48,
        textAlign: 'left',
        marginTop: -10,
      },
      iconClose: {
        width: 20,
        height: 20,
        marginTop: 32,
        marginRight: 32,
      },
      inputContainer: {
        width: 251,
        height: 60,
      },
      inputLabel: {
        fontFamily: theme.FONT_FAMILY_MEDIUM,
        fontSize: 12,
        fontWeight: 'normal',
        lineHeight: 15,
        textAlign: 'left',
        color: theme.FONT_COLOR_BLUE,
      },
      input: {
        width: 250,
        height: 42,
        borderWidth: 1,
        borderRadius: 10,
        borderColor: '#707070',
        padding: 10,
        fontFamily: theme.FONT_FAMILY_MEDIUM,
        fontSize: 16,
        fontWeight: 'normal',
        lineHeight: 19,
        textAlign: 'left',
      },
      modalBtnGroup: {
        gap: 10,
      },
      modalBtn: {
        width: 114,
        height: 39,
        borderRadius: 10,
        backgroundColor: theme.BLACK,
        paddingVertical: 10,
        paddingHorizontal: 20,
        fontFamily: 'Inter',
        fontSize: 16,
        fontWeight: 'normal',
        lineHeight: 19,
        textAlign: 'center',
        alignItems: 'center',
        justifyContent: 'center',
      },
      btnTextWhite:{
        fontFamily: theme.FONT_FAMILY,
        color: theme.FONT_COLOR_WHITE,
        fontSize: 16,
      },
      headerContainer: {
        flexDirection: 'row',
        justifyContent: 'flex-start',
        width: wp('100%'),
        marginBottom: 15,
      },
      headBackButton: {
        height: 50,
        width: wp('15%'),
        maxWidth: 70,
        backgroundColor: theme.BLACK,
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: 10,
        // marginRight: wp('10%'),
        borderTopEndRadius: 20,
        borderBottomEndRadius: 20,
      },
      contentHead: {
        marginTop: 10,
        flexGrow: 1,
        flexShrink: 0,
        height: 50,
        width: 200,
        position: 'absolute',
        left: wp('50%'),
        marginLeft: -100,
        maxWidth: 275,
        backgroundColor: theme.BLUE,
        borderRadius: 20,
        alignItems: 'center',
        justifyContent: 'center',
        // marginBottom: 15,
      },
      arrowLeft: {
        width: 40,
        height: 40,
      },
      contentHeadText: {
        fontFamily: theme.FONT_FAMILY_MEDIUM,
        color: theme.FONT_COLOR_WHITE,
        fontSize: 24,
        lineHeight: 24,
        textAlign: 'center',
      },
      btnGroup: {
        marginTop: 31,
        gap: 2,
        marginBottom: 32,
        flex: 1,
      },
      btnProfile: {
        width: wp('25%'),
        maxWidth: 145,
        height: 39,
        paddingHorizontal: 5,
        paddingVertical: 10,
        borderWidth: 1,
        borderStyle: 'solid',
        borderRadius: 10,
        // borderColor: theme.BLACK,
        backgroundColor: theme.BLACK,
        alignItems: 'center',
        justifyContent: 'center',
      },
      btnProfileText: {
        fontFamily: theme.FONT_FAMILY_MEDIUM,
        color: theme.FONT_COLOR_WHITE,
        fontSize: 16,
      },
      btnInvitation: {
        width: 287,
        height: 39,
        paddingHorizontal: 40,
        paddingVertical: 10,
        borderWidth: 1,
        borderStyle: 'solid',
        borderRadius: 10,
        borderColor: '#000000',
        backgroundColor: '#FFF',
        alignItems: 'center',
        justifyContent: 'center',
      },
      btnBack: {
        width: 300,
        height: 39,
        paddingHorizontal: 10,
        paddingVertical: 10,
        borderWidth: 1,
        borderStyle: 'solid',
        borderRadius: 10,
        borderColor: '#000000',
        // backgroundColor: '#D9D9D9',
        alignItems: 'center',
        justifyContent: 'center',
      },
      btnText: {
        fontFamily: 'Inter',
        fontSize: 16,
        fontWeight: 'normal',
        lineHeight: 19,
        textAlign: 'center',
      },
      divider: {
        flex: 1,
      },
      errorBorder: {
        borderColor: 'red',
      },
      radioGroup: {
        padding: 5,
        marginTop: 10,
      },
      tableContainer: {
        width: 600,
        height: 80,
        marginTop: 30,
      },
      headerRow: {
        flexDirection: 'row',
        borderBottomWidth: 1,
        paddingBottom: 10,
      },
      infoRow: {
        flexDirection: 'row',
        alignItems: 'center',
        paddingTop: 10,
      },
      cell: {
        flex: 1,
        textAlign: 'center',
        fontFamily: theme.FONT_FAMILY_MEDIUM,
        fontSize: 16,
        color: theme.FONT_COLOR_WHITE,
      },
      ordersHeader: {
        flex: 1,
        padding: 20,
      },
      ordersText: {
        fontSize: 16,
        frontWeight: 'normal',
      },
      referalTitleText: {
        fontFamily: theme.FONT_FAMILY_MEDIUM,
        fontWeight: '700',
        fontSize: 48,
        color: theme.FONT_COLOR_BLUE,
      },
      card: {
        width: wp('90%'),
        marginTop: 50,
        maxWidth: 540,
        height: 90,
        backgroundColor: theme.WHITE,
        borderWidth: 0,
        justifyContent: 'center',
      },
      cardAvatar: {
        width: 45,
      },
      cardAvatarStyle: {
        marginRight: 20,
        borderRadius: 5,
      },
      cardAvatarTheme: {
        colors: {
          primary: '#D9D9D9',
        },
        fonts: {
          fontFamily: theme.FONT_FAMYLI_MEDIUM,
          color: theme.FONT_COLOR_WHITE,
          fontSize: 24,
        }
      },
      cardTitleStyle: {
        fontFamily: theme.FONT_FAMILY_MEDIUM,
        fontSize: 24,
        lineHeight: 24,
        color: theme.FONT_COLOR_BLUE,
        paddingLeft: 20,
      },
      cardSubtitleStyle: {
        fontFamily: theme.FONT_FAMILY_MEDIUM,
        fontSize: 18,
        lineHeight: 18,
        color: theme.FONT_COLOR_BLACK,
        paddingLeft: 20,
      },
      segmentedButtons: {
        width: wp('80%'),
        maxWidth: 340,
      }
    }),
  android: (wp, hp, theme) =>
    StyleSheet.create({
      modalContainer: {
        // flex: 1,
        position: 'fixed',
        marginTop: hp('15%'),
        alignItems: 'center',
        alignSelf: 'center',
        width: wp('100%'),
        minHeight: screenHeight,
        height: '100%',
        backgroundColor: '#0000001A',
        zIndex: 10,
      },
      modalContent: {
        top: '5%',
        width: wp('90%'),
        maxWidth: 400,
        // height: 307,
        borderRadius: 40,
        backgroundColor: theme.WHITE,
        zIndex: 11,
        paddingBottom: 64,
      },
      modalHead: {
        alignItems: 'flex-end',
        height: 64,
      },
      modalBody: {
        width: 267,
        // height: 222,
        alignItems: 'center',
        // justifyContent: 'center',
        alignSelf: 'center',
        gap: 23,
      },
      modalCaption: {
        height: 48,
        fontFamily: theme.FONT_FAMILY_MEDIUM,
        fontSize: 40,
        lineHeight: 48,
        textAlign: 'left',
        marginTop: -10,
      },
      iconClose: {
        width: 20,
        height: 20,
        marginTop: 32,
        marginRight: 32,
      },
      inputContainer: {
        width: 251,
        height: 60,
      },
      inputLabel: {
        fontFamily: theme.FONT_FAMILY_MEDIUM,
        fontSize: 12,
        fontWeight: 'normal',
        lineHeight: 15,
        textAlign: 'left',
        color: theme.FONT_COLOR_BLUE,
      },
      input: {
        width: 250,
        height: 42,
        borderWidth: 1,
        borderRadius: 10,
        borderColor: theme.BLACK,
        padding: 10,
        fontFamily: theme.FONT_FAMILY_MEDIUM,
        fontSize: 16,
        color: theme.FONT_COLOR_BLACK,
        lineHeight: 19,
        textAlign: 'left',
      },
      modalBtnGroup: {
        gap: 10,
      },
      modalBtn: {
        width: 120,
        height: 39,
        borderRadius: 10,
        backgroundColor: theme.BLACK,
        paddingVertical: 10,
        paddingHorizontal: 10,
        fontFamily: 'Inter',
        fontSize: 16,
        fontWeight: 'normal',
        lineHeight: 19,
        textAlign: 'center',
        alignItems: 'center',
        justifyContent: 'center',
      },
      btnTextWhite:{
        fontFamily: theme.FONT_FAMILY,
        color: theme.FONT_COLOR_WHITE,
        fontSize: 16,
      },
      headerContainer: {
        flexDirection: 'row',
        justifyContent: 'flex-start',
        width: wp('100%'),
        marginBottom: 15,
      },
      headBackButton: {
        height: 50,
        width: wp('15%'),
        maxWidth: 70,
        backgroundColor: theme.BLACK,
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: 10,
        // marginRight: wp('10%'),
        borderTopEndRadius: 20,
        borderBottomEndRadius: 20,
      },
      contentHead: {
        marginTop: 10,
        flexGrow: 1,
        flexShrink: 0,
        height: 50,
        width: 200,
        position: 'absolute',
        left: wp('50%'),
        marginLeft: -100,
        maxWidth: 275,
        backgroundColor: theme.BLUE,
        borderRadius: 20,
        alignItems: 'center',
        justifyContent: 'center',
        // marginBottom: 15,
      },
      arrowLeft: {
        width: 40,
        height: 40,
      },
      contentHeadText: {
        fontFamily: theme.FONT_FAMILY_MEDIUM,
        color: theme.FONT_COLOR_WHITE,
        fontSize: 24,
        lineHeight: 24,
        textAlign: 'center',
      },
      btnGroup: {
        marginTop: 31,
        gap: 2,
        marginBottom: 32,
        flex: 1,
      },
      btnProfile: {
        width: wp('25%'),
        maxWidth: 145,
        height: 39,
        paddingHorizontal: 5,
        paddingVertical: 10,
        borderWidth: 1,
        borderStyle: 'solid',
        borderRadius: 10,
        // borderColor: theme.BLACK,
        backgroundColor: theme.BLACK,
        alignItems: 'center',
        justifyContent: 'center',
      },
      btnProfileText: {
        fontFamily: theme.FONT_FAMILY_MEDIUM,
        color: theme.FONT_COLOR_WHITE,
        fontSize: 16,
        lineHeight: 16,
      },
      btnInvitation: {
        width: 287,
        height: 39,
        paddingHorizontal: 40,
        paddingVertical: 10,
        borderWidth: 1,
        borderStyle: 'solid',
        borderRadius: 10,
        borderColor: '#000000',
        backgroundColor: '#FFF',
        alignItems: 'center',
        justifyContent: 'center',
      },
      btnBack: {
        width: 300,
        height: 39,
        paddingHorizontal: 10,
        paddingVertical: 10,
        borderWidth: 1,
        borderStyle: 'solid',
        borderRadius: 10,
        borderColor: '#000000',
        // backgroundColor: '#D9D9D9',
        alignItems: 'center',
        justifyContent: 'center',
      },

      btnText: {
        fontFamily: 'Inter',
        fontSize: 12,
        fontWeight: 'normal',
        lineHeight: 14,
        textAlign: 'center',
      },
      divider: {
        flex: 1,
      },
      errorBorder: {
        borderColor: 'red',
      },
      radioGroup: {
        padding: 5,
        marginTop: 5,
      },
      tableContainer: {
        width: 350,
        height: 80,
        marginTop: 30,
      },
      headerRow: {
        flexDirection: 'row',
        borderBottomWidth: 1,
        paddingBottom: 5,
      },
      infoRow: {
        flexDirection: 'row',
        alignItems: 'center',
        paddingTop: 5,
      },
      cell: {
        flex: 1,
        textAlign: 'center',
        fontFamily: 'Inter',
        fontSize: 12,
        fontWeight: 'normal',
      },
      ordersHeader: {
        // flex: 1,
        padding: 20,
      },
      ordersText: {
        fontSize: 16,
        fontWeight: 'normal',
      },
      referalTitleText: {
        fontFamily: theme.FONT_FAMILY_MEDIUM,
        fontWeight: '700',
        fontSize: 48,
        color: theme.FONT_COLOR_BLUE,
      },
      card: {
        width: wp('90%'),
        marginTop: 50,
        maxWidth: 540,
        height: 90,
        backgroundColor: theme.WHITE,
        borderWidth: 0,
        justifyContent: 'center',
      },
      cardAvatar: {
        width: 45,
      },
      cardAvatarStyle: {
        marginRight: 20,
        borderRadius: 5,
      },
      cardAvatarTheme: {
        colors: {
          primary: '#D9D9D9',
        },
        fonts: {
          fontFamily: theme.FONT_FAMYLI_MEDIUM,
          color: theme.FONT_COLOR_WHITE,
          fontSize: 24,
        }
      },
      cardTitleStyle: {
        fontFamily: theme.FONT_FAMILY_MEDIUM,
        fontSize: 24,
        lineHeight: 24,
        color: theme.FONT_COLOR_BLUE,
        paddingLeft: 20,
      },
      cardSubtitleStyle: {
        fontFamily: theme.FONT_FAMILY_MEDIUM,
        fontSize: 18,
        lineHeight: 18,
        color: theme.FONT_COLOR_BLACK,
        paddingLeft: 20,
      },
      segmentedButtons: {
        width: wp('80%'),
        maxWidth: 380,
      }
    }),
  ios: (wp, hp, theme) =>
    StyleSheet.create({
      modalContainer: {
        flex: 1,
        position: 'fixed',
        top: 0,
        alignItems: 'center',
        width: 360,
        minHeight: 500,
        height: 500,
        backgroundColor: '#0000001A',
        zIndex: 10,
      },
      modalContent: {
        top: 5,
        width: 350,
        // height: 307,
        borderRadius: 40,
        backgroundColor: '#FFF',
        zIndex: 11,
        paddingBottom: 64,
      },
      modalHead: {
        alignItems: 'flex-end',
        height: 64,
      },
      modalBody: {
        width: 267,
        // height: 222,
        alignItems: 'center',
        // justifyContent: 'center',
        alignSelf: 'center',
        gap: 23,
      },
      modalCaption: {
        height: 48,
        fontFamily: 'Inter',
        fontSize: 40,
        fontWeight: 'normal',
        lineHeight: 48,
        textAlign: 'left',
        marginTop: -10,
      },
      iconClose: {
        width: 20,
        height: 20,
        marginTop: 32,
        marginRight: 32,
      },
      inputContainer: {
        width: 251,
        height: 60,
      },
      inputLabel: {
        fontFamily: 'Inter',
        fontSize: 12,
        fontWeight: 'normal',
        lineHeight: 15,
        textAlign: 'left',
        color: '#707070',
      },
      input: {
        width: 250,
        height: 42,
        borderWidth: 1,
        borderRadius: 10,
        borderColor: '#707070',
        padding: 10,
        fontFamily: 'Inter',
        fontSize: 16,
        // fontWeight: 'normal',
        lineHeight: 19,
        textAlign: 'left',
      },
      modalBtnGroup: {
        gap: 23,
      },
      modalBtn: {
        width: 114,
        height: 39,
        borderRadius: 10,
        backgroundColor: '#D9D9D9',
        paddingVertical: 10,
        paddingHorizontal: 20,
        fontFamily: 'Inter',
        fontSize: 16,
        fontWeight: 'normal',
        lineHeight: 19,
        textAlign: 'center',
        alignItems: 'center',
        justifyContent: 'center',
      },
      contentHead: {
        marginTop: 20,
      },
      contentHeadText: {
        fontFamily: 'Inter',
        fontSize: 20,
        lineHeight: 22,
        textAlign: 'center',
        height: 24,
      },
      btnGroup: {
        marginTop: 15,
        gap: 5,
        marginBottom: 70,
        flex: 1,
      },
      btnProfile: {
        width: 90,
        height: 55,
        paddingHorizontal: 5,
        paddingVertical: 5,
        borderWidth: 1,
        borderStyle: 'solid',
        borderRadius: 10,
        borderColor: '#000000',
        // backgroundColor: '#D9D9D9',
        alignItems: 'center',
        justifyContent: 'center',
      },
      btnInvitation: {
        width: 160,
        height: 39,
        paddingHorizontal: 5,
        paddingVertical: 5,
        borderWidth: 1,
        borderStyle: 'solid',
        borderRadius: 10,
        borderColor: '#000000',
        backgroundColor: '#FFF',
        alignItems: 'left',
        justifyContent: 'center',
      },
      btnBack: {
        width: 200,
        height: 39,
        paddingHorizontal: 10,
        paddingVertical: 10,
        borderWidth: 1,
        borderStyle: 'solid',
        borderRadius: 10,
        borderColor: '#000000',
        // backgroundColor: '#D9D9D9',
        alignItems: 'center',
        justifyContent: 'center',
      },
      btnText: {
        fontFamily: 'Inter',
        fontSize: 12,
        fontWeight: 'normal',
        lineHeight: 14,
        textAlign: 'center',
      },
      divider: {
        flex: 1,
      },
      errorBorder: {
        borderColor: 'red',
      },
      radioGroup: {
        padding: 5,
        marginTop: 5,
      },
      tableContainer: {
        width: 350,
        height: 80,
        marginTop: 30,
      },
      headerRow: {
        flexDirection: 'row',
        borderBottomWidth: 1,
        paddingBottom: 5,
      },
      infoRow: {
        flexDirection: 'row',
        alignItems: 'center',
        paddingTop: 5,
      },
      cell: {
        flex: 1,
        textAlign: 'center',
        fontFamily: 'Inter',
        fontSize: 12,
        fontWeight: 'normal',
      },
      ordersHeader: {
        flex: 1,
        padding: 20,
      },
      ordersText: {
        fontSize: 16,
        frontWeight: 'normal',
      },
      segmentedButtons: {
        width: wp('80%'),
        maxWidth: 380,
      }
    }),
};

export default platformStyles[Platform.OS];
