import React, {useState, useContext, useEffect, useCallback} from 'react';
import {HStack} from 'native-base';
import {useIsFocused} from '@react-navigation/native';
import {
  Text,
  View,
  ScrollView,
  Pressable,
  KeyboardAvoidingView,
  Image,
  Platform,
  Modal,
} from 'react-native';
import {Card, Badge, DataTable} from 'react-native-paper';
import {
  widthPercentageToDP as wp,
  heightPercentageToDP as hp,
} from 'react-native-responsive-screen';
import {TextInputMask} from 'react-native-masked-text';
import {AuthContext} from '../../components/AuthProvider';
import AxiosInstance from '../../components/common/axiosInstance';

import Header from '../../components/common/Header';
import Footer from '../../components/common/Footer';
import Loader from '../../components/common/Loader';
import {capitalizeFirstLetter} from '../../components/common/commonFunctions';
import ErrorModal from '../../components/common/ErrorModal';
import InfoModal from '../../components/common/InfoModal';

import ArrowLeft from '../../assets/img/arrow_left_white.svg';

import {default as commonStylesF} from '../../styles/common';
import {default as commonStylesMobileF} from '../../styles/common_mobile';
import {default as listStylesF} from '../../styles/list';
import {default as balanceStylesF} from '../../styles/balance';
import {default as balanceStylesMobileF} from '../../styles/balance_mobile';
import IOSCustomPagination from '../../components/common/IOSCustomPagination';

const BalanceHeaderTable = props => {
  const {t, theme} = useContext(AuthContext);
  const listStyles = listStylesF(theme);
  const dateSort = direction => {
    direction === 'desc'
      ? props.setSortDirection('asc')
      : props.setSortDirection('desc');
  };
  return (
    <DataTable.Header style={{...listStyles.itemsHeader, maxWidth: 640}}>
      <DataTable.Title
        style={listStyles.itemCell}
        sortDirection={
          props.sortDirection === 'desc' ? 'descending' : 'ascending'
        }>
        <Pressable
          onPress={() => {
            dateSort(props.sortDirection);
          }}>
          <HStack style={Platform.OS === 'ios' ? {alignItems: 'center'} : {}}>
            <Text style={{...listStyles.itemsHeaderText}}>{t('date')}</Text>
          </HStack>
        </Pressable>
      </DataTable.Title>
      <DataTable.Title style={listStyles.itemCell}>
        <Text style={listStyles.itemsHeaderText}>{t('sum')}</Text>
      </DataTable.Title>
      <DataTable.Title style={listStyles.itemCell}>
        <Text style={listStyles.itemsHeaderText}>{t('description')}</Text>
      </DataTable.Title>
      <DataTable.Title style={listStyles.itemCell}>
        <Text style={listStyles.itemsHeaderText}>{t('rest')}</Text>
      </DataTable.Title>
    </DataTable.Header>
  );
};

const BalanceTable = ({item}) => {
  const {t, theme} = useContext(AuthContext);
  const listStyles = listStylesF(theme);

  let oper_type = '';
  let row_style = {...listStyles.itemRow, maxWidth: 640};
  if (item.type === 'transaction') {
    if (item.amount > 0) {
      oper_type = t('accrual');
      row_style = {...row_style, backgroundColor: theme.LIGHT_BLUE};
    } else {
      oper_type = t('withdraw');
      row_style = {...row_style, backgroundColor: theme.BLUE};
    }
  } else {
    oper_type = t('withdraw_request');
    row_style = {...row_style, backgroundColor: '#ffa4a4'};
  }
  return (
    <DataTable.Row style={row_style} key={item.id}>
      <DataTable.Cell style={listStyles.itemCell}>
        <Text style={listStyles.itemCol}>{item.updated_at.substr(0, 16)}</Text>
      </DataTable.Cell>
      <DataTable.Cell style={listStyles.itemCell}>
        <Text style={listStyles.itemCol}>
          {item.amount} {t('UAH')}
        </Text>
      </DataTable.Cell>
      <DataTable.Cell style={listStyles.itemCell}>
        <Text style={listStyles.itemCol}>{oper_type}</Text>
      </DataTable.Cell>
      <DataTable.Cell style={listStyles.itemCell}>
        <Text style={listStyles.itemCol}>
          {item.curr_balance ? `${item.curr_balance} ${t('UAH')}` : ''}
        </Text>
      </DataTable.Cell>
    </DataTable.Row>
  );
};

const BalanceHeaderCard = props => {
  const {t, theme} = useContext(AuthContext);
  const listStyles = listStylesF(theme);
  const dateSort = direction => {
    direction === 'desc'
      ? props.setSortDirection('asc')
      : props.setSortDirection('desc');
  };
  return (
    <DataTable.Header style={{...listStyles.itemsHeader, maxWidth: 640}}>
      <DataTable.Title
        style={listStyles.itemCell}
        sortDirection={
          props.sortDirection === 'desc' ? 'descending' : 'ascending'
        }>
        <Pressable
          onPress={() => {
            dateSort(props.sortDirection);
          }}>
          <HStack style={Platform.OS === 'ios' ? {alignItems: 'center'} : {}}>
            <Text style={{...listStyles.itemsHeaderText}}>{t('date')}</Text>
          </HStack>
        </Pressable>
      </DataTable.Title>
    </DataTable.Header>
  );
};

const BalanceCard = ({item}) => {
  const {t, theme} = useContext(AuthContext);
  const balanceStyles = balanceStylesMobileF(wp, hp, theme);
  let oper_type = '';
  let row_style = '';
  if (item.type === 'transaction') {
    if (item.amount > 0) {
      oper_type = t('accrual');
      row_style = theme.BLUE;
    } else {
      oper_type = t('withdraw');
      row_style = '#f75c5c';
    }
  } else {
    oper_type = t('withdraw_request');
    row_style = theme.LIGHT_BLUE;
  }
  const Label = (
    <View style={{height: 60, flexDirection: 'column', flexWrap: 'wrap'}}>
      <Text
        style={{
          fontFamily: theme.FONT_FAMILY_MEDIUM,
          fontSize: 24,
          lineHeight: 40,
          color: theme.FONT_COLOR_WHITE,
          width: 60,
        }}>
        {item.amount}
      </Text>
      <Text
        style={{
          fontFamily: theme.FONT_FAMILY,
          fontSize: 14,
          lineHeight: 20,
          color: theme.FONT_COLOR_WHITE,
          width: 60,
        }}>
        {t('UAH')}
      </Text>
    </View>
  );

  const _Label = (
    <>
      <Text>777</Text>
    </>
  );

  const Right = () => (
    <>
      <Text
        style={{
          fontFamily: theme.FONT_FAMILY_MEDIUM,
          fontSize: 24,
          lineHeight: 25,
          color: theme.FONT_COLOR_WHITE,
        }}>
        {item.amount}
      </Text>
      <Text
        style={{
          fontFamily: theme.FONT_FAMILY_MEDIUM,
          fontSize: 14,
          lineHeight: 15,
          color: theme.FONT_COLOR_WHITE,
        }}>
        {t('UAH')}
      </Text>
    </>
  );

  const SubTitle = (
    <View>
      {item.type === 'transaction' && (
        <Text style={balanceStyles.btnTextWhite}>
          {t('rest')}: {item.curr_balance} {t('UAH')}
        </Text>
      )}
    </View>
  );

  return (
    <DataTable.Row
      key={item.id}
      style={{width: wp('90%'), alignItems: 'center'}}>
      <Card
        mode={'outlined'}
        style={{
          width: wp('90%'),
          margin: 2,
          maxWidth: 980,
          alignSelf: 'center',
          backgroundColor: row_style,
          borderRadius: 10,
        }}>
        <Card.Title
          title={oper_type}
          subtitle={SubTitle}
          right={Right}
          titleStyle={{
            fontFamily: theme.FONT_FAMILY_BOLD,
            color: theme.FONT_COLOR_WHITE,
            fontSize: 18,
          }}
          subtitleStyle={{
            fontFamily: theme.FONT_FAMILY_MEDIUM,
            color: theme.FONT_COLOR_WHITE,
            fontSize: 14,
          }}
          rightStyle={{
            marginRight: 10,
            width: 80,
            alignItems: 'center',
            justifyContent: 'center',
          }}
        />
      </Card>
    </DataTable.Row>
  );
};

const Balance = ({navigation, route}) => {
  const {t, screenWidth, theme, addHistory} = useContext(AuthContext);
  const {user, logout} = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(null);
  const [info, setInfo] = useState(null);
  const [balance, setBalance] = useState({transactions: {}, balance: {}});
  const [modalVisible, setModalVisible] = useState(false);
  const [withdraw, setWithdraw] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [pagination, setPagination] = useState({});
  const [sortField, setSortField] = useState('created_at');
  const [sortDirection, setSortDirection] = useState('desc');
  const [isShowKeyboard, setIsShowKeyboard] = useState(false);
  const [prevScreenWidth, setPrevScreenWidth] = useState(screenWidth);
  const [from, setFrom] = useState(0);
  const [to, setTo] = useState(0);
  const isFocused = useIsFocused();

  const commonStyles =
    screenWidth < 1080
      ? commonStylesMobileF(wp, hp, theme)
      : commonStylesF(wp, hp, theme);
  const balanceStyles =
    screenWidth < 1080
      ? balanceStylesMobileF(wp, hp, theme)
      : balanceStylesF(wp, hp, theme);
  const listStyles = listStylesF(theme);

  let BalanceHeader =
    screenWidth < 700 ? BalanceHeaderCard : BalanceHeaderTable;
  let Balance = screenWidth < 700 ? BalanceCard : BalanceTable;

  const doWithdraw = async () => {
    setIsLoading(true);
    const api = AxiosInstance({user, logout});
    await api('/api/v1/withdraw', {
      method: 'POST',
      mode: 'cors',
      data: {
        amount: parseFloat(withdraw),
        program: 'trio',
      },
    })
      .then(response => {
        if (response.status === 201) {
          setBalance({
            ...balance,
            available: balance.available - parseFloat(withdraw),
          });
          let new_row = response.data.message;
          if (new_row.type === 'request') {
            new_row.curr_balance = balance.available;
          }
          setBalance(prevVal => {
            prevVal.transactions.data.unshift(new_row);
            return prevVal;
          });
          setInfo(t('withdraw_request_sended'));
        } else if (response.status === 'error') {
          console.log(response);
        }
      })
      .catch(error => {
        setIsError(error);
      })
      .finally(() => {
        setIsLoading(false);
        setModalVisible(false);
      });
  };

  const getBalance = useCallback(async () => {
    setIsLoading(true);
    const api = AxiosInstance({user, logout});
    const url = `/api/v1/balance?page=${currentPage}&order_field=${sortField}&order=${sortDirection}`;
    await api(url, {
      method: 'GET',
      mode: 'cors',
    })
      .then(response => {
        let _from = 0;
        let _to = 0;
        setBalance(response.data.message);
        setPagination(response.data.message.transactions.pagination);
        _from =
          response.data.message.transactions.pagination.totalRows > 0
            ? (currentPage - 1) *
                response.data.message.transactions.pagination.perPage +
              1
            : 0;
        _to =
          response.data.message.transactions.pagination.totalRows >
          currentPage * response.data.message.transactions.pagination.perPage
            ? currentPage *
              response.data.message.transactions.pagination.perPage
            : response.data.message.transactions.pagination.totalRows;
        setFrom(_from);
        setTo(_to);
      })
      .catch(error => {
        setIsError(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [user, currentPage, logout, sortField, sortDirection]);

  useEffect(() => {
    if (isFocused) {
      getBalance();
    }

  }, [getBalance, isFocused]);

  useEffect(() => {
    setPrevScreenWidth(screenWidth);
  }, [screenWidth]);

  useEffect(() => {
    if (Platform.OS !== 'web') {
      addHistory(route.name);
    }
  }, [addHistory, route]);

  if (isLoading) {
    return <Loader />;
  } else {
    return (
      <ScrollView contentContainerStyle={commonStyles.container}>
        <Header navigation={navigation} route={route} />
        <View style={commonStyles.contentContainer}>
          <View style={commonStyles.headerContainer}>
            <Pressable
              style={commonStyles.headBackButton}
              onPress={() =>
                Platform.OS === 'web'
                ? navigation.push('Profile')
                : navigation.navigate('Profile')
              }>
              {Platform.OS === 'web' ? (
                <Image source={ArrowLeft} style={commonStyles.arrowLeft} />
              ) : (
                <ArrowLeft style={commonStyles.arrowLeft} />
              )}
            </Pressable>
            <View style={commonStyles.contentHead}>
              <Text style={commonStyles.contentHeadText}>{t('balance')}</Text>
            </View>
          </View>
          <View style={balanceStyles.availableBalance}>
            <Text style={balanceStyles.availableText}>
              {t('available_for_withdraw')}
            </Text>
            <Text style={balanceStyles.availableText1}>
              {balance.available} {t('UAH')}
            </Text>
          </View>
          {/* <DataTable style={listStyles.dataTable}>
            <BalanceHeader
              sortField={sortField}
              sortDirection={sortDirection}
              setSortField={setSortField}
              setSortDirection={setSortDirection}
            />
            {balance.transactions.data.map(item => (
              <Balance item={item} />
            ))}
            <DataTable.Pagination
              page={currentPage - 1}
              numberOfPages={Math.ceil(pagination.totalPages)}
              onPageChange={page => setCurrentPage(page + 1)}
              label={`${from}-${to} of ${pagination.totalRows}`}
              numberOfItemsPerPage={pagination.perPage}
              showFastPaginationControls
            />
          </DataTable> */}
          {Platform.OS === 'ios' ? (
            // Кастомна пагінація і рендеринг даних для iOS
            <View style={listStyles.dataTable}>
              <BalanceHeader
                sortField={sortField}
                sortDirection={sortDirection}
                setSortField={setSortField}
                setSortDirection={setSortDirection}
              />
              {balance.transactions.data.map(item => (
                <Balance item={item} key={item.id} />
              ))}
              <IOSCustomPagination
                currentPage={currentPage}
                totalPages={Math.ceil(pagination.totalPages)}
                onPageChange={newPage => setCurrentPage(newPage)}
                label={`${from}-${to} of ${pagination.totalRows}`}
              />
            </View>
          ) : (
            // Стандартна пагінація для інших платформ
            <DataTable style={listStyles.dataTable}>
              <BalanceHeader
                sortField={sortField}
                sortDirection={sortDirection}
                setSortField={setSortField}
                setSortDirection={setSortDirection}
              />
              {balance.transactions.data.map(item => (
                <Balance item={item} key={item.id} />
              ))}
              <DataTable.Pagination
                page={currentPage - 1}
                numberOfPages={Math.ceil(pagination.totalPages)}
                onPageChange={page => setCurrentPage(page + 1)}
                label={`${from}-${to} of ${pagination.totalRows}`}
                numberOfItemsPerPage={pagination.perPage}
                showFastPaginationControls
              />
            </DataTable>
          )}

          <HStack style={balanceStyles.balanceFooterContainer}>
            <HStack style={balanceStyles.btnGroup}>
              <Pressable
                style={balanceStyles.balanceBtnWithdraw}
                onPress={() => setModalVisible(true)}>
                <Text style={balanceStyles.btnTextWhite}>
                  {t('do_withdraw_request')}
                </Text>
              </Pressable>
            </HStack>
          </HStack>
        </View>
        <Footer navigation={navigation} />
        {modalVisible && (
          <KeyboardAvoidingView
            behavior={Platform.OS === 'ios' ? 'padding' : ''}>
            <Modal
              animationType="none"
              transparent={true}
              visible={true}
              onRequestClose={() => {
                setModalVisible(false);
              }}
              style={{alignItems: 'center', justifyContent: 'center'}}>
              <View
                style={{
                  ...balanceStyles.modalContainer,
                  marginBottom: isShowKeyboard ? 20 : 150,
                }}>
                <View style={balanceStyles.modalContent}>
                  <View style={balanceStyles.modalHead}>
                    <Pressable onPress={() => setModalVisible(false)}>
                      <Image
                        style={balanceStyles.iconClose}
                        source={require('../../assets/img/icon_close.png')}
                      />
                    </Pressable>
                  </View>

                  <View style={balanceStyles.modalBody}>
                    <Text style={balanceStyles.modalCaption}>
                      {capitalizeFirstLetter(t('withdraw'))}
                    </Text>
                    <View style={balanceStyles.inputContainer}>
                      <Text style={balanceStyles.sumLabel}>{t('sum')}</Text>
                      <TextInputMask
                        type={'only-numbers'}
                        style={balanceStyles.sumInput}
                        onChangeText={text => setWithdraw(text)}
                        // value={balance.balance.curr_balance}
                        value={withdraw}
                        onFocus={() => setIsShowKeyboard(true)}
                        isFocused={true}
                      />
                    </View>
                    <HStack style={balanceStyles.modalBtnGroup}>
                      <Pressable
                        style={balanceStyles.modalBtn}
                        onPress={() => {
                          setModalVisible(false);
                          setIsShowKeyboard(false);
                        }}>
                        <Text style={balanceStyles.btnTextWhite}>
                          {t('cancel')}
                        </Text>
                      </Pressable>
                      <Pressable
                        style={balanceStyles.modalBtn}
                        onPress={() => {
                          doWithdraw();
                          setIsShowKeyboard(false);
                        }}>
                        <Text style={balanceStyles.btnTextWhite}>
                          {t('do_withdraw_request')}
                        </Text>
                      </Pressable>
                    </HStack>
                    {/* <Text style={styles.error}>Errors: {errors ? JSON.stringify(errors) : ''}</Text> */}
                  </View>
                </View>
              </View>
            </Modal>
          </KeyboardAvoidingView>
        )}
        {isError && <ErrorModal error={isError} setIsError={setIsError} />}
        {info && <InfoModal info={info} setInfo={setInfo} />}
      </ScrollView>
    );
  }
};

export default Balance;
