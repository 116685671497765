import * as React from 'react';
import {useEffect, useState, useContext, useCallback} from 'react';
import {
  StyleSheet,
  View,
  ActivityIndicator,
  Platform,
  Pressable,
} from 'react-native';
import {Center, Text, Heading, HStack, Divider} from 'native-base';
import {AuthContext} from '../../components/AuthProvider';
import axios from 'axios';
import {
  widthPercentageToDP as wp,
  heightPercentageToDP as hp,
} from 'react-native-responsive-screen';
import {
  SegmentedButtons,
  DataTable,
} from 'react-native-paper';

import Header from '../../components/common/AdminHeader';
import ErrorModal from '../../components/common/ErrorModal';
import AddMessage from './AddMessage';

import commonStylesF from '../../styles/common';
import listStylesF from '../../styles/list';

const MessagesHeader = () => {
  const {t, theme} = useContext(AuthContext);
  const listStyles = listStylesF(theme);
  return (
    <DataTable.Header style={listStyles.itemsHeader}>
      <DataTable.Title style={listStyles.itemCell}>
        <Text style={listStyles.itemsHeaderText}>{t('message_type')}</Text>
      </DataTable.Title>
      <DataTable.Title style={listStyles.itemCell}>
        <Text style={listStyles.itemsHeaderText}>{t('message_channel')}</Text>
      </DataTable.Title>
      <DataTable.Title style={listStyles.itemCell}>
        <Text style={listStyles.itemsHeaderText}>{t('message_subject')}</Text>
      </DataTable.Title>
      <DataTable.Title style={listStyles.itemCell}>
        <Text style={listStyles.itemsHeaderText}>{t('message_content')}</Text>
      </DataTable.Title>
      <DataTable.Title style={listStyles.itemCell}>
        <Text style={listStyles.itemsHeaderText}>{t('message_language')}</Text>
      </DataTable.Title>
    </DataTable.Header>
  );
};

const Message = ({item, setEditMessage}) => {
  const {t, theme} = useContext(AuthContext);
  const listStyles = listStylesF(theme);
  return (
    <DataTable.Row
      key={item.id}
      onPress={() => {
        setEditMessage(item);
      }}
      style={listStyles.itemRow}
    >
      <DataTable.Cell style={listStyles.itemCol}>
        <Text style={listStyles.itemCol}>{t(item.type)}</Text>
      </DataTable.Cell>
      <DataTable.Cell style={listStyles.itemCol}>
        <Text style={listStyles.itemCol}>{t(item.channel)}</Text>
      </DataTable.Cell>
      <DataTable.Cell style={listStyles.itemCol}>
        <Text style={listStyles.itemCol} numberOfLines={2} ellipsizeMode='tail'>{item.announce}</Text>
      </DataTable.Cell>
      <DataTable.Cell style={listStyles.itemCol}>
        <Text style={listStyles.itemCol} numberOfLines={2} ellipsizeMode='tail'>{item.message}</Text>
      </DataTable.Cell>
      <DataTable.Cell style={listStyles.itemCol}>
        <Text style={listStyles.itemCol}>{item.lang}</Text>
      </DataTable.Cell>
    </DataTable.Row>
  );
};

const AdminMessages = ({navigation, route}) => {
  const {user, Config, t, theme} = useContext(AuthContext);
  const commonStyles = commonStylesF(wp, hp, theme);
  const listStyles = listStylesF(theme);

  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [messages, setMessages] = useState([]);
  const [addMessage, setAddMessage] = useState(false);
  const [editMessage, setEditMessage] = useState(false);
  const [needUpdate, setNeedUpdate] = useState(false);

  const getData = useCallback(async () => {
    setIsLoading(true);
    await axios(`${Config.API_URL}/api/v1/messages`, {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        Authorization: user.Authorization,
      },
    })
      .then(response => {
        if (response.status === 200) {
          setMessages(response.data.message);
        } else if (response.status === 'error') {
          console.log(response);
          setIsError(response.data.message);
        }
      })
      .catch(error => {
        if (error.response) {
          console.log('AdminMessages error.response', error.response);
          setIsError(error.response.data.message);
        } else if (error.request) {
          console.log('AdminMessages error.request', error.request);
          setIsError(JSON.stringify(error.request));
        } else {
          console.log('AdminTrios unknown error', error);
          setIsError(JSON.stringify(error));
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [user, Config]);

  useEffect(() => {
    getData();
    if (needUpdate) {
      setNeedUpdate(false);
    }
  }, [getData, needUpdate]);

  if (addMessage) {
    return (
      <AddMessage
        _message={false}
        setAddMessage={setAddMessage}
        navigation={navigation}
        setNeedUpdate={setNeedUpdate}
      />
    );
  } else if (editMessage) {
    return (
      <AddMessage
        _message={editMessage}
        setAddMessage={setEditMessage}
        navigation={navigation}
        setNeedUpdate={setNeedUpdate}
      />
    );
  } else {
    return (
      <View style={commonStyles.container}>
        <Header navigation={navigation} />
        <View style={commonStyles.contentContainer}>
          <View style={commonStyles.contentHead}>
            <Text style={commonStyles.contentHeadText}>{t('messages')}</Text>
          </View>
          <DataTable style={listStyles.dataTable}>
            <MessagesHeader />
            {messages.map(item => (
              <Message item={item} setEditMessage={setEditMessage}/>
            ))}
          </DataTable>
          <View style={commonStyles.btnGroup}>
            <Pressable
              onPress={() => setAddMessage(true)}
              style={{...commonStyles.btnCommonBig, width: 220}}>
              <Text style={commonStyles.btnCommonTxtBig}>{t('add_message')}</Text>
            </Pressable>
          </View>
        </View>
        {isLoading === true && (
          <ActivityIndicator
            style={styles.activityIndicator}
            color="#0C090D"
            size="large"
          />
        )}
        {isError && <ErrorModal error={isError} setIsError={setIsError} />}
      </View>
    );
  }
};

const styles = StyleSheet.create({
  activityIndicator: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'transparent', // Прозрачный фон
  },
  btnAdd: {
    width: 150,
    height: 40,
    borderWidth: 1,
    borderRadius: 10,
    alignItems: 'center',
    justifyContent: 'center',
    alignSelf: 'center',
  },
});

export default AdminMessages;
