const Dev = {
  BLUE: '',
  LIGHT_BLUE: '',
  BLACK: '',
  FONT_FAMILY: '',
  FONT_COLOR: '',
}

const Prod = {
  BLUE: '#3E3EFF',
  LIGHT_BLUE: '#3E3EFFBF',
  BLACK: '#000000',
  WHITE: '#FFFFFF',
  FONT_FAMILY: 'Mariupol',
  FONT_FAMILY_MEDIUM: 'Mariupol-Medium',
  FONT_FAMILY_BOLD: 'Mariupol-Bold',
  FONT_COLOR_WHITE: '#FFF',
  FONT_COLOR_BLUE: '#3E3EFF',
  FONT_COLOR_LIGHT_BLUE: '#3E3EFFBF',
  FONT_COLOR_BLACK: '#000',
}

export default Prod;