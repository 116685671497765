import React, {useState, useContext, useEffect} from 'react';
import {useIsFocused} from '@react-navigation/native';
import {
  Image,
  TouchableOpacity,
  Pressable,
  Text,
  Platform,
  ScrollView,
  ImageBackground,
} from 'react-native';
import AsyncStorage from '@react-native-async-storage/async-storage';
import {
  HStack,
  VStack,
  View,
} from 'native-base';
import {GoogleOAuthProvider} from '@react-oauth/google';
import {createStackNavigator} from '@react-navigation/stack';
import {createBottomTabNavigator} from '@react-navigation/bottom-tabs';
import Ionicons from 'react-native-vector-icons/Ionicons';
import Icon from 'react-native-vector-icons/FontAwesome';
import Clipboard from '@react-native-clipboard/clipboard';

import AxiosInstance from '../../components/common/axiosInstance';

import Header from '../../components/common/Header';
import Footer from '../../components/common/Footer';
import Transactions from './Transactions';
import Balance from './Balance';
import InvitationsScreen from '../Invitation/Invitations';
import TriosScreen from '../Trio/Screen';
import ActivityScreen from '../Activity/Activity';
import ActivityDetails from '../Activity/ActivityDetails';
import WithdrawScreen from '../Withdraws/WithdrawScreen';
import Loader from '../../components/common/Loader';
import {AuthContext} from '../../components/AuthProvider';
import Config from '../../config/config';
import {
  ShareComponent,
  ShareWithChoice,
  formInitials,
} from '../../components/common/commonFunctions';
import {
  widthPercentageToDP as wp,
  heightPercentageToDP as hp,
} from 'react-native-responsive-screen';

import FirstPageMobile from './FirstPageMobile';
import FirstPageWeb from './FirstPageWeb';
import ProfileEditLink from './ProfileEditLink';
import ProfileEditing from './ProfileEdit';
import ContactList from '../Invitation/ContactList';
import OtherContactList from '../Invitation/OtherContactList';
import WebContacts from '../Invitation/WebContacts';
import ErrorModal from '../../components/common/ErrorModal';
import InfoModal from '../../components/common/InfoModal';
import ErrorScreen from '../../components/common/ErrorScreen';
import PrivacyPolicy from '../../components/common/PrivacyPolicy';
import About from '../../components/common/About';
import Contacts from '../../components/common/Contacts';
import Empty from '../../components/common/Empty';

import {default as commonStylesDesktop} from '../../styles/common';
import {default as commonStylesMobile} from '../../styles/common_mobile';
import {default as profileStylesDesktop} from '../../styles/profile';
import {default as profileStylesMobile} from '../../styles/profile_mobile';
import ToolTipModal from '../../components/common/ToolTipModal';


const ProfileStack =
  Platform.OS === 'web' ? createStackNavigator() : createBottomTabNavigator();

const InfoBox = ({userData, navigation, part, boxStyle}) => {
  const {t, screenWidth, theme} = useContext(AuthContext);
  let fontSizeBig =
    Math.ceil(screenWidth / 18) < 40 ? Math.ceil(screenWidth / 14) : 40;
  let fontSizeMd =
    Math.ceil(screenWidth / 20) < 24 ? Math.ceil(screenWidth / 16) : 24;
  let profileStyles =
    screenWidth < 1080
      ? profileStylesMobile(wp, hp, fontSizeBig, fontSizeMd, theme)
      : profileStylesDesktop(wp, hp, fontSizeBig, fontSizeMd, theme);

  let partName;
  switch (part) {
    case 'Balance':
      partName = t('balance');
      break;
    case 'Invitations':
      partName = t('invitations');
      break;
    case 'Transactions':
      partName = t('transactions');
      break;
    case 'Trios':
      partName = t('trios');
      break;
  }
  return (
    <Pressable
      onPress={() => {
        part === 'Transactions'
          ? navigation.navigate('Balance')
          : navigation.navigate(part);
      }}>
      <View style={boxStyle}>
        <Text style={profileStyles.statLabel}>{partName}</Text>
        {part === 'Balance' ? (
          <>
            <Text style={profileStyles.statText}>
              {t('infobox_text_balance')}
            </Text>
            <HStack style={profileStyles.statCountContainer}>
              <Text style={profileStyles.statCount}>
                {userData.balance ? userData.balance.curr_balance : 0}
              </Text>
              <Text style={profileStyles.statCountTxt}>
                {' '}
                {t('UAH')}
              </Text>
            </HStack>
          </>
        ) : part === 'Invitations' ? (
          <>
            <Text style={profileStyles.statText}>
              {t('infobox_text_invitations')}
            </Text>
            <VStack style={profileStyles.statCountContainer}>
              <Text style={profileStyles.statTxt}>
                {t('total')}: {userData.invitations.total}
              </Text>
              <Text style={profileStyles.statTxt}>
                {t('accepted')}: {userData.invitations.accepted}
              </Text>
              <Text style={profileStyles.statTxt}>
                {t('waiting')}: {userData.invitations.waiting}
              </Text>
            </VStack>
          </>
        ) : part === 'Transactions' ? (
          <>
            <Text style={profileStyles.statText}>
              {t('infobox_text_transactions')}
            </Text>
            <VStack style={profileStyles.statCountContainer}>
              <Text style={profileStyles.statTxt}>
                {t('total')}: {userData.transactions.total_cnt}{' '}
                {t('infobox_text_transactions_1')}
              </Text>
              <Text style={profileStyles.statTxt}>
                {t('accruals')}: {userData.transactions.positive_cnt} {t('on')}{' '}
                {userData.transactions.positive_sum} {t('UAH')}
              </Text>
              <Text style={profileStyles.statTxt}>
                {t('total_withdraws')}: {userData.transactions.negative_cnt}{' '}
                {t('on')} {userData.transactions.negative_sum}{' '}
                {t('UAH')}
              </Text>
            </VStack>
          </>
        ) : (
          part === 'Trios' && (
            <>
              <Text style={{...profileStyles.statText}}>
                {t('infobox_text_trios')}
              </Text>
              <VStack style={{...profileStyles.statCountContainer, top: 20}}>
                <Text style={profileStyles.statTxt}>
                  {t('total')}: {userData.trios.total_cnt}
                </Text>
                <Text style={profileStyles.statTxt}>
                  {t('infobox_on_sum')}: {userData.trios.total_sum}{' '}
                  {t('UAH')}
                </Text>
                <Text style={profileStyles.statTxt}>
                  {t('infobox_reward')}: {userData.trios.total_reward}{' '}
                  {t('UAH')}
                </Text>
              </VStack>
            </>
          )
        )}
        {/* <Image
          style={profileStyles.statImgRight}
          source={require('../../assets/img/si_Arrow_right.png')}
        /> */}
      </View>
    </Pressable>
  );
};

const ProfileScreen = ({navigation, route}) => {
  const {user, logout, t, screenWidth, theme, i18n, setScreenParams, addHistory} = useContext(AuthContext);
  let fontSizeBig =
    Math.ceil(screenWidth / 18) < 40 ? Math.ceil(screenWidth / 12) : 40;
  let fontSizeMd =
    Math.ceil(screenWidth / 20) < 24 ? Math.ceil(screenWidth / 14) : 24;
  let commonStyles =
    screenWidth < 1080
      ? commonStylesMobile(wp, hp, theme)
      : commonStylesDesktop(wp, hp, theme);
  let profileStyles =
    screenWidth < 1080
      ? profileStylesMobile(wp, hp, fontSizeBig, fontSizeMd, theme)
      : profileStylesDesktop(wp, hp, fontSizeBig, fontSizeMd, theme);

  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(null);
  const [info, setInfo] = useState('');
  const [userData, setUserData] = useState({});
  const [updatedUserData, setUpdatedUserData] = useState(user);
  const [fullRefLink, setFullRefLink] = useState('');
  // const [messages, setMessages] = useState([]);
  const [visible, setVisible] = useState(false);
  const isFocused = useIsFocused();

  useEffect(() => {
    // console.log('Profile useEffect');
    async function getUserData() {
      const api = AxiosInstance({user, logout});
      setIsLoading(true);

      await api(`/api/v1/users/${user.id}`, {
        method: 'GET',
        mode: 'cors',
      })
      .then(response => {
        setUserData(response.data.message);
        setUpdatedUserData(response.data.message);
        AsyncStorage.setItem('userData', JSON.stringify(response.data.message));
        // console.log('user response', updatedUserData);
        setFullRefLink(
          `https://${response.data.message.ref_link}.${Config.SHOP_MAIN_DOMAIN}`
        );
      })
      .catch(error => {
        console.log('PROFILE SCREEN CATCH ERROR', error);
        if (error.code && error.code === 403) {
          logout();
        }
        setIsError(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
    }

    if (isFocused) {
      getUserData();
    }

    return () => {
      // console.log('Profile useEffect unmount');
    };
  }, [user, logout, isFocused, i18n, setUpdatedUserData]);

  useEffect(() => {
    if (Platform.OS !== 'web') {
      addHistory(route.name);
    }
  }, [addHistory, route]);

  if (isLoading) {
    return <Loader />;
  } else if (Object.keys(userData).length) {
    return (
      <ScrollView contentContainerStyle={commonStyles.container}>
        <Header navigation={navigation} route={route} />
        <View style={profileStyles.profileContainer}>
          <View style={profileStyles.personalLink}>
            <View>
              <View style={{...profileStyles.refLinkCaption, flexDirection: 'row'}}>
                <Text style={profileStyles.refLinkCaption}>
                  {t('your_personal_link')}:
                </Text>
                <Pressable onPress={() => setVisible(!visible)} style={profileStyles.icon}>
                  <Text style={profileStyles.iconText}>?</Text>
                </Pressable>
              </View>
              <HStack style={profileStyles.infoContainer}>
                <View style={profileStyles.refLinkContainer}>
                  <Text style={profileStyles.refLinkText}>{fullRefLink}</Text>
                </View>
                <Pressable
                  onPress={() => {
                    Clipboard.setString(fullRefLink);
                    setInfo(t('ref_copied'));
                  }}>
                  {Platform.OS === 'ios' ? (
                    <Ionicons name="copy" size={25} color="#007AFF" />
                  ) : (
                    <Icon
                      name="copy"
                      size={30}
                      color={theme.FONT_COLOR_BLACK}
                    />
                  )}
                </Pressable>
                {Platform.OS !== 'web' ? (
                  <ShareWithChoice url={fullRefLink} />
                ) : (
                  <ShareComponent
                    url={fullRefLink}
                  />
                )}
                <Pressable
                  onPress={() => {
                    setScreenParams({ref_link: updatedUserData.ref_link, phone: updatedUserData.phone});
                    navigation.navigate('ProfileLinkEditing');
                  }}
                  style={profileStyles.refLinkEdit}>
                  <Text style={profileStyles.refLinkEditText}>{t('edit')}</Text>
                </Pressable>
              </HStack>
            </View>
          </View>
          <View style={profileStyles.profileInfo}>
            {userData.photo ? (
              <Image
                style={profileStyles.profilePhoto}
                source={{uri: `data:image/jpeg;base64,${userData.photo}`}}
              />
            ) : (
              <ImageBackground
                source={require('../../assets/img/avatar100x100.png')}
                style={{...profileStyles.image, marginLeft: 15}}>
                <Text style={profileStyles.initials}>
                  {formInitials(userData.name)}
                </Text>
              </ImageBackground>
            )}
            <VStack style={profileStyles.profileTextContainer}>
              <Text style={profileStyles.nameText}>{userData.name}</Text>
              <Text style={profileStyles.profileText}>{t('my_profile')}</Text>
            </VStack>
          </View>
          <TouchableOpacity
            style={profileStyles.buttonBlack}
            onPress={() => {
              setScreenParams(userData);
              // navigation.navigate('ProfileEditing', {userData: userData});
              navigation.navigate('ProfileEditing');
            }}>
            <Text style={profileStyles.buttonText}>{t('edit_profile')}</Text>
          </TouchableOpacity>

          <View style={profileStyles.infoBoxes}>
            <View style={profileStyles.infoBoxesRow}>
              <InfoBox
                userData={userData}
                navigation={navigation}
                part={'Balance'}
                boxStyle={profileStyles.infoBoxBlue}
              />
              <InfoBox
                userData={userData}
                navigation={navigation}
                part={'Trios'}
                boxStyle={profileStyles.infoBoxLightBlue}
              />
            </View>
            <View style={profileStyles.infoBoxesRow}>
              <InfoBox
                userData={userData}
                navigation={navigation}
                part={'Invitations'}
                boxStyle={
                  screenWidth > 700
                    ? profileStyles.infoBoxLightBlue
                    : profileStyles.infoBoxBlue
                }
              />
              <InfoBox
                userData={userData}
                navigation={navigation}
                part={'Transactions'}
                boxStyle={
                  screenWidth > 700
                    ? profileStyles.infoBoxBlue
                    : profileStyles.infoBoxLightBlue
                }
              />
            </View>
          </View>

          <View style={profileStyles.welcomeContainer}>
            <View style={profileStyles.welcome}>
              <Text style={profileStyles.welcomeText}>{t('welcome')}</Text>
              <Text style={profileStyles.welcomeText}>{t('main_text_1')}</Text>
              <Text style={profileStyles.welcomeText}>{t('main_text_2')}</Text>

              <Text style={profileStyles.welcomeBlock1}>
                {t('main_text_3')}
              </Text>

              <Text style={profileStyles.welcomeBlock2}>{t('advantages')}</Text>
            </View>
            <View>
              <Text></Text>
            </View>
          </View>
          <View style={profileStyles.advantages}>
            <View style={profileStyles.advantagesRow}>
              <View style={profileStyles.advantagesCol}>
                <Text style={profileStyles.advantagesHead}>
                  {t('overview')}
                </Text>
                <Text style={profileStyles.advantagesContent}>
                  {t('advantages_text')}
                </Text>
              </View>
              <View style={profileStyles.advantagesCol}>
                <Text style={profileStyles.advantagesHead}>{t('promo')}</Text>
                <Text style={profileStyles.advantagesContent}>
                  {t('promo_text')}
                </Text>
              </View>
            </View>
            <View style={profileStyles.advantagesRow}>
              <View style={profileStyles.advantagesCol}>
                <Text style={profileStyles.advantagesHead}>
                  {t('settings_and_profile')}
                </Text>
                <Text style={profileStyles.advantagesContent}>
                  {t('settings_and_profile_text')}
                </Text>
              </View>
              <View style={profileStyles.advantagesCol}>
                <Text style={profileStyles.advantagesHead}>{t('support')}</Text>
                <Text style={profileStyles.advantagesContent}>
                  {t('support_text')}
                </Text>
              </View>
            </View>
            <View style={profileStyles.marketLinks}>
              <Text style={profileStyles.advantagesHead}>
                {t('personal_links')}
              </Text>
              <Text style={profileStyles.advantagesContent}>
                {t('personal_links_text')}
              </Text>
            </View>
          </View>
        </View>
        <Footer navigation={navigation}/>
        {isError && <ErrorModal error={isError} setIsError={setIsError} />}
        {info ? <InfoModal info={info} setInfo={setInfo} /> : <></>}
        <ToolTipModal visible={visible} setVisible={setVisible}/>
        {Platform.OS !== 'web' && <Empty />}
      </ScrollView>
    );
  } else {
    return <ErrorScreen />;
  }
};

const ProfileViewWeb = ({navigation, route}) => {
  const {t, screenWidth} = useContext(AuthContext);
  const FirstPage = screenWidth < 1080 ? FirstPageMobile : FirstPageWeb;
  return (
    <GoogleOAuthProvider
      clientId={Config.GOOGLE_CLIENT_ID}
      redirectUri={Config.GOOGLE_API_REDIRECT_URL}>
      <ProfileStack.Navigator
        initialRouteName="Profile"
        screenOptions={{
          headerShown: false,
          // freezeOnBlur: true,
          unmountOnBlur: true,
        }}>
        <ProfileStack.Screen
          name="Profile"
          component={ProfileScreen}
          options={{title: t('profile')}}
        />
        <ProfileStack.Screen
          name="Balance"
          component={Balance}
          options={{title: t('balance')}}
        />
        <ProfileStack.Screen
          name="Trios"
          component={TriosScreen}
          options={{title: t('trios')}}
        />
        <ProfileStack.Screen
          name="Transactions"
          component={Transactions}
          options={{title: t('transactions')}}
        />
        <ProfileStack.Screen
          name="Invitations"
          component={InvitationsScreen}
          options={{title: t('invitations')}}
        />
        <ProfileStack.Screen
          name="WebContacts"
          component={WebContacts}
          options={{title: t('my_contacts')}}
        />
        <ProfileStack.Screen
          name="History"
          component={ActivityScreen}
          options={{title: t('history')}}
        />
        <ProfileStack.Screen
          name="Withdraw"
          component={WithdrawScreen}
          options={{title: t('withdraws')}}
        />
        <ProfileStack.Screen
          name="PrivacyPolicy"
          component={PrivacyPolicy}
          options={{title: t('privacy_policy')}}
        />
        <ProfileStack.Screen
          name="Contacts"
          component={Contacts}
          options={{title: t('contacts')}}
        />
        {/* <ProfileStack.Screen
          name="FirstPage"
          component={FirstPage}
          options={{title: t('welcome')}}
        /> */}
        <ProfileStack.Screen
          name="About"
          component={About}
          options={{title: t('about')}}
        />
        <ProfileStack.Screen
          name="ProfileLinkEditing"
          component={ProfileEditLink}
          options={{title: t('editing_personal_link')}}
        />
        <ProfileStack.Screen
          name="ProfileEditing"
          component={ProfileEditing}
          options={{title: t('editing_profile')}}
        />
        <ProfileStack.Screen
          name="ActivityDetails"
          component={ActivityDetails}
          options={{title: t('activity_details')}}
        />
      </ProfileStack.Navigator>
    </GoogleOAuthProvider>
  );
};

const ProfileViewMobile = () => {
  const {t, screenWidth, theme} = useContext(AuthContext);
  const FirstPage = screenWidth < 1080 ? FirstPageMobile : FirstPageWeb;
  return (
    <>
      <ProfileStack.Navigator
        screenOptions={({route}) => ({
          tabBarIcon: ({focused, color, size}) => {
            let iconName;

            if (route.name === 'Profile') {
              iconName = focused ? 'id-card' : 'id-card-outline';
            } else if (route.name === 'Invitations') {
              iconName = focused ? 'mail' : 'mail-outline';
            } else if (route.name === 'Trios') {
              iconName = focused ? 'people-circle' : 'people-circle-outline';
              // } else if (route.name === 'Transactions') {
              //   iconName = focused ? 'file-tray-full' : 'file-tray-full-outline';
            } else if (route.name === 'Balance') {
              iconName = focused ? 'cash' : 'cash-outline';
              // } else if (route.name === 'Withdraw') {
              //   iconName = focused ? 'card' : 'card-outline';
            } else if (route.name === 'History') {
              iconName = focused ? 'watch' : 'watch-outline';
            }

            return <Ionicons name={iconName} size={size} color={color} />;
          },
          tabBarActiveTintColor: theme.BLUE,
          tabBarInactiveTintColor: theme.BLACK,
          headerShown: false,
          tabBarHideOnKeyboard: true,
          // freezeOnBlur: true,
          unmountOnBlur: true,
        })}>
        <ProfileStack.Screen
          name="Profile"
          options={{tabBarLabel: t('profile')}}
          component={ProfileScreen}
        />
        <ProfileStack.Screen
          name="Balance"
          options={{tabBarLabel: t('balance')}}
          component={Balance}
        />
        <ProfileStack.Screen
          name="Trios"
          options={{tabBarLabel: t('trios')}}
          component={TriosScreen}
        />
        {/* <ProfileStack.Screen
          name="Transactions"
          options={{tabBarButton: () => null}}
          component={Transactions}
        /> */}
        <ProfileStack.Screen
          name="Invitations"
          options={{tabBarLabel: t('invitations')}}
          component={InvitationsScreen}
        />
        <ProfileStack.Screen
          name="ContactsList"
          component={ContactList}
          options={{title: t('phone_book'), tabBarButton: () => null}}
        />
        <ProfileStack.Screen
          name="OtherContactsList"
          component={OtherContactList}
          options={{title: t('other_contacts'), tabBarButton: () => null}}
        />
        <ProfileStack.Screen
          name="History"
          options={{tabBarLabel: t('history')}}
          component={ActivityScreen}
        />
        {/* <ProfileStack.Screen
          name="Withdraw"
          options={{tabBarLabel: t('finances')}}
          component={WithdrawScreen}
        /> */}
        <ProfileStack.Screen
          name="PrivacyPolicy"
          component={PrivacyPolicy}
          options={{title: t('privacy_policy'), tabBarButton: () => null}}
        />
        <ProfileStack.Screen
          name="Contacts"
          component={Contacts}
          options={{title: t('contacts'), tabBarButton: () => null}}
        />
        <ProfileStack.Screen
          name="About"
          component={About}
          options={{title: t('about'), tabBarButton: () => null}}
        />
        <ProfileStack.Screen
          name="FirstPage"
          component={FirstPage}
          options={{title: t('welcome'), tabBarButton: () => null}}
        />
        <ProfileStack.Screen
          name="ProfileLinkEditing"
          component={ProfileEditLink}
          options={{title: t('profile_edit_link'), tabBarButton: () => null}}
        />
        <ProfileStack.Screen
          name="ProfileEditing"
          component={ProfileEditing}
          options={{title: t('profile_edit'), tabBarButton: () => null}}
        />
        <ProfileStack.Screen
          name="ActivityDetails"
          component={ActivityDetails}
          options={{title: t('activity_details'), tabBarButton: () => null}}
        />
      </ProfileStack.Navigator>
    </>
  );
};

const ProfileView = Platform.OS === 'web' ? ProfileViewWeb : ProfileViewMobile;

export default ProfileView;
