import React, {useContext} from 'react';
import {TouchableOpacity, ScrollView, Modal, Text, View} from 'react-native';
import {AuthContext} from '../../components/AuthProvider';

import {default as stylesF} from '../../styles/toolTip';

const ToolTipModal = ({ visible, setVisible }) => {
  const {t, theme} = useContext(AuthContext);
  const styles = stylesF(theme);

  return (
    <Modal
      transparent={true}
      visible={visible}
      animationType="slide"
      onRequestClose={() => setVisible(false)}
    >
      <View style={styles.centeredView}>
        <View style={styles.modalView}>
          <Text style={styles.modalTitle}>{t('ref_link_tooltip_title')}</Text>
          <ScrollView style={styles.modalContent}>
            <Text>{t('ref_link_tooltip')}</Text>
          </ScrollView>
          <TouchableOpacity style={styles.closeButton} onPress={() => setVisible(false)}>
            <Text style={styles.closeButtonText}>{t('close')}</Text>
          </TouchableOpacity>
        </View>
      </View>
    </Modal>
  );
};

export default ToolTipModal;