import {StyleSheet, Platform} from 'react-native';

const styles = {
  web: (wp, hp, theme) =>
    StyleSheet.create({
      firstBlock: {
        flexDirection: 'row',
        backgroundColor: 'white',
        height: 640,
      },
      loginContainer: {
        top: 134,
        left: 84,
        height: 300,
      },
      firstPageLogo: {
        width: 540,
        height: 97,
        marginTop: 70,
        left: -40,
        // alignItems: 'center',
      },
      logoTextTrio: {
        fontFamily: theme.FONT_FAMILY_BOLD,
        fontSize: 102,
        color: theme.FONT_COLOR_BLUE,
        lineHeight: 102,
        marginTop: -50,
        marginLeft: 70,
      },
      logoTextBottom: {
        fontFamily: theme.FONT_FAMILY_MEDIUM,
        fontSize: 36,
        fontWeight: '500',
        color: theme.FONT_COLOR_BLACK,
        // letterSpacing: -1,
        marginTop: 42,
        marginLeft: 70,
      },
      btnGroup: {
        width: 300,
        height: 50,
        marginTop: 70,
        marginLeft: 30,
        flexDirection: 'row',
        gap: 30,
      },
      btnCommon: {
        width: 178,
        heigth: 48,
        borderRadius: 14,
        backgroundColor: theme.BLUE,
        paddingVertical: 10,
        paddingHorizontal: 20,
        alignItems: 'center',
        justifyContent: 'center',
      },
      btnCommonTxt: {
        fontFamily: theme.FONT_FAMILY_MEDIUM,
        color: theme.FONT_COLOR_WHITE,
        fontSize: 24,
      },
      blocksContainer: {
        width: 540,
        height: 565,
      },
      greyBlock: {
        height: '100%',
      },
      blueBlock1: {
        width: 493,
        height: 274,
        right: -47,
      },
      blueBlock2: {
        width: 316,
        height: 274,
        marginTop: 90,
        right: -224,
      },
      secondBlock: {
        width: 1080,
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
        paddingHorizontal: 72,
        backgroundColor: theme.WHITE,
        // marginTop: -55,
      },
      secondBlockPart1: {
        alignSelf: 'flex-end',
        // marginTop:50,
      },
      secondBlockPart2: {
        width: '100%',
      },
      secondBlockPart3: {
        alignSelf: 'flex-start',
        height: 192,
      },
      blueBox1: {
        width: 223,
        height: 280,
        backgroundColor: theme.BLUE,
        borderRadius: 15,
        paddingHorizontal: 20,
        paddingVertical: 40,
        marginTop: -185,
      },
      blackBox1: {
        width: 223,
        height: 280,
        backgroundColor: theme.BLACK,
        marginLeft: 233,
        marginTop: -149,
        borderRadius: 15,
        paddingHorizontal: 20,
        paddingVertical: 40,
      },
      blueBox2: {
        width: 223,
        height: 280,
        backgroundColor: theme.BLUE,
        marginLeft: 466,
        marginTop: -149,
        borderRadius: 15,
        paddingHorizontal: 20,
        paddingVertical: 40,
      },
      blackBox2: {
        width: 223,
        height: 280,
        backgroundColor: theme.BLACK,
        marginLeft: 699,
        marginTop: -149,
        borderRadius: 15,
        paddingHorizontal: 20,
        paddingVertical: 40,
      },
      boxHead: {
        fontFamily: theme.FONT_FAMILY_MEDIUM,
        color: theme.FONT_COLOR_WHITE,
        fontSize: 14,
      },
      block2TextContent: {
        fontFamily: theme.FONT_FAMILY,
        color: theme.FONT_COLOR_WHITE,
        fontSize: 13,
        letterSpacing: 0,
        marginTop: 10,
      },
      secondBlockText: {
        fontFamily: theme.FONT_FAMILY_MEDIUM,
        fontSize: 64,
        lineHeight: 62,
        textAlign: 'right',
      },
      secondBlockText2: {
        fontFamily: theme.FONT_FAMILY_MEDIUM,
        fontSize: 64,
        lineHeight: 62,
        textAlign: 'left',
      },
      ellips: {
        width: 384,
        height: 186,
        marginTop: -193,
        marginLeft: -5,
      },
      thirdBlock: {
        width: 1080,
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: theme.WHITE,
        paddingTop: 85,
      },
      thirdBlockTxt1Container: {
        width: 869,
        height: 139,
      },
      thirdBlockTxt1: {
        fontFamily: theme.FONT_FAMILY_MEDIUM,
        fontSize: 32,
        fontWeight: 500,
        lineHeight: 31,
        textAlign: 'right',
      },
      thirdBlockPart2: {
        width: '100%',
        flexDirection: 'row',
        justifyContent: 'space-between',
      },
      ringsLeft: {
        marginTop: -98,
      },
      ringsRight: {},
      trioArea: {
        width: 261,
        height: 72,
        paddingHorizontal: 20,
        paddingVertical: 10,
        backgroundColor: theme.BLUE,
        borderRadius: 15,
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: 75,
      },
      trioAreaTxt: {
        fontFamily: theme.FONT_FAMILY_MEDIUM,
        color: theme.FONT_COLOR_WHITE,
        fontSize: 32,
        lineHeight: 31,
      },
      left_ring_1: {
        width: 127,
        height: 145,
      },
      left_ring_2: {
        width: 261,
        height: 262,
        marginTop: -352
      },
      left_ring_3: {
        width: 166,
        height: 190,
        marginTop: 56,
      },
      right_ring_1: {
        width:172,
        height: 178,
        marginLeft: 65,
        marginTop: -25,
      },
      right_ring_2: {
        width: 260,
        height: 260,
        marginTop: -105,
        marginLeft: -45,
      },
      fouthBlock: {
        flex: 1,
        width: 1080,
        backgroundColor: theme.WHITE,
        alignItems: 'center',
        justifyContent: 'flex-start',
      },
      fouthBlockTxtContainer: {
        marginLeft: -215,
      },
      fouthBlockPart2: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        width: 958,
        marginTop: 65,
      },
      leftRings1:{

      },
      left_ring_1_1: {
        width:172,
        height: 178,
      },
      left_ring_2_1: {
        width: 260,
        height: 260,
        marginTop: -80,
        marginLeft: -5,
      },
      loginContainer1: {
        marginTop: 85,
      },
      btnGroup2: {
        width: 469,
        height: 50,
        marginTop: 60,
        marginLeft: 30,
        flexDirection: 'row',
        gap: 50,
        alignSelf: 'flex-end',
      },
      btnCommonBlack: {
        width: 206,
        height: 66,
        backgroundColor: theme.BLACK,
        paddingHorizontal: 20,
        paddingVertical: 10,
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: 14,
      },
    }),
  android: (wp, hp, theme) =>
    StyleSheet.create({
      firstPageLogo: {},
    }),
  ios: (wp, hp, theme) =>
    StyleSheet.create({
      firstPageLogo: {},
    }),
};

export default styles[Platform.OS];
