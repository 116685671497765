import React, {useContext} from 'react';
import {Pressable, StyleSheet, Image, Modal} from 'react-native';
import {Text, View} from 'native-base';
import {AuthContext} from '../../components/AuthProvider';

const InfoModal = ({info, setInfo}) => {
  const {t} = useContext(AuthContext);
  return (
    <View style={styles.container}>
      <Modal
        animationType="none"
        transparent={true}
        visible={true}
        onRequestClose={() => {
          setInfo(false);
        }}>
        <View style={styles.errorModalContainer}>
          <View style={styles.errorModalContent}>
            <Text style={styles.errorModalCaption}>{t('info')}</Text>
            <Text style={styles.errorText}>{t(info)}</Text>
            <Pressable
              style={styles.errorModalBtn}
              onPress={() => {
                setInfo(false);
              }}>
              <Image
                style={styles.errorIconClose}
                source={require('../../assets/img/si_Arrow_right.png')}
              />
            </Pressable>
          </View>
        </View>
      </Modal>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
    justifyContent: 'center',
    position: 'fixed',
    alignSelf: 'center',
    zIndex: 11,
    top: '-70%',
  },
  errorModalContainer: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  errorModalContent: {
    width: 320,
    height: 131,
    borderRadius: 40,
    borderWidth: 1,
    backgroundColor: '#FFF',
    gap: 12,
  },
  errorModalCaption: {
    fontFamily: 'Inter',
    fontSize: 24,
    fontWeight: 'bold',
    lineHeight: 29,
    textAlign: 'left',
    marginTop: 10,
    marginLeft: 20,
  },
  errorModalBtn: {
    width: 24,
    height: 24,
    alignSelf: 'flex-end',
    marginRight: 32,
    marginBottom: 32,
  },
  errorIconClose: {
    width: 24,
    height: 24,
    zIndex: 11,
  },
  errorText: {
    marginLeft: 20,
    fontFamily: 'Inter',
    fontSize: 12,
    fontWeight: 'bold',
    lineHeight: 15,
    textAlign: 'left',
  },
});

export default InfoModal;
