import {Text, View, TouchableOpacity} from 'react-native';
import Ionicons from 'react-native-vector-icons/Ionicons';

const IOSCustomPagination = ({currentPage, totalPages, onPageChange}) => (
  <View
    style={{
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      padding: 10,
    }}>
    <TouchableOpacity
      onPress={() => onPageChange(1)}
      disabled={currentPage === 1}
      style={{marginRight: 20}}>
      <Ionicons
        name="caret-back-outline"
        size={24}
        color={currentPage === 1 ? '#ccc' : 'black'}
      />
    </TouchableOpacity>
    <TouchableOpacity
      onPress={() => onPageChange(currentPage - 1)}
      disabled={currentPage === 1}>
      <Ionicons
        name="chevron-back-outline"
        size={24}
        color={currentPage === 1 ? '#ccc' : 'black'}
      />
    </TouchableOpacity>
    <Text
      style={{marginHorizontal: 15}}>{`${currentPage} of ${totalPages}`}</Text>
    <TouchableOpacity
      onPress={() => onPageChange(currentPage + 1)}
      disabled={currentPage === totalPages}>
      <Ionicons
        name="chevron-forward-outline"
        size={24}
        color={currentPage === totalPages ? '#ccc' : 'black'}
      />
    </TouchableOpacity>
    <TouchableOpacity
      onPress={() => onPageChange(totalPages)}
      disabled={currentPage === totalPages}
      style={{marginLeft: 20}}>
      <Ionicons
        name="caret-forward-outline"
        size={24}
        color={currentPage === totalPages ? '#ccc' : 'black'}
      />
    </TouchableOpacity>
  </View>
);

export default IOSCustomPagination;
