import {Platform, StyleSheet} from 'react-native';

const platformStyles = {
  web: (wp, hp, theme) =>
    StyleSheet.create({
      contentHead: {
        marginTop: 10,
      },
      threeIcon: {
        width: 80,
        height: 40,
        left: 5,
      },
      btnGroup: {
        marginTop: 31,
        gap: 12,
        marginBottom: 32,
        flex: 1,
        justifyContent: 'center',
      },
      btnProfile: {
        width: 184,
        height: 39,
        paddingHorizontal: 10,
        paddingVertical: 10,
        borderWidth: 1,
        borderStyle: 'solid',
        borderRadius: 10,
        borderColor: '#000000',
        alignItems: 'center',
        justifyContent: 'center',
      },
      btnText: {
        fontFamily: 'Inter',
        fontSize: 14,
        fontWeight: 'normal',
        lineHeight: 16,
        textAlign: 'center',
      },
      divider: {
        flex: 1,
      },
      itemCell: {
        fontFamily: theme.FONT_FAMILY_MEDIUM,
        color: theme.FONT_COLOR_BLUE,
        fontSize: 16,
        justifyContent: 'center',
      },
      trioStats: {
        marginTop: 32,
        gap: 20,
        alignItems: 'center',
        justifyContent: 'center',
        flexWrap: 'wrap',
        flexDirection: 'row',
      },
      trioStatBlack: {
        width: 140,
        height: 140,
        borderRadius: 13,
        backgroundColor: theme.BLACK,
        paddingHorizontal: 10,
        paddingTop: 15,
      },
      trioStatBlue: {
        width: 140,
        height: 140,
        borderRadius: 13,
        backgroundColor: theme.BLUE,
        paddingHorizontal: 10,
        paddingTop: 15,
      },
      trioStatTxt1: {
        width: 120,
        height: 30,
        marginTop: 10,
        fontFamily: theme.FONT_FAMILY_MEDIUM,
        color: theme.FONT_COLOR_WHITE,
        fontSize: 16,
        lineHeight: 18,
        textAlign: 'left',
      },
      trioStatTxt2: {
        fontFamily: theme.FONT_FAMILY_MEDIUM,
        color: theme.FONT_COLOR_WHITE,
        height: 20,
        marginTop: 10,
        fontSize: 18,
        lineHeight: 20,
        textAlign: 'left',
      },
      trioStatTxt3: {
        fontFamily: theme.FONT_FAMILY,
        color: theme.FONT_COLOR_WHITE,
        width: 50,
        height: 15,
        fontSize: 12,
        lineHeight: 14,
        textAlign: 'left',
      },
      radioGroup: {
        padding: 10,
        marginTop: 20,
      },
      cardStyle: {
        width: wp('90%'),
        margin: 2,
        maxWidth: 980,
        alignSelf: 'center',
        backgroundColor: theme.BLUE,
        borderRadius: 10,
      },
      cardTitleStyle: {
        fontFamily: theme.FONT_FAMILY_MEDIUM,
        color: theme.FONT_COLOR_WHITE,
        fontSize: 16,
      },
      cardSubtitleStyle: {
        fontFamily: theme.FONT_FAMILY,
        color: theme.FONT_COLOR_WHITE,
        fontSize: 14,
      },
      cardContentStyle: {
        fontFamily: theme.FONT_FAMILY,
        color: theme.FONT_COLOR_WHITE,
        fontSize: 14,
      },
      segmentButtons: {
        width: wp('90%'),
        maxWidth: 600,
        marginTop: 20,
        marginBottom: 20,
      }
    }),
  android: (wp, hp, theme) =>
    StyleSheet.create({
      contentHead: {
        marginTop: 10,
      },
      threeIcon: {
        width: 80,
        height: 40,
        left: 5,
      },
      btnGroup: {
        marginTop: 31,
        gap: 12,
        marginBottom: 32,
        flex: 1,
        justifyContent: 'center',
      },
      btnProfile: {
        width: 184,
        height: 39,
        paddingHorizontal: 10,
        paddingVertical: 10,
        borderWidth: 1,
        borderStyle: 'solid',
        borderRadius: 10,
        borderColor: '#000000',
        alignItems: 'center',
        justifyContent: 'center',
      },
      btnText: {
        fontFamily: theme.FONT_FAMILY,
        fontSize: 14,
        fontWeight: 'normal',
        lineHeight: 16,
        textAlign: 'center',
      },
      divider: {
        flex: 1,
      },
      itemCell: {
        fontFamily: theme.FONT_FAMILY_MEDIUM,
        color: theme.FONT_COLOR_BLUE,
        fontSize: 16,
        justifyContent: 'center',
      },
      trioStats: {
        marginTop: 32,
        gap: 20,
        alignItems: 'center',
        justifyContent: 'center',
        flexWrap: 'wrap',
        flexDirection: 'row',
      },
      trioStatBlack: {
        width: 140,
        height: 140,
        borderRadius: 13,
        backgroundColor: theme.BLACK,
        paddingHorizontal: 10,
        paddingTop: 15,
      },
      trioStatBlue: {
        width: 140,
        height: 140,
        borderRadius: 13,
        backgroundColor: theme.BLUE,
        paddingHorizontal: 10,
        paddingTop: 15,
      },
      trioStatTxt1: {
        width: 120,
        height: 45,
        marginTop: 10,
        fontFamily: theme.FONT_FAMILY_MEDIUM,
        color: theme.FONT_COLOR_WHITE,
        fontSize: 16,
        lineHeight: 18,
        textAlign: 'left',
      },
      trioStatTxt2: {
        fontFamily: theme.FONT_FAMILY_MEDIUM,
        color: theme.FONT_COLOR_WHITE,
        height: 20,
        marginTop: 10,
        fontSize: 18,
        lineHeight: 20,
        textAlign: 'left',
      },
      trioStatTxt3: {
        fontFamily: theme.FONT_FAMILY,
        color: theme.FONT_COLOR_WHITE,
        width: 50,
        height: 15,
        fontSize: 12,
        lineHeight: 14,
        textAlign: 'left',
      },
      radioGroup: {
        padding: 10,
        marginTop: 20,
      },
      cardStyle: {
        width: wp('90%'),
        margin: 2,
        maxWidth: 980,
        alignSelf: 'center',
        backgroundColor: theme.BLUE,
        borderRadius: 10,
      },
      cardTitleStyle: {
        fontFamily: theme.FONT_FAMILY_MEDIUM,
        color: theme.FONT_COLOR_WHITE,
        fontSize: 16,
      },
      cardSubtitleStyle: {
        fontFamily: theme.FONT_FAMILY,
        color: theme.FONT_COLOR_WHITE,
        fontSize: 14,
      },
      cardContentStyle: {
        fontFamily: theme.FONT_FAMILY,
        color: theme.FONT_COLOR_WHITE,
        fontSize: 14,
      },
      segmentButtons: {
        width: wp('90%'),
        maxWidth: 600,
        marginTop: 20,
      }
    }),
  ios: (wp, hp, theme) =>
    StyleSheet.create({
      contentHead: {
        marginTop: 22,
      },
      itemsHeader: {
        width: 350,
        height: 72,
        alignItems: 'center',
        justifyContent: 'center',
        // gap: 60,
        borderBottomWidth: 1,
        borderStyle: 'solid',
        borderBottomColor: '#D9D9D9',
        marginTop: 10,
        marginBottom: 4,
      },
      itemRow: {
        width: 350,
        height: 42,
        alignItems: 'center',
        justifyContent: 'center',
        borderWidth: 1,
        borderRadius: 10,
        borderStyle: 'solid',
        borderColor: '#D9D9D9',
        marginTop: 12,
        // marginLeft: 20,
        // marginRight: 20,
      },
      itemCol: {
        width: '20%',
        // paddingHorizontal: 10,
        textAlign: 'center',
        fontFamily: 'Inter',
        fontSize: 14,
        fontWeight: 'normal',
        lineHeight: 19,
      },
      threeIcon: {
        width: 80,
        height: 40,
        left: 20,
      },
      scrollableContainer: {},
      btnGroup: {
        // paddingHorizontal: 10,
        marginTop: 31,
        gap: 12,
        marginBottom: 100,
        flex: 1,
      },
      btnProfile: {
        width: 130,
        height: 39,
        paddingHorizontal: 5,
        paddingVertical: 5,
        borderWidth: 1,
        borderStyle: 'solid',
        borderRadius: 10,
        borderColor: '#000000',
        alignItems: 'center',
        justifyContent: 'center',
      },
      btnText: {
        fontFamily: 'Inter',
        fontSize: 14,
        // fontWeight: 'normal',
        lineHeight: 19,
        textAlign: 'center',
      },
      divider: {
        flex: 1,
      },
      radioGroup: {
        padding: 10,
        marginTop: 20,
      },
    }),
};

export default platformStyles[Platform.OS];
