import React, {useContext, useEffect, useState} from "react";
import {
  Platform,
  View,
  Pressable,
  Text,
  ScrollView,
  // Image,
} from 'react-native';
import {HStack} from 'native-base';
import {TextInputMask} from 'react-native-masked-text';
import {
  widthPercentageToDP as wp,
  heightPercentageToDP as hp,
} from 'react-native-responsive-screen';

import {AuthContext} from '../../components/AuthProvider';

import ErrorModal from '../../components/common/ErrorModal';
import InfoModal from '../../components/common/InfoModal';
import Header from '../../components/common/Header';
import Footer from '../../components/common/Footer';
import Loader from '../../components/common/Loader';

import profileStylesF from '../../styles/profile';
import profileStylesMobileF from '../../styles/profile_mobile';
import commonStylesF from '../../styles/common';
import commonStylesMobileF from '../../styles/common_mobile';
import stylesF from '../../styles/login';

const CodeVerifying = ({navigation, route}) => {
  const {
    t,
    screenWidth,
    theme,
    login,
    addHistory,
    inputedData,
    setInputedData,
  } = useContext(AuthContext);

  const phone = inputedData.phone;
  const [password, setPassword] = useState(inputedData.password);
  const [isError, setIsError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [info, setInfo] = useState('');

  const fontSizeBig =
    Math.ceil(screenWidth / 18) < 40 ? Math.ceil(screenWidth / 14) : 40;
  const fontSizeMd =
    Math.ceil(screenWidth / 20) < 24 ? Math.ceil(screenWidth / 16) : 24;

  const commonStyles =
    screenWidth < 1080
      ? commonStylesMobileF(wp, hp, theme)
      : commonStylesF(wp, hp, theme);

  const profileStyles =
    screenWidth < 1080
      ? profileStylesMobileF(wp, hp, fontSizeBig, fontSizeMd, theme)
      : profileStylesF(wp, hp, fontSizeBig, fontSizeMd, theme);

  const styles = stylesF(wp, theme);

  const hideLogoutButton = Platform.OS === 'ios';

  const handleSmsLogin = async  () => {
    // Perform validation before sending the request
    setIsLoading(true);
    if (phone === '' || password === '') {
      setIsError(t('enter_code_from_sms'));
      return;
    }

    login(phone, password, navigation).finally(() => setIsLoading(false));
  };

  useEffect(() => {
    if (Platform.OS !== 'web') {
      addHistory(route.name);
    }
  }, [addHistory, route]);
  if (isLoading) {
    return <Loader />
  } else {
    return (
      <View style={commonStyles.container}>
        <ScrollView contentContainerStyle={commonStyles.container}>
          <Header
            navigation={navigation}
            route={route}
            hideLogoutButton={hideLogoutButton}
          />
          <View
            style={
              Platform.OS === 'ios'
                ? styles.iosProfileContainer
                : profileStyles.profileContainer
            }>
            <View style={styles.contentHead}>
              <Text style={commonStyles.contentHeadText}>
                {t('confirmation')}
              </Text>
            </View>
            <View style={styles.content}>
              <Text style={styles.label}>{t('enter_code_from_sms')}*</Text>
              <TextInputMask
                type={'custom'}
                keyboardType="numeric"
                options={{
                  mask: '999999',
                }}
                placeholder={t('code_from_sms')}
                value={password}
                onChangeText={text => setPassword(text)}
                style={styles.phoneInput}
              />
              <Text style={styles.labelBlack}>{t('code_length_label')}</Text>
              <HStack style={styles.btnGroup}>
                <Pressable
                  onPress={() =>
                    Platform.OS === 'web'
                    ? navigation.push('Login')
                    : navigation.navigate('Login')
                  }
                  style={styles.buttonSmall}>
                  <Text style={styles.buttonText}>{t('back')}</Text>
                </Pressable>
                <Pressable
                  onPress={handleSmsLogin}
                  style={styles.buttonSmall}>
                  <Text style={styles.buttonText}>{t('log_in')}</Text>
                </Pressable>
              </HStack>
            </View>
          </View>
          <Footer navigation={navigation} />
        </ScrollView>
        {isError ? (
          <ErrorModal error={isError} setIsError={setIsError} />
        ) : (
          <></>
        )}
        {info ? <InfoModal info={info} setInfo={setInfo} /> : <></>}
        {/* {Platform.OS !== 'web' && <Empty />} */}
    </View>
    )
  }
}

export default CodeVerifying;