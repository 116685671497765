import {Dimensions, Platform, StyleSheet} from 'react-native';

let screenHeight = Dimensions.get('window').height;

const platformStyles = {
  web: (wp, hp, theme) =>
    StyleSheet.create({
      container: {
        // flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: hp('100%'),
        backgroundColor: '#FFF',
      },
      contentContainer: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'flex-start',
        backgroundColor: '#FFF',
        width: wp('100%'),
        maxWidth: 1080,
        flexDirection: 'column',
      },
      headerContainer: {
        flexDirection: 'row',
        justifyContent: 'flex-start',
        width: 1080,
      },
      headBackButton: {
        height: 115,
        width: 120,
        backgroundColor: theme.BLACK,
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: 10,
        marginRight: 170,
        borderTopEndRadius: 20,
        borderBottomEndRadius: 20,
      },
      contentHead: {
        // flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        width: wp('80%'),
        maxWidth: 505,
        height: 115,
        backgroundColor: theme.BLUE,
        marginTop: 10,
        alignSelf: 'center',
        borderRadius: 20,
      },
      arrowLeft: {
        width: 40,
        height: 40,
      },
      contentHeadText: {
        fontFamily: theme.FONT_FAMILY_MEDIUM,
        color: theme.FONT_COLOR_WHITE,
        fontSize: 40,
        lineHeight: 40,
        textAlign: 'center',
      },
      btnGroup: {
        marginTop: 15,
        gap: 12,
        marginBottom: 32,
        // flex: 1,
        flexDirection: 'row',
        height: 30

      },
      btnProfile: {
        width: 184,
        height: 39,
        paddingHorizontal: 40,
        paddingVertical: 10,
        borderWidth: 1,
        borderStyle: 'solid',
        borderRadius: 10,
        borderColor: '#000000',
        alignItems: 'center',
        justifyContent: 'center',
      },
      btnCommon: {
        width: 99,
        height: 30,
        paddingHorizontal: 15,
        paddingVertical: 7,
        borderWidth: 1,
        borderStyle: 'solid',
        borderRadius: 15,
        borderColor: '#000000',
        backgroundColor: theme.BLACK,
        alignItems: 'center',
        justifyContent: 'center',
      },
      btnCommonBig: {
        width: 184,
        height: 39,
        paddingHorizontal: 15,
        paddingVertical: 7,
        borderWidth: 1,
        borderStyle: 'solid',
        borderRadius: 15,
        backgroundColor: theme.BLACK,
        alignItems: 'center',
        justifyContent: 'center',
      },
      btnCommonTxtSmall: {
        fontFamily:theme.FONT_FAMILY_MEDIUM,
        fontSize: 12,
        color: theme.FONT_COLOR_WHITE,
        // color: '#FFF',
      },
      btnCommonTxt: {
        fontFamily: theme.FONT_FAMILY_MEDIUM,
        fontSize: 12,
        color: theme.FONT_COLOR_WHITE,
        // color: '#FFF',
      },
      btnCommonTxtBig: {
        fontFamily: theme.FONT_FAMILY_MEDIUM,
        fontSize: 16,
        color: theme.FONT_COLOR_WHITE,
        // color: '#FFF',
      },
      divider: {
        flex: 1,
      },
      radioGroup: {
        padding: 2,
        marginTop: 10,
      },
      radioText: {
        fontSize: 14,
        left: -2,
      },
    }),
  android: (wp, hp, theme) =>
    StyleSheet.create({
      container: {
        minHeight: hp('100%'),
        width: wp('100%'),
        backgroundColor: '#FFF',
      },
      contentContainer: {
        flex: 1,
        alignItems: 'center',
        backgroundColor: '#FFF',
        width: wp('100%'),
        maxWidth: 1080,
        flexDirection: 'column',
      },
      contentHead: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        width: wp('80%'),
        marginTop: 10,
        alignSelf: 'center',
      },
      contentHeadText: {
        fontFamily: 'Inter',
        fontSize: 40,
        fontWeight: 'normal',
        lineHeight: 48,
        textAlign: 'left',
        height: 48,
      },
      btnGroup: {
        marginTop: 31,
        gap: 12,
        marginBottom: 32,
        flex: 1,
      },
      btnProfile: {
        width: 184,
        height: 39,
        paddingHorizontal: 40,
        paddingVertical: 10,
        borderWidth: 1,
        borderStyle: 'solid',
        borderRadius: 10,
        borderColor: '#000000',
        // backgroundColor: '#D9D9D9',
        alignItems: 'center',
        justifyContent: 'center',
      },
      btnCommon: {
        width: 287,
        height: 39,
        paddingHorizontal: 40,
        paddingVertical: 10,
        borderWidth: 1,
        borderStyle: 'solid',
        borderRadius: 10,
        borderColor: '#000000',
        backgroundColor: '#FFF',
        alignItems: 'center',
        justifyContent: 'center',
      },
      divider: {
        flex: 1,
      },
      radioGroup: {
        padding: 2,
        marginTop: 10,
      },
      radioText: {
        fontSize: 14,
        left: -2,
      },
    }),
  ios: (wp, hp, theme) =>
    StyleSheet.create({
      container: {
        // flex: 1,
        // alignItems: 'center',
        // justifyContent: 'center',
        minHeight: hp('100%'),
        // height: hp('80%'),
        width: wp('100%'),
        backgroundColor: '#FFF',
      },
      contentContainer: {
        flex: 1,
        alignItems: 'center',
        // justifyContent: 'center',
        backgroundColor: '#FFF',
        width: wp('100%'),
        maxWidth: 1080,
        flexDirection: 'column',
        // borderWidth: 2,
        // borderStyle: 'solid',
        // borderColor: 'green',
        // flexGrow: 1,
      },
      contentHead: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        width: wp('80%'),
        marginTop: 10,
        alignSelf: 'center',
      },
      contentHeadText: {
        fontFamily: 'Inter',
        fontSize: 40,
        fontWeight: 'normal',
        lineHeight: 48,
        textAlign: 'left',
        height: 48,
      },
      btnGroup: {
        marginTop: 31,
        gap: 12,
        marginBottom: 32,
        flex: 1,
      },
      btnProfile: {
        width: 184,
        height: 39,
        paddingHorizontal: 40,
        paddingVertical: 10,
        borderWidth: 1,
        borderStyle: 'solid',
        borderRadius: 10,
        borderColor: '#000000',
        // backgroundColor: '#D9D9D9',
        alignItems: 'center',
        justifyContent: 'center',
      },
      btnCommon: {
        width: 287,
        height: 39,
        paddingHorizontal: 40,
        paddingVertical: 10,
        borderWidth: 1,
        borderStyle: 'solid',
        borderRadius: 10,
        borderColor: '#000000',
        backgroundColor: '#FFF',
        alignItems: 'center',
        justifyContent: 'center',
      },
      divider: {
        flex: 1,
      },
      radioGroup: {
        padding: 2,
        marginTop: 10,
      },
      radioText: {
        fontSize: 14,
        left: -2,
      },
    }),
};

export default platformStyles[Platform.OS];
