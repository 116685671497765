import React, {useEffect, useState, useContext, useCallback} from 'react';
import {FlatList, Text, View} from 'react-native';
import {
  widthPercentageToDP as wp,
  heightPercentageToDP as hp,
} from 'react-native-responsive-screen';
import {AuthContext} from '../../components/AuthProvider';
import {
  TrioCard,
  TrioTable,
  TriosHeaderCard,
  TriosHeaderTable,
} from '../Trio/TrioItem';
import axios from 'axios';

import Header from '../../components/common/AdminHeader';
import Loader from '../../components/common/Loader';
import ErrorModal from '../../components/common/ErrorModal';
import PaginationFooter from '../../components/common/paginationFooter';

import {default as commonStylesF} from '../../styles/common';
import adminStyles from '../../styles/admin';

const TriosScreen = ({navigation}) => {
  const {user, Config, screenWidth, theme} = useContext(AuthContext);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [trios, setTrios] = useState({});
  const [pagination, setPagination] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [prevScreenWidth, setPrevScreenWidth] = useState(screenWidth);
  const [from, setFrom] = useState(0);
  const [to, setTo] = useState(0);

  let commonStyles = commonStylesF(wp, hp, theme);
  let Trio = screenWidth < 700 ? TrioCard : TrioTable;
  let TriosHeader = screenWidth < 700 ? TriosHeaderCard : TriosHeaderTable;

  const getData = useCallback(async () => {
    setIsLoading(true);
    await axios(`${Config.API_URL}/api/v1/admin/trios?page=${currentPage}`, {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        Authorization: user.Authorization,
      },
    })
      .then(response => {
        if (response.status === 200) {
          let _from = 0;
          let _to = 0;
          setTrios(response.data.message.data);
          setPagination(response.data.message.pagination);
          _from =
            response.data.message.pagination.totalRows > 0
              ? (currentPage - 1) * response.data.message.pagination.perPage + 1
              : 0;
          _to =
            response.data.message.pagination.totalRows >
            currentPage * response.data.message.pagination.perPage
              ? currentPage * response.data.message.pagination.perPage
              : response.data.message.pagination.totalRows;
          setFrom(_from);
          setTo(_to);
        } else if (response.status === 'error') {
          console.log(response);
          setIsError(response.data.message);
        }
      })
      .catch(error => {
        if (error.response) {
          console.log('AdminTrios error.response', error.response);
          setIsError(error.response.data.message);
        } else if (error.request) {
          console.log('AdminTrios error.request', error.request);
          setIsError(JSON.stringify(error.request));
        } else {
          console.log('AdminTrios unknown error', error);
          setIsError(JSON.stringify(error));
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [user, currentPage, Config]);

  useEffect(() => {
    console.log('Admin Statistics useEffect');
    getData();
  }, [getData]);

  const loadNext = () => {
    let nextPage =
      pagination.totalPages > currentPage ? currentPage + 1 : currentPage;
    setCurrentPage(nextPage);
  };

  const loadPrev = () => {
    let prevPage = currentPage > 1 ? currentPage - 1 : currentPage;
    setCurrentPage(prevPage);
  };
  if (isLoading) {
    return <Loader />;
  } else {
    return (
      <View style={commonStyles.container}>
        <Header navigation={navigation} />
        <View style={commonStyles.contentContainer}>
          <View style={adminStyles.contentHead}>
            <Text style={adminStyles.contentHeadText}>Тройки</Text>
          </View>
          <FlatList
            ListHeaderComponent={<TriosHeader />}
            data={trios}
            renderItem={({item}) => <Trio item={item} />}
            keyExtractor={item => item.id}
            ListFooterComponent={
              <PaginationFooter
                currentPage={currentPage}
                totalPages={pagination.totalPages}
                loadPrev={loadPrev}
                loadNext={loadNext}
              />
            }
          />
        </View>
        {isError && <ErrorModal error={isError} setIsError={setIsError} />}
      </View>
    );
  }
};

export default TriosScreen;
