import {StyleSheet, Platform} from 'react-native';

const styles = {
  web: (theme) => StyleSheet.create({
    footerContainer: {
      flex: 1,
      flexDirection: 'row',
      width: 1080,
      height: 210,
      backgroundColor: theme.BLACK,
    },
    footerLogoContainer: {
      flex: 1,
      width: 133,
      height: 90,
      top: 35,
      left: 30,
      // paddingTop: 15,
      // paddingRight: 9,
      paddingBottom: 16,
      // paddingLeft: 10,
    },
    logoText: {
      fontFamily: theme.FONT_FAMILY_BOLD,
      fontSize: 40,
      color: theme.FONT_COLOR_WHITE,
    },
    footerLinksContainer: {
      flex: 3,
      flexDirection: 'row',
      paddingTop: 40,
      height: 210,
    },
    footerLinksBlock: {
      width: 300,
      wordWrap: 'break-word',
      paddingLeft: 30,
      gap: 10,
    },
    headerLogo: {
      width: 114,
      height: 19,
    },
    statLabel: {
      color: theme.FONT_COLOR_WHITE,
      fontFamily: theme.FONT_FAMILY,
      fontSize: 16,
      lineHeight: 19,
    },
    empty: {
      width: 0,
      height: 0,
    },
  }),
  android: (theme) => StyleSheet.create({

    empty: {
      width: 0,
      height: 0,
    },
  }),
  ios: (theme) => StyleSheet.create({
    footerContainer: {
      height: 10,
    },
    empty: {
      width: 0,
      height: 0,
    },
  }),
};

export default styles[Platform.OS];
