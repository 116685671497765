import React, {useState, useContext, useEffect, useCallback} from 'react';
import {Text, View, Pressable, Image, Platform} from 'react-native';
import {FlatList, HStack, ScrollView} from 'native-base';
import {
  Card,
  Avatar,
  Badge,
  SegmentedButtons,
  DataTable,
} from 'react-native-paper';
import {
  widthPercentageToDP as wp,
  heightPercentageToDP as hp,
} from 'react-native-responsive-screen';
import AxiosInstance from '../../components/common/axiosInstance';

import ArrowUp from '../../assets/img/arrow-down.svg';
import ArrowDown from '../../assets/img/arrow-up.svg';
import Header from '../../components/common/Header';
import Footer from '../../components/common/Footer';
import Loader from '../../components/common/Loader';
import {AuthContext} from '../../components/AuthProvider';
import PaginationFooter from '../../components/common/paginationFooter';
import ErrorModal from '../../components/common/ErrorModal';

import {default as commonStylesF} from '../../styles/common';
import listStyles from '../../styles/list';
import {default as transactionsStylesF} from '../../styles/transactions';
import {t} from 'i18next';
import IOSCustomPagination from '../../components/common/IOSCustomPagination';

const TransactionsHeaderTable = props => {
  const {t} = useContext(AuthContext);
  const dateSort = direction => {
    direction === 'desc'
      ? props.setSortDirection('asc')
      : props.setSortDirection('desc');
  };
  return (
    <DataTable.Header style={{...listStyles.itemsHeader}}>
      <DataTable.Title style={listStyles.itemCell}>
        <Text style={listStyles.itemsHeaderText}>ID</Text>
      </DataTable.Title>
      <DataTable.Title style={listStyles.itemCell}>
        <Pressable
          onPress={() => {
            dateSort(props.sortDirection);
          }}>
          <HStack style={Platform.OS === 'ios' ? {alignItems: 'center'} : {}}>
            <Text style={{...listStyles.itemsHeaderText, marginLeft: 10}}>
              {t('date')}
            </Text>
          </HStack>
        </Pressable>
      </DataTable.Title>
      <DataTable.Title style={listStyles.itemCell}>
        <Text style={listStyles.itemsHeaderText}>{t('sum')}</Text>
      </DataTable.Title>
      <DataTable.Title style={listStyles.itemCell}>
        <Text style={listStyles.itemsHeaderText}>{t('rest')}</Text>
      </DataTable.Title>
    </DataTable.Header>
  );
};

const TransactionContentTable = ({item}) => {
  const {t} = useContext(AuthContext);
  let trType = '';
  let background = '';
  if (item.type === 'request') {
    trType = t('withdraw_request');
    background = '#ffa4a4';
  } else {
    if (item.amount > 0) {
      trType = t('accrual');
      background = '#a4ffae';
    } else {
      trType = t('withdraw');
      background = '#8480ff';
    }
  }
  return (
    <DataTable.Row
      key={item.id}
      style={{
        ...listStyles.itemRow,
        backgroundColor: background,
      }}>
      <DataTable.Cell style={listStyles.itemCell}>
        <Text
          style={{
            ...listStyles.itemCol,
            fontSize: Platform.OS === 'ios' ? 14 : listStyles.itemCol.fontSize,
          }}>
          {trType} {item.id}
        </Text>
      </DataTable.Cell>
      <DataTable.Cell style={listStyles.itemCell}>
        <Text
          style={{
            ...listStyles.itemCol,
            fontSize: Platform.OS === 'ios' ? 12 : listStyles.itemCol.fontSize,
          }}>
          {item.created_at.substr(0, 16)}
        </Text>
      </DataTable.Cell>
      <DataTable.Cell style={listStyles.itemCell}>
        <Text
          style={{
            ...listStyles.itemCol,
            fontSize: Platform.OS === 'ios' ? 14 : listStyles.itemCol.fontSize,
          }}>
          {item.amount}
        </Text>
      </DataTable.Cell>
      <DataTable.Cell style={listStyles.itemCell}>
        <Text
          style={{
            ...listStyles.itemCol,
            fontSize: Platform.OS === 'ios' ? 12 : listStyles.itemCol.fontSize,
          }}>
          {item.curr_balance}
        </Text>
      </DataTable.Cell>
    </DataTable.Row>
  );
};

const TransactionsHeaderCard = props => {
  const {t} = useContext(AuthContext);
  const dateSort = direction => {
    direction === 'desc'
      ? props.setSortDirection('asc')
      : props.setSortDirection('desc');
  };
  return (
    <DataTable.Header style={{...listStyles.itemsHeader}}>
      <DataTable.Title style={listStyles.itemCell}>
        <Pressable
          onPress={() => {
            dateSort(props.sortDirection);
          }}>
          <HStack style={Platform.OS === 'ios' ? {alignItems: 'center'} : {}}>
            <Text style={{...listStyles.itemsHeaderText, marginLeft: 10}}>
              {t('date')}
            </Text>
          </HStack>
        </Pressable>
      </DataTable.Title>
    </DataTable.Header>
  );
};

const TransactionContentCard = ({item}) => {
  const {t} = useContext(AuthContext);
  let trType = '';
  let background = '';
  if (item.type === 'request') {
    trType = t('withdraw_request');
    background = '#ffa4a4';
  } else {
    if (item.amount > 0) {
      trType = t('accrual');
      background = '#a4ffae';
    } else {
      trType = t('withdraw');
      background = '#8480ff';
    }
  }

  return (
    <DataTable.Row
      key={item.id}
      style={{...listStyles.itemCardRow, padding: 0, borderBottomWidth: 0}}>
      <Card
        mode={'outlined'}
        style={{
          width: wp('80%'),
          margin: 2,
          maxWidth: 980,
          alignSelf: 'center',
        }}>
        <Card.Title
          title={`ID: ${item.id} ${item.created_at.substr(0, 16)}`}
          subtitle={
            <Badge
              size={25}
              style={{backgroundColor: background, color: '#222'}}>
              {trType}
            </Badge>
          }
        />
        <Card.Content>
          <Text>
            {t('sum')}: {item.amount} {t('UAH')}
          </Text>
          {item.type !== 'request' ? (
            <Text>
              {t('rest')}: {item.curr_balance} {t('UAH')}
            </Text>
          ) : (
            <Text></Text>
          )}
        </Card.Content>
      </Card>
    </DataTable.Row>
  );
};

const Transactions = ({navigation, route}) => {
  const {user, setErrors, logout, screenWidth} = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(null);
  const [transactions, setTransactions] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [pagination, setPagination] = useState({});
  const [sortField, setSortField] = useState('created_at');
  const [sortDirection, setSortDirection] = useState('desc');
  const [prevScreenWidth, setPrevScreenWidth] = useState(screenWidth);
  const [from, setFrom] = useState(0);
  const [to, setTo] = useState(0);

  let commonStyles = commonStylesF(wp, hp);
  let transactionsStyles = transactionsStylesF(wp, hp);

  let TransactionsHeader =
    screenWidth < 700 ? TransactionsHeaderCard : TransactionsHeaderTable;
  let TransactionContent =
    screenWidth < 700 ? TransactionContentCard : TransactionContentTable;

  const getTransactions = useCallback(async () => {
    setIsLoading(true);
    const api = AxiosInstance({user, logout});
    const url = `/api/v1/transactions?page=${currentPage}&order_field=${sortField}&order=${sortDirection}`;
    await api(url, {
      method: 'GET',
      mode: 'cors',
    })
      .then(response => {
        let _from = 0;
        let _to = 0;
        setTransactions(response.data.message.data);
        setPagination(response.data.message.pagination);
        _from =
          response.data.message.pagination.totalRows > 0
            ? (currentPage - 1) * response.data.message.pagination.perPage + 1
            : 0;
        _to =
          response.data.message.pagination.totalRows >
          currentPage * response.data.message.pagination.perPage
            ? currentPage * response.data.message.pagination.perPage
            : response.data.message.pagination.totalRows;
        setFrom(_from);
        setTo(_to);
      })
      .catch(error => {
        setIsError(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [currentPage, user, logout, sortField, sortDirection]);

  useEffect(() => {
    console.log('Transactions useEffect');
    if (navigation.isFocused()) {
      getTransactions();
    }
    return () => {
      console.log('Transactions useEffect unmount');
    };
  }, [getTransactions, navigation]);

  useEffect(() => {
    setPrevScreenWidth(screenWidth);
  }, [screenWidth]);

  if (isLoading) {
    return <Loader />;
  } else {
    return (
      <ScrollView contentContainerStyle={commonStyles.container}>
        <Header navigation={navigation} route={route} />
        <View style={commonStyles.contentContainer}>
          <View style={transactionsStyles.contentHead}>
            <Text style={commonStyles.contentHeadText}>
              {t('transactions')}
            </Text>
          </View>
          {Platform.OS === 'ios' ? (
            // Кастомна пагінація і рендеринг даних для iOS
            <View style={listStyles.dataTable}>
              <TransactionsHeader
                sortField={sortField}
                sortDirection={sortDirection}
                setSortField={setSortField}
                setSortDirection={setSortDirection}
              />
              {transactions.map(item => (
                <TransactionContent item={item} key={item.id} />
              ))}
              <IOSCustomPagination
                currentPage={currentPage}
                totalPages={Math.ceil(pagination.totalPages)}
                onPageChange={newPage => setCurrentPage(newPage)}
                label={`${from}-${to} of ${pagination.totalRows}`}
              />
            </View>
          ) : (
            // Стандартна пагінація для інших платформ
            <DataTable style={{...listStyles.dataTable, alignItems: 'center'}}>
              <TransactionsHeader
                sortField={sortField}
                sortDirection={sortDirection}
                setSortField={setSortField}
                setSortDirection={setSortDirection}
              />
              {transactions.map(item => (
                <TransactionContent item={item} />
              ))}
              <DataTable.Pagination
                page={currentPage - 1}
                numberOfPages={Math.ceil(pagination.totalPages)}
                onPageChange={page => setCurrentPage(page + 1)}
                label={`${from}-${to} of ${pagination.totalRows}`}
                numberOfItemsPerPage={pagination.perPage}
                showFastPaginationControls
              />
            </DataTable>
          )}
          <HStack
            style={{
              ...listStyles.itemsFooterContainer,
              alignItems: 'center',
              justifyContent: 'center',
            }}>
            <HStack style={transactionsStyles.btnGroup}>
              <Pressable
                style={transactionsStyles.btnProfile}
                onPress={() => navigation.navigate('Profile')}>
                <Text style={transactionsStyles.btnText}>
                  {t('back_to_profile')}
                </Text>
              </Pressable>
            </HStack>
          </HStack>
        </View>
        <Footer navigation={navigation}/>
        {isError && <ErrorModal error={isError} setIsError={setIsError} />}
      </ScrollView>
    );
  }
};

export default Transactions;
