import React, {useContext} from 'react';
import {Image, Pressable, Platform} from 'react-native';
import {Text, Center, HStack, View} from 'native-base';
import {Picker} from '@react-native-picker/picker';
import Icon from 'react-native-vector-icons/FontAwesome';

import {AuthContext} from '../../components/AuthProvider';

import headerStylesF from '../../styles/header';

const HeaderWeb = ({navigation, route}) => {
  const {logout, theme, t, i18n,} = useContext(AuthContext);
  const headerStyles = headerStylesF(theme);
  const currentLanguage = i18n.language;

  const changeLanguage = language => {
    i18n.changeLanguage(language);
  };

  return (
    <>
      <Center>
        <HStack style={headerStyles.headerContainer}>
          <View style={headerStyles.headerLogoContainer}>
            <Image
              style={headerStyles.headerLogo}
              source={require('../../assets/img/logo.png')}
            />
          </View>
          <HStack style={{...headerStyles.headerLinksContainer, paddingLeft: 0}}>
            <Pressable
              onPress={() => {
                navigation
                  ? navigation.navigate('ActiveUsers')
                  : alert('Выполните вход');
              }}>
              <Text style={headerStyles.statLabel}>{t('admin_users')}</Text>
            </Pressable>

            <Pressable
              onPress={() => {
                navigation
                  ? navigation.navigate('AdminStatistics')
                  : alert('Выполните вход');
              }}>
              <Text style={headerStyles.statLabel}>{t('admin_statistics')}</Text>
            </Pressable>

            <Pressable
              onPress={() => {
                navigation
                  ? navigation.navigate('AdminWithdraws')
                  : alert('Выполните вход');
              }}>
              <Text style={headerStyles.statLabel}>{t('admin_withdraw_requests')}</Text>
            </Pressable>

            <Pressable
              onPress={() => {
                navigation
                  ? navigation.navigate('AdminMessages')
                  : alert('Выполните вход');
              }}>
              <Text style={headerStyles.statLabel}>{t('admin_messages')}</Text>
            </Pressable>
          </HStack>
          <HStack style={headerStyles.langButtons}>
            <Picker
              selectedValue={currentLanguage}
              style={{
                height: 35,
                width: 60,
                borderWidth: 1,
                borderColor: '#000000',
                backgroundColor: '#FFF',
              }}
              onValueChange={(itemValue, itemIndex) =>
                changeLanguage(itemValue)
              }>
              <Picker.Item label="UA" value="ua" />
              <Picker.Item label="RU" value="ru" />
              <Picker.Item label="EN" value="en" />
            </Picker>
          </HStack>
          <HStack style={headerStyles.nagationBox}>
            <Pressable onPress={() => logout()}>
              <Icon name="sign-out" size={35} color="black" />
            </Pressable>
          </HStack>
        </HStack>
      </Center>
    </>
  );
};

const HeaderMobile = ({navigation, route}) => {
  // console.log('HEADER NAVIGATION', navigation);
  const {logout, theme, t} = useContext(AuthContext);
  const headerStyles = headerStylesF(theme);
  return (
    <>
      <Center>
        <HStack style={headerStyles.headerContainer}>
          <View style={headerStyles.headerLogoContainer}>
            <Image
              style={headerStyles.headerLogo}
              source={require('../../assets/img/logo.png')}
            />
          </View>
          <HStack style={headerStyles.nagationBox}>
            <Pressable onPress={() => logout()}>
              <Icon name="sign-out" size={25} color="black" />
            </Pressable>
          </HStack>
        </HStack>
      </Center>
    </>
  );
};

const Header = Platform.OS === 'web' ? HeaderWeb : HeaderMobile;
export default Header;
