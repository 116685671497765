let GoogleSignin;
let GoogleOAuth;
let SignIn;
let statusCodes;
let useGoogleLogin;

if (
  process.env.REACT_APP_PLATFORM &&
  process.env.REACT_APP_PLATFORM === 'web'
) {
  GoogleSignin = null;
  SignIn = null;
  statusCodes = null;
  GoogleOAuth = require('@react-oauth/google');
  useGoogleLogin = GoogleOAuth.useGoogleLogin;
} else {
  SignIn = require('@react-native-google-signin/google-signin');
  GoogleSignin = SignIn.GoogleSignin;
  statusCodes = SignIn.statusCodes;
  useGoogleLogin = param => {
    return param;
  };
}

export {GoogleSignin, statusCodes, useGoogleLogin};
