import React, {useContext, useEffect, useState, useCallback, memo} from 'react';
import {
  FlatList,
  Image,
  ActivityIndicator,
  Pressable,
  SafeAreaView,
  StyleSheet,
  Text,
  View,
  Platform,
  TouchableOpacity,
  Alert,
} from 'react-native';
import {SegmentedButtons} from 'react-native-paper';
import {
  widthPercentageToDP as wp,
  heightPercentageToDP as hp,
} from 'react-native-responsive-screen';
import AsyncStorage from '@react-native-async-storage/async-storage';

import {CheckBox, SearchBar} from 'react-native-elements';
import Icon from 'react-native-vector-icons/FontAwesome';
import Ionicons from 'react-native-vector-icons/Ionicons';

import {GoogleSignin, statusCodes} from '../../components/common/GoogleSignin';
import axios from 'axios';
import {AuthContext} from '../../components/AuthProvider';
import AxiosInstance from '../../components/common/axiosInstance';
import {
  getDataFromStorage,
  saveContactsToDB,
  saveDataToStorage,
  sendInviteToContacts,
} from './ContactsCommon';

import FormModal from '../../components/common/FormModal';
import Header from '../../components/common/Header';
import ErrorModal from '../../components/common/ErrorModal';
import InfoModal from '../../components/common/InfoModal';
import Config from '../../config/config';
import theme from '../../config/theme_config';

const batchSize = 10;
let cachedContacts = [];

const saveContactsToStorage = async (data, account_id) => {
  try {
    data.push({lastUpdate: new Date()});
    console.log('saveContactsToStorage for ',account_id, data.length);
    await AsyncStorage.setItem(
      `otherContacts_${account_id}`,
      JSON.stringify(data)
    );
  } catch (error) {
    console.log('saveContactsToStorage error: ', error);
  }
};

const saveSelectedContactsToStorage = async (data, account_id) => {
  try {
    // console.log('saveSelectedContactsToStorage function');
    // console.debug(data);
    await AsyncStorage.setItem(
      `otherContactsSelected_${account_id}`,
      JSON.stringify(data)
    );
    // console.log('saveSelectedContactsToStorage: ', JSON.stringify(data));
  } catch (error) {
    console.log('saveSelectedContactsToStorage error: ', error);
  }
};

const getSelectedContactsFromStorage = async account_id => {
  try {
    let data = await AsyncStorage.getItem(
      `otherContactsSelected_${account_id}`
    );
    // console.log('getSelectedContactsFromStorage JSON string ', data);
    let savedSelectedContacts = data !== null ? JSON.parse(data) : {};
    // console.log('getSelectedContactsFromStorage', savedSelectedContacts.length);
    return savedSelectedContacts;
  } catch (error) {
    console.log('getSelectedContactsFromStorage error: ', error);
    return {};
  }
};

const getContactsFromStorage = async account_id => {
  try {
    let data = await AsyncStorage.getItem(`otherContacts_${account_id}`);
    // console.log('getContactsFromStorage for ', account_id, data);
    let savedContacts = data !== null ? JSON.parse(data) : {};
    // console.log('getContactsFromStorage', savedContacts);
    return savedContacts;
  } catch (error) {
    console.log('getContactsFromStorage error: ', error);
    return {};
  }
};

const checkSelectedStatus = (_contacts, _selectedItems, defaultSelected) => {
  let checkedItems = 0;
  let uncheckedItems = 0;
  let itemsCnt = 0;

  _contacts.map(item => {
    let key =
      item.resourceName !== undefined
        ? item.resourceName.replace('otherContacts/', '')
        : false;
    if (key && _selectedItems[key] !== undefined) {
      if (_selectedItems[key].checked) {
        checkedItems++;
      } else {
        uncheckedItems++;
      }
      itemsCnt++;
    } else if (key && _selectedItems[key] === undefined) {
      if (defaultSelected) {
        checkedItems++;
      } else {
        uncheckedItems++;
      }
      itemsCnt++;
    }
  });

  if (checkedItems === itemsCnt) {
    console.log(`allSelected: checked ${checkedItems} all ${itemsCnt}`);
    return true;
  } else if (uncheckedItems === itemsCnt) {
    console.log(`allUnchecked unchecked ${uncheckedItems} all ${itemsCnt}`);
    return false;
  } else {
    console.log(
      `partialSelected checked ${checkedItems} unchecked ${uncheckedItems} all ${itemsCnt}`
    );
    return defaultSelected === true || defaultSelected === 1 ? 1 : 2;
  }
};

const MemContactItem = memo(
  ({
    contact,
    allContacts,
    selectedItems,
    setSelectedItems,
    setNeedUpdate,
    isChecked,
    isSended,
    allSelected,
    setAllSelected,
    // setCheckedContacts,
  }) => {
    // console.log('RENDER CONTACT ', contact.emailAddresses[0].value, isSended);
    const {t} = useContext(AuthContext);
    const formInitials = name => {
      if (!name) {
        return '';
      }
      let tmp = name.split(' ');
      if (tmp.length >= 2) {
        return tmp[0].substring(0, 1) + tmp[1].substring(0, 1);
      } else {
        return tmp[0].substring(0, 1);
      }
    };

    const handleCheckboxChange = () => {
      let currentSelected;

      setSelectedItems(prevState => {
        let newState = prevState;
        let itemId = contact.resourceName.replace('otherContacts/', '');
        newState[itemId] = contact;
        newState[itemId].checked = !isChecked;
        // console.log('SELECTED ITEMS AFTER handleCheckboxChange', newState);
        return newState;
      });

      currentSelected = checkSelectedStatus(
        allContacts,
        selectedItems,
        allSelected
      );

      setAllSelected(currentSelected);
      setNeedUpdate(prevState => !prevState);
    };

    const IOSCheckbox = ({checked, onPress}) => (
      <TouchableOpacity
        onPress={onPress}
        style={{
          width: 24,
          height: 24,
          borderWidth: 1,
          borderColor: checked ? '#007AFF' : 'gray', // Змінено на синій колір, коли вибрано
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: 'white', // Фон білий
          borderRadius: 4, // Закруглені кути
          ...Platform.select({
            ios: {
              marginHorizontal: 10,
            },
          }),
        }}>
        {checked ? (
          <Text style={{fontSize: 18, fontWeight: 'bold', color: '#007AFF'}}>
            ✓
          </Text> // Змінено колір на синій
        ) : null}
      </TouchableOpacity>
    );
    if (!contact || !contact.resourceName) {
      // Якщо contact або resourceName відсутні
      console.log('contact або resourceName відсутні');
      return null;
    }

    // при отображении чекбокса использовать значение из selectedItems
    return (
      <View
        style={{
          ...styles.contactCon,
          ...Platform.select({
            ios: {
              justifyContent: 'center',
              alignItems: 'center',
            },
          }),
        }}>
        {Platform.OS === 'ios' ? (
          <IOSCheckbox
            checked={isChecked === true || isChecked === 1 ? true : false}
            onPress={() => {
              handleCheckboxChange();
            }}
          />
        ) : (
          <CheckBox
            checked={isChecked}
            onPress={() => {
              handleCheckboxChange();
            }}
          />
        )}

        <View>
          {contact.photos[0].url ? (
            <Image
              source={{uri: contact.photos[0].url}}
              style={styles.avatar}
            />
          ) : (
            <View style={styles.placeholder}>
              <Text style={styles.txt}>
                {contact.names
                  ? formInitials(contact.names[0].displayName)
                  : '?'}
              </Text>
            </View>
          )}
        </View>

        <View style={styles.contactDat}>
          {Platform.OS === 'ios' ? (
            <Text style={styles.name}>
              {isSended === null &&
                (isSended ? (
                  <Ionicons
                    name="checkmark"
                    size={15}
                    fontWeight="bold"
                    color="green"
                  />
                ) : (
                  <Ionicons
                    name="checkmark"
                    size={15}
                    fontWeight="bold"
                    color="yellow"
                  />
                ))}
            </Text>
          ) : (
            <Text style={styles.name}>
              {Platform.OS === 'ios'
              ? contact.names[0].givenName || t('no_name')
              : contact.names !== undefined
              ? contact.names[0].displayName
              : ''}
              {isSended !== null &&
                (isSended ? (
                  <Icon name="check" size={15} color="green" />
                ) : (
                  <Icon name="check" size={15} color="yellow" />
                ))}
            </Text>
          )}
          <Text style={styles.phoneNumber}>
            {contact.emailAddresses[0]?.value}
          </Text>
        </View>
      </View>
    );
  }
);

const OtherContactList = ({
  navigation,
  route,
}) => {
  const {user, logout, t, theme, addHistory} = useContext(AuthContext);
  const [isSignedIn, setIsSignedIn] = useState(false);
  const [contacts, setContacts] = useState([]);
  const [tokens, setTokens] = useState({});
  const [needUpdate, setNeedUpdate] = useState(false);
  const [search, setSearch] = useState('');
  const [visibleData, setVisibleData] = useState([]);
  const [allSelected, setAllSelected] = useState(true);
  const [prevAllSelected, setPrevAllSelected] = useState(false);
  const [filteredSelected, setFilteredSelected] = useState(false);
  const [selectedItems, setSelectedItems] = useState({});
  const [alreadySended, setAlreadySended] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(null);
  const [info, setInfo] = useState(null);
  const [userInfo, setUserInfo] = useState({});
  const [needUpdateFromGoogle, setNeedUpdateFromGoogle] = useState(false);
  const [signInWorking, setSignInWorking] = useState(false);
  const [checkedContacts, setCheckedContacts] = useState(0);
  const [modalVisible, setModalVisible] = useState(false);
  const [contactType, setContactType] = useState('other');
  const [googleAccountId, setGoogleAccountId] = useState(null);
  const [forceSaveSelected, setForceSaveSelected] = useState(false);

  // let commonStyles = commonStylesF(wp, hp);

  const toggleAll = async () => {
    let selected = search ? filteredSelected : allSelected;
    if (typeof selected === 'boolean') {
      setPrevAllSelected(selected);
    }

    contacts
      .filter(_item => _item.resourceName !== undefined)
      .map(item => {
        let key = item.resourceName.replace('otherContacts/', '');
        setSelectedItems(prevState => {
          const newState = prevState;
          newState[key] = item;
          newState[key].checked =
            typeof selected === 'boolean' ? !selected : false;
          return newState;
        });
      });
    await AsyncStorage.setItem('otherAllSelected', JSON.stringify(!selected));

    if (search) {
      setFilteredSelected(prevState => {
        if (typeof prevState === 'boolean') {
          return !selected;
        } else {
          return false;
        }
      });
    } else {
      setAllSelected(prevState => {
        if (typeof prevState === 'boolean') {
          return !selected;
        } else {
          return false;
        }
      });
    }
  };

  const filter = text => {
    let filteredContacts;
    if (text.length >= 3) {
      text = text.toLowerCase();
      filteredContacts = cachedContacts.filter(
        element =>
          element.emailAddresses !== undefined &&
          element.emailAddresses[0].value.includes(text)
      );
      setContacts(filteredContacts);
      setVisibleData(
        filteredContacts.slice(
          0,
          batchSize <= filteredContacts.length
            ? batchSize
            : filteredContacts.length
        )
      );
    } else {
      setContacts(cachedContacts);
      setVisibleData(
        cachedContacts.slice(
          0,
          batchSize <= cachedContacts.length ? batchSize : cachedContacts.length
        )
      );
    }
    setSearch(text);
  };

  const loadAlreadySendedContacts = useCallback(async () => {
    console.log('start loadAlreadySendedContacts');
    const api = AxiosInstance({user, logout});
    await api('/api/v1/invitations/all', {
      method: 'GET',
      mode: 'cors',
    })
      .then(response => {
        if (response.data.code === 200) {
          // console.log('ALREADY SENDED', response.data.message);
          let invitations = response.data.message;
          let _alreadySended = {};
          invitations.map(item => {
            if (item.email) {
              _alreadySended[item.email] =
                parseInt(item.status) === 1 ? true : false;
            }
          });
          setAlreadySended(_alreadySended);
        } else {
          setIsError(t('server_error'));
          console.log(
            'User invitations list error:',
            response.data.message,
            response.data.code
          );
        }
      })
      .catch(error => {
        console.log('Catch user invitations list error:', error);
        setIsError(t('server_error'));
      });
  }, [user, logout, t]);

  const _sendToSelectedContacts = async defaultSelected => {
    setIsLoading(true);
    console.log('sendToSelectedContacts');
    console.log(
      `contacts ${contacts.length} selected: ${
        Object.keys(selectedItems).length
      }`
    );
    let key;
    let name;
    let email;
    let contactsToSend = [];
    contacts.map(item => {
      key =
        'resourceName' in item
          ? item.resourceName.replace('otherContacts/', '')
          : false;
      if (key) {
        email = item.emailAddresses[0].value;
        name = item.names !== undefined ? item.names[0].displayName : email;
        if (
          selectedItems[key] !== undefined &&
          selectedItems[key].checked === true
        ) {
          contactsToSend.push({displayName: name, email: email});
          setAlreadySended(prevState => {
            prevState[key] = false;
            return prevState;
          });
        } else if (
          selectedItems[key] === undefined &&
          (defaultSelected === true || defaultSelected === 1)
        ) {
          contactsToSend.push({displayName: name, email: email});
          setAlreadySended(prevState => {
            prevState[key] = false;
            return prevState;
          });
        }
      }
    });
    console.log('contactsToSend', contactsToSend);

    if (!contactsToSend.length) {
      setIsError(t('empty_choice'));
      setIsLoading(false);
      return false;
    }
    if (contactsToSend.length > Config.MAX_EMAILS) {
      setIsError(
        t('error_max_send') + `${Config.MAX_EMAILS} ` + t('of_invitations')
      );
      setIsLoading(false);
      return false;
    }
    if (Config.ENV_NAME === 'dev') {
      contactsToSend = contactsToSend.slice(0, Config.MAX_EMAILS);
    }
    Alert.alert(
      t('invitation_sending'), // Заголовок
      t('send') + ` ${contactsToSend.length} ` + t('of_invitations') + '?', // Сообщение
      [
        {
          text: t('cancel'),
          onPress: () => {
            console.log(t('action_canceled'));
            setIsLoading(false);
          },
          style: 'cancel',
        },
        {
          text: 'OK',
          onPress: async () => {
            console.log(t('action_confirmed'));

            await sendInviteToContacts(
              contactsToSend,
              setIsError,
              setInfo,
              setIsLoading,
              user,
              logout,
              t
            );
            let _sended = alreadySended;
            contactsToSend.map(item => {
              _sended[item.email] = false;
            });
            setAlreadySended(_sended);
          },
        },
      ],
      {cancelable: false}
    );

    return contactsToSend;
  };

  const checkSendRules = (
    _contacts,
    _selectedItems,
    defaultSelected
  ) => {
    let contactsToSend = [];
    let name;
    let email;
    // setIsLoading(true);
    _contacts.map(item => {
      let       key =
      'resourceName' in item
        ? item.resourceName.replace('otherContacts/', '')
        : false;

      if (key) {
        email = item.emailAddresses[0].value;
        name = item.names !== undefined ? item.names[0].displayName : email;
        if (_selectedItems[key] !== undefined) {
          if (_selectedItems[key].checked) {
            contactsToSend.push({displayName: name, email: email});
          }
        } else if (_selectedItems[key] === undefined) {
          if (defaultSelected) {
            contactsToSend.push({displayName: name, email: email});
          }
        }
      }
    });
    // console.log(contactsToSend);
    if (!contactsToSend.length) {
      setIsError(t('error_empty_choice'));
      setIsLoading(false);
      return false;
    }
    console.log('contacts to send: ', contactsToSend);

    let maxMessages =
      contactType === 'phone' ? Config.MAX_SMS : Config.MAX_EMAILS;
    if (contactsToSend.length > maxMessages) {
      setIsError(
        t('error_max_send') + ` ${maxMessages} ` + t('of_invitations')
      );
      setIsLoading(false);
      return false;
    }

    if (Config.ENV_NAME === 'dev') {
      contactsToSend = contactsToSend.slice(0, maxMessages);
    }

    return contactsToSend;
  }

  const handleShowModal = () => {
    setModalVisible(true);
  };

  const handleCloseModal = () => {
    setModalVisible(false);
  };

  const handleFormSubmit = (formData) => {
    // Обработка данных формы
    console.log('Form submitted', formData);
    setIsLoading(true);

    let _contactsToSend = checkSendRules(
      contacts,
      selectedItems,
      all_selected_check
    );

    if (_contactsToSend !== false){
      sendToSelectedContacts(_contactsToSend, formData);
    } else {
      setIsError(t('unknown_error'));
    }

    // setIsLoading(false);
  };

  const sendToSelectedContacts = async (contactsToSend, msgFormData) => {
    if (msgFormData.message === undefined || !msgFormData.length) {
      await sendInviteToContacts(
        contactsToSend,
        setIsError,
        setInfo,
        setIsLoading,
        user,
        logout,
        t,
        msgFormData
      );

      let _sended = alreadySended;
      contactsToSend.map(item => {
        _sended[item.email] = false;
      });
      setAlreadySended(_sended);
    } else {
      setIsError(t('empty_message'));
    }
  };

  const loadMoreData = () => {
    // Вычислите следующую порцию данных на основе текущего состояния
    setIsLoading(true);
    const startIndex = visibleData.length;
    const lastContact = contacts[contacts.length - 1];
    const contactsLength =
      lastContact !== undefined && lastContact.resourceName !== undefined
        ? contacts.length
        : contacts.length - 1;
    const endIndex =
      startIndex + batchSize <= contactsLength
        ? startIndex + batchSize
        : contactsLength;
    // console.log(`loadMore contacts length ${contacts.length}`);
    console.log(`loadMoreData from ${startIndex} to ${endIndex}`);
    const nextBatch = contacts.slice(startIndex, endIndex);
    setVisibleData(prevData => [...prevData, ...nextBatch]);
    setIsLoading(false);
  };

  const IOSHeaderCheckbox = ({checked, onPress, title}) => (
    <TouchableOpacity
      onPress={onPress}
      style={{flexDirection: 'row', alignItems: 'center'}}>
      <View
        style={{
          width: 24,
          height: 24,
          borderWidth: 1,
          borderColor: checked ? '#007AFF' : 'gray',
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: 'white',
          borderRadius: 4,
          marginRight: 5,
        }}>
        {checked ? (
          <Text style={{fontSize: 18, fontWeight: 'bold', color: '#007AFF'}}>
            ✓
          </Text>
        ) : null}
      </View>
      <Text>{title}</Text>
    </TouchableOpacity>
  );

  const googleGetNewAccessToken = useCallback(async (refresh_token) => {
    await axios
      .post(
        `${Config.API_URL}/oauth2.php`,
        {
          refresh_token: refresh_token,
        },
        {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
        }
      )
      .then(response => {
        console.log('RESPONSE FROM BACKEND, REFRESHING TOKEN DATA', response.data);
        if (response.data.token !== undefined) {
          response.data.token.accessToken = response.data.token.access_token;
          response.data.token.expires_at =
            new Date().getTime() + response.data.token.expires_in * 1000;
          setTokens(prevState => {
            return {...prevState, ...response.data.token}
          });
          saveDataToStorage(response.data.token, 'tokens');
          setIsSignedIn(true);
        } else {
          signIn();
        }
      })
      .catch(error => {
        console.log('ERROR RESPONSE FROM BACKEND, REFRESHING TOKEN', error);
        signIn();
      });
  }, [signIn]);

  const signIn = useCallback(async () => {
    console.log('signIn');
    setSignInWorking(true);
    try {
      await GoogleSignin.configure({
        scopes: [
          'https://www.googleapis.com/auth/contacts.other.readonly',
        ],
        // webClientId:
        //   '281249292700-97h5dol9hn64uvut0tkr2gc3iahoitdl.apps.googleusercontent.com',
        webClientId: Config.GOOGLE_CLIENT_ID,
        iosClientId: Config.GOOGLE_IOS_CLIENT_ID,
        offlineAccess: true,
        forceCodeForRefreshToken: true,
      });
      await GoogleSignin.hasPlayServices();
      let _userInfo = await GoogleSignin.signIn();
      setUserInfo(_userInfo);
      await saveDataToStorage(_userInfo.user.id, 'google_account_id');
      setGoogleAccountId(_userInfo.user.id);

      // console.log('userInfo', _userInfo);
      // const _tokens = await GoogleSignin.getTokens();
      // console.log('tokens', _tokens);

      // Получение auth объекта с refresh_token
      const auth = await GoogleSignin.getCurrentUser();

      if (auth && auth.serverAuthCode) {
        const response = await fetch('https://oauth2.googleapis.com/token', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
          body: `code=${auth.serverAuthCode}&client_id=${Config.GOOGLE_CLIENT_ID}&client_secret=${Config.GOOGLE_API_CLIENTSECRET}&redirect_uri=&grant_type=authorization_code`,
        });

        const data = await response.json();
        console.log('RESPONSE FROM https://oauth2.googleapis.com/token', data);
        const { refresh_token } = data;
        data.expires_at = new Date().getTime() + data.expires_in * 1000;
        data.accessToken = data.access_token;
        setTokens(data);
        setIsSignedIn(true);
        saveDataToStorage(data, 'tokens');
        saveRefreshToken(refresh_token, _userInfo.user.id);
        // console.log('needUpdateFromGoogle', needUpdateFromGoogle);
        // setNeedUpdateFromGoogle(true);
      } else {
        setIsError(t('get_google_token_error'));
      }
      setSignInWorking(false);
    } catch (error) {
      if (error.code === statusCodes.SIGN_IN_CANCELLED) {
        // Пользователь отменил вход
        setIsError(t('error_user_canceled_login'));
      } else if (error.code === statusCodes.IN_PROGRESS) {
        // Вход уже выполняется
        setIsError(t('error_login_in_progress'));
      } else if (error.code === statusCodes.PLAY_SERVICES_NOT_AVAILABLE) {
        // Google Play Services недоступен
        setIsError(t('error_google_play'));
      } else if (error.code === statusCodes.SIGN_IN_REQUIRED) {
        await handleSignInRequired();
        console.log('handleSignInRequired');
      } else {
        console.error(error);
        setIsError(t('error_unknown') + JSON.stringify(error));
      }
      setSignInWorking(false);
    }
  }, [t, saveRefreshToken]);

  const reSignIn = useCallback(async () => {
    await getRefreshToken()
    .then(refresh_token => {
      console.log('REFRESH TOKEN', refresh_token);
      if (refresh_token) {
        // обновляем авторизацию по refresh_token
        googleGetNewAccessToken(refresh_token);
      } else {
        // запрашиваем новую авторизацию
        signIn();
      }
    }).catch(error => {
      console.log('reSignIn error');
      // запрашиваем новую авторизацию
      signIn();
    });
  }, [getRefreshToken, signIn, googleGetNewAccessToken]);

  const saveRefreshToken = useCallback(async (refresh_token, google_account_id) => {
    console.log('start saving refresh token', refresh_token);
    await axios.post(
      `${Config.API_URL}/api/v1/users/${user.id}/google_account`,
      {
        user_id: parseInt(user.id),
        google_account_id: google_account_id,
        refresh_token: refresh_token,
        last_used: 1,
      },
      {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          'Authorization': user.Authorization,
        },
      },

    ).then(response => {
      console.log('saveRefreshToken response', response);
    }).catch(error => {
      console.log('saveRefreshToken error', error.toJSON());
      for(let key in error) {
        console.log(key, error[key]);
      }
    });
  }, [user]);

  const getRefreshToken = useCallback(async () => {
    let refresh_token = false;
    console.log('getRefreshToken for ', googleAccountId);

    if (!googleAccountId) {
      return false;
    }

    await axios.get(
      `${Config.API_URL}/api/v1/users/${user.id}/google_account/${googleAccountId}`,
      {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          'Authorization': user.Authorization,
        },
      }
    ).then(response => {
      if (response.data !== undefined && response.data.message.refresh_token !== undefined) {
        refresh_token = response.data.message.refresh_token;
      } else {
        console.log('getRefreshToken response error');
      }
    }).catch(error => {
      // googleGetTokens();
      console.log('getRefreshToken error', error);
    });

    return refresh_token;
  }, [user.id, user.Authorization, googleAccountId]);

  async function handleSignInRequired() {
    try {
      await GoogleSignin.signOut(); // Очистите текущую сессию
    } catch (error) {
      console.error('Error during sign-in:', error);
      setIsSignedIn(false);
    }
  }

  const selectAccount = useCallback(async (revokeAccess=false) => {
    console.log('select Google Account');
    setForceSaveSelected(true);
    // setTokens(null);
    GoogleSignin.configure({
      scopes: [
        'https://www.googleapis.com/auth/contacts.other.readonly',
      ],
      // webClientId: '281249292700-97h5dol9hn64uvut0tkr2gc3iahoitdl.apps.googleusercontent.com',
      webClientId: Config.GOOGLE_CLIENT_ID,
      iosClientId: Config.GOOGLE_IOS_CLIENT_ID,
      offlineAccess: true,
      forceCodeForRefreshToken: true,
    });
    if (isSignedIn && revokeAccess){
        await GoogleSignin.revokeAccess();
    }
    await GoogleSignin.signOut();
    await signIn();
    setNeedUpdateFromGoogle(true);
  }, [isSignedIn, signIn]);

  const syncContacts = useCallback(async () => {
    setIsLoading(true);
    try {
      console.log('auth with token: ', tokens.accessToken);
      const response = await axios.get(
        `https://people.googleapis.com/v1/otherContacts?readMask=names,emailAddresses,photos`,
        {
          headers: {
            Authorization: `Bearer ${tokens.accessToken}`,
          },
        }
      );

      const otherContacts = response.data.otherContacts;
      cachedContacts = otherContacts;
      setContacts(otherContacts);
      setVisibleData(otherContacts.slice(0, batchSize));
      await saveContactsToStorage(otherContacts, googleAccountId);
      await saveDataToStorage(new Date().getTime(), `lastModified_${googleAccountId}`);
      setIsLoading(false);
    } catch (error) {
      // setIsError(t('error_saving_contacts'));
      setIsLoading(false);
      if (error.response) {
        const {data, status, headers} = error.response;
        console.error('error.response', data, status);
        if (status === 401) {
          setIsError(t('error_google_need_login'));
          setTimeout(() => {
            setIsError(false);
            selectAccount();
          }, 1000);
        } else if (status === 403) {
          setIsError(t('google_403'));
          selectAccount(true);
          // return true;
        } else {
          setIsError(t('server_error') + `${status}`);
        }
      } else if (error.request) {
        // Отсутствует тело ответа
        console.error(error.request);
        setIsError(t('server_error'));
      } else {
        // Ошибка, связанная с неправильной настройкой запроса
        setIsError(t('server_error'));
        console.error(error.message);
      }
    }
  }, [tokens, googleAccountId, t, selectAccount]);

  useEffect(() => {
    console.log('forceSaveSelected');
    if (forceSaveSelected) {
      saveSelectedContactsToStorage(selectedItems, googleAccountId);
      setForceSaveSelected(false);
    }
  }, [forceSaveSelected, selectedItems, googleAccountId]);

  useEffect(() => {
    // console.log('useEffect update from Storage');
      setIsLoading(true);

      const loadDataFromStorage = async () => {
        let _tokens = null;
        let _google_accoutn_id = null;
        let _last_modified = 0;
        let _contacts = [];

        await getDataFromStorage('tokens').then(result => {
          console.log('Get token from storage result', result);
          _tokens = result;
        }).catch(error => {
          console.log('Get token from storage error', error);
        });

        await getDataFromStorage('google_account_id').then(acc_id => {
          console.log('Get google_account_id from storage result', acc_id);
          _google_accoutn_id = acc_id;
        }).catch(error => {
          console.log('Get google_account_id from storage error', error);
        });

        if (_google_accoutn_id) {
          await getContactsFromStorage(_google_accoutn_id).then(data => {
            console.log('contacts from storage for ', _google_accoutn_id, data.length);
            _contacts = data;
          });
          await getDataFromStorage(`lastModified_${_google_accoutn_id}`).then(last_modified => {
            console.log('last_modified for', _google_accoutn_id, last_modified)
            _last_modified = last_modified;
          });

          await getSelectedContactsFromStorage(_google_accoutn_id).then(data => {
            console.log('selectedItems from storage', data);
            setSelectedItems(data);
          });
        }

        return {tokens: _tokens, google_account_id: _google_accoutn_id, lastModified: _last_modified, contacts: _contacts};
      };


      loadDataFromStorage().then(response => {
        // если есть сохраненный токен, записываем его в состояние
        if (response.tokens && response.google_account_id) {
          console.log('DATA IN STORAGE', response.tokens, response.google_account_id);
          setTokens(response.tokens);
          setGoogleAccountId(response.google_account_id);
          // смотрим контакты в локальном хранилище
          if (response.contacts && new Date().getTime() - response.lastModified < 7 * 24 * 3600 * 1000) {
            // есть сохраненные и они свеже 7-ми дней
            console.log('Есть сохраненные контакты');
            cachedContacts = response.contacts;
            // for(let key in response.contacts.slice(0, batchSize)){
            //   console.log(response.contacts[key].names);
            // }
            setContacts(response.contacts);
            setVisibleData(response.contacts.slice(0, batchSize));
          } else {
            // сохраненных нет, обновляем токен и тянем контакты из Google
            console.log('need reSignIn');
            reSignIn();
            setNeedUpdateFromGoogle(true);
          }
        } else {
          // нет сохраненных токенов, авторизируемся через Google и загружаем контакты
          console.log('need signIn');
          signIn();
          setNeedUpdateFromGoogle(true);
        }
      }).catch(error => {
        console.log('TOKENS IN STORAGE ERROR', error);
        console.log('need signIn 1');
        signIn();
        setNeedUpdateFromGoogle(true);
      }).finally(() => {
        setIsLoading(false);
      });

  }, [signIn, reSignIn]);

  // загрузка контактов по Google API
  useEffect(() => {
    if (needUpdateFromGoogle && tokens && tokens.accessToken && googleAccountId && !signInWorking) {
      console.log('useEffect  загрузка контактов по Google API');
      syncContacts();
      setNeedUpdateFromGoogle(false);
    }
  }, [syncContacts, tokens, googleAccountId, needUpdateFromGoogle, signInWorking]);

  useEffect(() => {
    loadAlreadySendedContacts(); // зацикливает, вызывает постоянный рендеринг, если добавить в зависимости
  }, [loadAlreadySendedContacts]);

  // определение статуса отмеченности галочки "все"
  useEffect(() => {

    let currentSelected;
    if (search.length >= 2) {
      currentSelected = checkSelectedStatus(
        contacts,
        selectedItems,
        filteredSelected
      );

      setFilteredSelected(currentSelected);
    } else {
      currentSelected = checkSelectedStatus(
        contacts,
        selectedItems,
        allSelected
      );

      setAllSelected(currentSelected);
    }

    return async () => {
      if (Object.keys(selectedItems).length) {
        await saveSelectedContactsToStorage(selectedItems, googleAccountId);
      }
    };
  }, [contacts, selectedItems, allSelected, filteredSelected, search, googleAccountId]);

  // сохранение типа контактов (телефон, другие) в локальное хранилище

  // сохранение контактов в локальное хранилище
  useEffect(() => {
    const saveContactType = async () => {
      await saveDataToStorage('other', 'contactType');
    };

    return () => {
      saveContactType();
    };
  }, []);

  useEffect(() => {
    if (Platform.OS !== 'web') {
      addHistory(route.name);
    }
  }, [addHistory, route]);

  // сохранение контактов в БД
  useEffect(() => {
    const checkDBContacts = async (_contacts, google_account_id) => {
      let needSave = false;
      let contactsToSave = [];
      // console.log('checkDBContacts TEST', _contacts.length, 'other');

      const api = AxiosInstance({user, logout});
      let response = await api.get(
        `${Config.API_URL}/api/v1/user_contacts/stat`,
        {
          method: 'GET',
          mode: 'cors',
        }
      );

      if (
        response.data.message[google_account_id] !== undefined &&
        response.data.message[google_account_id]['other'] !== undefined
      ) {
        let last_modified = new Date(
          response.data.message[google_account_id]['other'].last_modified
        );
        let now = new Date();
        let diff =
          (now.getTime() - last_modified.getTime()) / (1000 * 3600 * 24);
        if (
          diff > 7 ||
          _contacts.length >
            response.data.message[google_account_id]['other'].total_cnt
        ) {
          console.log(`saveContactsToDB for other`);
          // console.log(`diff: ${diff}`);
          // console.log(response.data.message, _contacts.length);
          needSave = true;
        }
      } else {
        if (_contacts.length) {
          // console.log(`saveContactsToDB for other, not founded in Stat`);
          needSave = true;
        }
      }

      if (needSave) {
        let id;
        let name;
        let email;
        _contacts
          .filter(c => c.resourceName !== undefined)
          .map(item => {
            id = item.resourceName.replace('otherContacts/', '');
            email = item.emailAddresses[0].value;
            name = item.names !== undefined ? item.names[0].displayName : email;
            contactsToSave.push({
              id: id,
              phone: null,
              email: email,
              name: name,
            });
          });
        saveContactsToDB(
          contactsToSave,
          googleAccountId,
          'other',
          user,
          logout
        );
      }
    };

    if (contacts.length && googleAccountId) {
      try {
        checkDBContacts(contacts, googleAccountId);
      } catch (error) {
        console.log('saving contacts to db error:', error);
      }
    }
  }, [contacts, user, logout, googleAccountId]);

  let all_selected_check = search ? filteredSelected : allSelected;

  // определение кол-ва отмеченных контактов
  useEffect(() => {
    if (all_selected_check === false) {
      setCheckedContacts(0);
    } else {
      let checked = 0;

      contacts.map(item => {
        let key =
          item.resourceName !== undefined
            ? item.resourceName.replace('otherContacts/', '')
            : false;

        if (key && selectedItems[key] !== undefined) {
          if (selectedItems[key].checked) {
            checked++;
          }
        } else if (key && selectedItems[key] === undefined) {
          if (all_selected_check) {
            checked++;
          }
        }
        // console.log('key selectedItems[key]', key, selectedItems[key]);
      });
      setCheckedContacts(checked);

    }
  }, [selectedItems, contacts, all_selected_check, needUpdate]);

  // const [storageContent, setStorageContent] = useState({});

  // useEffect(() => {
  //   const fetchStorageContent = async () => {
  //     try {
  //       const keys = await AsyncStorage.getAllKeys();
  //       const result = {};
  //       for (let key of keys) {
  //         console.log('STORAGE KEY: ', key);
  //         const value = await AsyncStorage.getItem(key);
  //         result[key] = value;
  //       }
  //       setStorageContent(result);
  //       // console.log('STORAGE:', result);
  //     } catch (error) {
  //       console.error('Error fetching AsyncStorage content:', error);
  //     }
  //   };

  //   fetchStorageContent();
  // }, []);

  return (
    <SafeAreaView style={styles.container}>
      <Header navigation={navigation} route={route} />
      <View
        style={[
          styles.contentHead,
          Platform.OS === 'ios' ? styles.iosContentHead : {},
        ]}>
        <SegmentedButtons
          style={{
            width: wp('90%'),
            maxWidth: 600,
          }}
          theme={{
            colors: {
              secondaryContainer: theme.BLACK,
              onSecondaryContainer: theme.FONT_COLOR_WHITE,
            },
          }}
          value={contactType}
          onValueChange={value => {
            if(value === 'phone') {
              navigation.navigate('ContactsList');
            };
          }}
          buttons={[
            {
              value: 'phone',
              label: t('phone_book'),
            },
            {
              value: 'other',
              label: t('other_contacts'),
            },
          ]}
        />
      </View>
      <View style={styles.headerButtons}>
        {Platform.OS === 'ios' ? (
          <View style={{marginRight: 25}}>
            <CheckBox
              checked={search ? filteredSelected : allSelected}
              onPress={() => toggleAll()}
              title={t('all')  + ` ${search}`}
              checkedIcon={
                typeof all_selected_check === 'boolean' ? (
                  <Ionicons name="checkmark" size={24} color="#007aff" /> // Жирний блакитний колір для іконки галочки
                ) : (
                  <Ionicons name="create" size={24} color="#007aff" />
                ) // Жирний блакитний колір для іконки олівця
              }
              uncheckedIcon={
                typeof all_selected_check === 'boolean' ? (
                  <Ionicons name="square-outline" size={24} color="grey" /> // Колір для іконки квадрату
                ) : (
                  <Ionicons name="create" size={24} color="#007aff" />
                ) // Жирний блакитний колір для іконки олівця
              }
            />
          </View>
        ) : (
          <CheckBox
            checked={search ? filteredSelected : allSelected}
            onPress={() => toggleAll()}
            title={'Все ' + `${search}`}
            containerStyle={styles.headerCheckBox}
            // iconType="material-community"
            checkedIcon={
              typeof all_selected_check === 'boolean'
                ? 'check-square-o'
                : 'pencil-square-o'
            }
            uncheckedIcon={
              typeof all_selected_check === 'boolean'
                ? 'square-o'
                : 'pencil-square-o'
            }
          />
        )}
        <Pressable
          style={styles.headerButtonReload}
          onPress={() => {
            selectAccount();
          }}>
          {Platform.OS === 'ios' ? (
            <Ionicons name="refresh" size={15} color="blue" />
          ) : (
            <Icon name="refresh" size={15} color="blue" />
          )}
        </Pressable>
        <Pressable
          onPress={() => {
            handleShowModal();
          }}
          style={styles.headerButton}>
          <Text style={styles.buttonText}>{t('send')}</Text>
        </Pressable>
        <Pressable
          style={styles.headerButton}
          onPress={() => {
            navigation.navigate('Invitations');
          }}>
          <Text style={styles.buttonText}>{t('close')}</Text>
        </Pressable>
      </View>
      <View style={{...styles.selectedItems, alignItems: 'center'}}>
        <Text style={styles.checkedContacts}>
          {t('selected')} {checkedContacts} {t('from')} {contacts.filter(item => item.resourceName).length}
        </Text>
      </View>
      <View>
        <SearchBar
          placeholder={t('start_type_name')}
          onChangeText={text => {
            filter(text);
          }}
          onCancel={() => {
            filter('');
          }}
          lightTheme={true}
          value={search}
          containerStyle={styles.searchBarContainer}
          inputContainerStyle={styles.searchBarInput}
          leftIconContainerStyle={styles.searchBarLeftIcon}
          rightIconContainerStyle={styles.searchBarRightIcon}
        />
      </View>
      <View style={styles.container}>
        {Platform.OS === 'ios' ? (
          <FlatList
            data={visibleData}
            renderItem={({item}) => {
              // Перевірка чи існує item та item.resourceName перед передачею в MemContactItem
              if (!item || !item.resourceName) {
                // Ваш код обробки випадку коли item або item.resourceName не існує
                return null;
              }

              //  otherContacts/ or people/
              const resourceName = item.resourceName.replace(
                'otherContacts/',
                ''
              );

              // IOS
              const isChecked =
                selectedItems[resourceName]?.checked ?? all_selected_check;
              const emailValue = item.emailAddresses?.[0]?.value;
              const isSended = alreadySended[emailValue] ?? null;

              return (
                <MemContactItem
                  contact={item}
                  selectedItems={selectedItems}
                  setSelectedItems={setSelectedItems}
                  setNeedUpdate={setNeedUpdate}
                  isChecked={isChecked}
                  isSended={isSended}
                  allContacts={contacts}
                  allSelected={
                    search.length < 3 ? allSelected : filteredSelected
                  }
                  setAllSelected={setAllSelected}
                  // setCheckedContacts={setCheckedContacts}
                  key={item.index}
                />
              );
            }}
            ListEmptyComponent={<Text>Загрузка ...</Text>}
            keyExtractor={(item, index) =>
              item?.resourceName
                ? `${item.resourceName}-${index}`
                : `item-${index}`
            }
            onEndReached={loadMoreData}
            onEndReachedThreshold={0.1}
          />
        ) : (
          <FlatList
            data={visibleData}
            renderItem={({item}) => (

              <MemContactItem
                contact={item}
                selectedItems={selectedItems}
                setSelectedItems={setSelectedItems}
                setNeedUpdate={setNeedUpdate}
                isChecked={
                  selectedItems[
                    item.resourceName.replace('otherContacts/', '')
                  ] !== undefined
                    ? selectedItems[
                        item.resourceName.replace('otherContacts/', '')
                      ].checked
                    : all_selected_check
                }
                isSended={
                  alreadySended[`${item.emailAddresses[0].value}`] !== undefined
                    ? alreadySended[`${item.emailAddresses[0].value}`]
                    : null
                }
                allContacts={contacts}
                allSelected={search.length < 3 ? allSelected : filteredSelected}
                setAllSelected={
                  search.length < 3 ? setAllSelected : setFilteredSelected
                }
                key={item.index}
              />
            )}
            ListEmptyComponent={<Text>...</Text>}
            keyExtractor={(item, index) => `${item.resourceName}-${index}`}
            onEndReached={loadMoreData}
            onEndReachedThreshold={0.1}
          />
        )}
      </View>
      {isLoading === true && <ActivityIndicator size="medium" style={styles.activityIndicator}/>}
      {isError && <ErrorModal error={isError} setIsError={setIsError} />}
      {info && <InfoModal info={info} setInfo={setInfo} />}
      <FormModal
        visible={modalVisible}
        onClose={handleCloseModal}
        onSubmit={handleFormSubmit}
        sendVia={'email'}
        msgType={'invite'}
      />
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  contentHead: {
    // flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    width: wp('100%'),
    maxWidth: 360,
    marginTop: 10,
    alignSelf: 'center',
  },
  buttonText: {
    fontFamily: theme.FONT_FAMILY_MEDIUM,
    color: theme.FONT_COLOR_WHITE,
  },
  spinner: {
    flex: 1,
    flexDirection: 'column',
    alignContent: 'center',
    justifyContent: 'center',
  },
  inputStyle: {
    height: 40,
    borderColor: 'gray',
    borderWidth: 1,
    textAlign: 'center',
  },
  initials: {
    fontSize: 32,
    fontWeight: 'bold',
    color: 'white',
  },
  avatar: {
    borderRadius: 30,
    width: 55,
    height: 55,
    justifyContent: 'center',
    alignItems: 'center',
  },
  contactDetails: {},
  contactCon: {
    flex: 1,
    flexDirection: 'row',
    padding: 5,
    borderBottomWidth: 0.5,
    borderBottomColor: '#d9d9d9',
  },
  imgCon: {},
  placeholder: {
    width: 55,
    height: 55,
    borderRadius: 30,
    overflow: 'hidden',
    backgroundColor: '#d9d9d9',
    alignItems: 'center',
    justifyContent: 'center',
  },
  contactDat: {
    flex: 1,
    justifyContent: 'center',
    paddingLeft: 5,
  },
  txt: {
    fontSize: 18,
  },
  name: {
    fontSize: 16,
  },
  phoneNumber: {
    color: '#888',
  },
  checkedContacts: {
    fontSize: 14,
    fontWeight: 'bold',
  },
  headerButtons: {
    marginTop: 10,
    flexDirection: 'row',
    heigth: 40,
    gap: 3,
    alignItems: 'center',
    justifyContent: 'center',
    wordWrap: '',
  },
  headerButton: {
    height: 40,
    padding: 2,
    width: '25%',
    maxWidth: 90,
    borderWidth: 1,
    borderColor: '#000000',
    // backgroundColor: 'rgb(242, 242, 242)',
    backgroundColor: theme.BLACK,
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 10,
    textAlign: 'center',
  },
  headerButtonReload: {
    height: 40,
    padding: 3,
    width: 40,
    borderWidth: 1,
    borderColor: '#000000',
    backgroundColor: 'rgb(242, 242, 242)',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 10,
    textAlign: 'center',
  },
  headerCheckBox: {
    height: 40,
    padding: 3,
    width: '20%',
    // alignItems: 'left',
    justifyContent: 'center',
    backgroundColor: 'rgb(242, 242, 242)',
    alignSelf: 'flex-start',
  },
  searchBarContainer: {
    backgroundColor: 'rgb(242, 242, 242)',
    borderBottomColor: '#FFF',
    borderTopColor: '#FFF',
  },
  searchBarInput: {
    backgroundColor: '#FFF',
  },
  searchBarLeftIcon: {
    backgroundColor: '#FFF',
  },
  searchBarRightIcon: {
    backgroundColor: '#FFF',
  },
  loader: {
    marginTop: 100,
    justifyContent: 'center',
    alignItems: 'center',
  },
  iosContentHead: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  activityIndicator: {
    color: "#3E3EFFBF",
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'transparent', // Прозрачный фон
  }
});

export default OtherContactList;
