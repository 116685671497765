import {Dimensions, Platform, StyleSheet} from 'react-native';

let screenHeight = Dimensions.get('window').height;

const platformStyles = {
  web: (wp, hp, theme) =>
    StyleSheet.create({
      container: {
        // flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: hp('100%'),
        backgroundColor: '#FFF',
      },
      contentContainer: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'flex-start',
        backgroundColor: '#FFF',
        width: wp('100%'),
        maxWidth: 1080,
        flexDirection: 'column',
      },
      headerContainer: {
        flexDirection: 'row',
        justifyContent: 'flex-start',
        width: wp('100%'),
        marginBottom: 15,
      },
      headBackButton: {
        height: 50,
        width: wp('15%'),
        maxWidth: 70,
        backgroundColor: theme.BLACK,
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: 10,
        // marginRight: wp('10%'),
        borderTopEndRadius: 20,
        borderBottomEndRadius: 20,
      },
      contentHead: {
        marginTop: 10,
        flexGrow: 1,
        flexShrink: 0,
        height: 50,
        width: 200,
        position: 'absolute',
        left: wp('50%'),
        marginLeft: -100,
        maxWidth: 275,
        backgroundColor: theme.BLUE,
        borderRadius: 20,
        alignItems: 'center',
        justifyContent: 'center',
        // marginBottom: 15,
      },
      arrowLeft: {
        width: 40,
        height: 40,
      },
      contentHeadText: {
        fontFamily: theme.FONT_FAMILY_MEDIUM,
        color: theme.FONT_COLOR_WHITE,
        fontSize: 24,
        lineHeight: 24,
        textAlign: 'center',
      },
      btnGroup: {
        marginTop: 15,
        gap: 12,
        marginBottom: 32,
        // flex: 1,
        flexDirection: 'row',
        height: 30

      },
      btnProfile: {
        width: 184,
        height: 39,
        paddingHorizontal: 40,
        paddingVertical: 10,
        borderWidth: 1,
        borderStyle: 'solid',
        borderRadius: 10,
        borderColor: '#000000',
        alignItems: 'center',
        justifyContent: 'center',
      },
      btnCommon: {
        width: 99,
        height: 30,
        paddingHorizontal: 15,
        paddingVertical: 7,
        borderWidth: 1,
        borderStyle: 'solid',
        borderRadius: 15,
        borderColor: '#000000',
        // backgroundColor: theme.BLACK,
        backgroundColor: '#000000',
        alignItems: 'center',
        justifyContent: 'center',
      },
      btnCommonTxtSmall: {
        fontFamily: 'Mariupol-Medium',
        fontSize: 12,
        // color: theme.FONT_COLOR_WHITE,
        color: '#FFF',
      },
      btnCommonTxt: {
        fontFamily: 'Mariupol-Medium',
        fontSize: 12,
        color: theme.FONT_COLOR_WHITE,
        // color: '#FFF',
      },
      divider: {
        flex: 1,
      },
      radioGroup: {
        padding: 2,
        marginTop: 10,
      },
      radioText: {
        fontSize: 14,
        left: -2,
      },
    }),
  android: (wp, hp, theme) =>
    StyleSheet.create({
      container: {
        minHeight: hp('100%'),
        width: wp('100%'),
        backgroundColor: '#FFF',
      },
      contentContainer: {
        flex: 1,
        alignItems: 'center',
        backgroundColor: '#FFF',
        width: wp('100%'),
        maxWidth: 1080,
        flexDirection: 'column',
      },
      headerContainer: {
        flexDirection: 'row',
        justifyContent: 'flex-start',
        width: wp('100%'),
        marginBottom: 15,
      },
      headBackButton: {
        height: 50,
        width: wp('15%'),
        maxWidth: 70,
        backgroundColor: theme.BLACK,
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: 10,
        // marginRight: wp('10%'),
        borderTopEndRadius: 20,
        borderBottomEndRadius: 20,
      },
      contentHead: {
        marginTop: 10,
        flexGrow: 1,
        flexShrink: 0,
        height: 'auto',
        width: 200,
        position: 'absolute',
        left: wp('50%'),
        marginLeft: -100,
        maxWidth: 275,
        backgroundColor: theme.BLUE,
        borderRadius: 20,
        alignItems: 'center',
        justifyContent: 'center',
        padding: 5,
        // marginBottom: 15,
      },
      arrowLeft: {
        width: 40,
        height: 40,
      },
      contentHeadText: {
        fontFamily: theme.FONT_FAMILY_MEDIUM,
        color: theme.FONT_COLOR_WHITE,
        fontSize: 24,
        lineHeight: 24,
        textAlign: 'center',
      },
      btnGroup: {
        marginTop: 15,
        gap: 12,
        marginBottom: 32,
        // flex: 1,
        flexDirection: 'row',
        height: 30

      },
      btnProfile: {
        width: 184,
        height: 39,
        paddingHorizontal: 40,
        paddingVertical: 10,
        borderWidth: 1,
        borderStyle: 'solid',
        borderRadius: 10,
        borderColor: '#000000',
        alignItems: 'center',
        justifyContent: 'center',
      },
      btnCommon: {
        width: 99,
        height: 30,
        paddingHorizontal: 15,
        paddingVertical: 7,
        borderWidth: 1,
        borderStyle: 'solid',
        borderRadius: 15,
        borderColor: theme.BLACK,
        backgroundColor: theme.BLACK,
        alignItems: 'center',
        justifyContent: 'center',
      },
      btnCommonTxtSmall: {
        fontFamily: 'Mariupol-Medium',
        fontSize: 12,
      },
      btnCommonTxt: {
        fontFamily: 'Mariupol-Medium',
        fontSize: 12,
        lineHeight: 12,
        color: theme.FONT_COLOR_WHITE,
      },      divider: {
        flex: 1,
      },
      radioGroup: {
        padding: 2,
        marginTop: 10,
      },
      radioText: {
        fontSize: 14,
        left: -2,
      },
    }),
  ios: (wp, hp, theme) =>
    StyleSheet.create({
      container: {
        flex: 1,
        alignItems: 'center',
        backgroundColor: '#FFF',
        justifyContent: 'center',
        minHeight: screenHeight,
      },
      contentContainer: {
        flex: 1,
        alignItems: 'center',
        backgroundColor: '#FFF',
        // width: 1080,
        flexDirection: 'column',
        overflow: 'scroll',
        marginTop: 70,
      },
      contentHeadText: {
        fontFamily: 'Inter',
        fontSize: 26,
        lineHeight: 28,
        textAlign: 'left',
        height: 30,
        color: '#000000',
      },
    }),
};

export default platformStyles[Platform.OS];
