import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
// import en from './translations/en.json';
// import ru from './translations/ru.json';
// import ua from './translations/ua.json';
import translation from './translations/common.json';

i18n
  .use(initReactI18next)
  .init({
    // resources: {
    //   en: { translation: en },
    //   ua: { translation: ua },
    //   ru: { translation: ru }
    // },
    resources: translation,
    lng: 'ua',
    fallbackLng: 'ua',
    interpolation: {
      escapeValue: false
    }
  });

export default i18n;
