import React, {useState, useContext, useEffect} from 'react';
import {
  Image,
  Pressable,
  Text,
  TextInput,
  Platform,
  ScrollView,
} from 'react-native';
import {Box, HStack, View, Stack} from 'native-base';

import AxiosInstance from '../../components/common/axiosInstance';

import Header from '../../components/common/Header';
import Footer from '../../components/common/Footer';
import {AuthContext} from '../../components/AuthProvider';
import Config from '../../config/config';
import {
  widthPercentageToDP as wp,
  heightPercentageToDP as hp,
} from 'react-native-responsive-screen';

import ArrowLeft from '../../assets/img/arrow_left_white.svg';

import ErrorModal from '../../components/common/ErrorModal';
import InfoModal from '../../components/common/InfoModal';
import Loader from '../../components/common/Loader';
import Empty from '../../components/common/Empty';

import {default as commonStylesDesktop} from '../../styles/common';
import {default as commonStylesMobile} from '../../styles/common_mobile';
import {default as profileStylesDesktop} from '../../styles/profile';
import {default as profileStylesMobile} from '../../styles/profile_mobile';

const ProfileEditLink = ({navigation, route}) => {
  const {user, logout, t, screenWidth, theme, screenParams, addHistory} =
    useContext(AuthContext);
  let fontSizeBig =
    Math.ceil(screenWidth / 18) < 40 ? Math.ceil(screenWidth / 12) : 40;
  let fontSizeMd =
    Math.ceil(screenWidth / 20) < 24 ? Math.ceil(screenWidth / 14) : 24;
  let commonStyles =
    screenWidth < 1080
      ? commonStylesMobile(wp, hp, theme)
      : commonStylesDesktop(wp, hp, theme);
  let profileStyles =
    screenWidth < 1080
      ? profileStylesMobile(wp, hp, fontSizeBig, fontSizeMd, theme)
      : profileStylesDesktop(wp, hp, fontSizeBig, fontSizeMd, theme);

  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(null);
  const [info, setInfo] = useState('');
  // const [updatedUserData, setUpdatedUserData] = useState(route.params);
  const [updatedUserData, setUpdatedUserData] = useState(screenParams);

  const updateRefLink = async () => {
    if (!updatedUserData.phone) {
      setIsError(t('no_inputed_phone'));
    }
    if (!updatedUserData.ref_link || updatedUserData.ref_link.length > 10) {
      setIsError(t('no_inputed_link_name'));
    }

    setIsLoading(true);

    const api = AxiosInstance({user, logout});
    await api(`${Config.API_URL}/api/v1/users/${user.id}/update_ref_link`, {
      method: 'POST',
      data: {
        phone: updatedUserData.phone,
        domain: updatedUserData.ref_link,
      },
    })
      .then(response => {
        if (response.status === 200) {
          setInfo(t('your_link_updated'));
        }
      })
      .catch(error => {
        if (error.code === 422) {
          setIsError(t('link_name_exist'));
        } else if (error.response && error.response.data) {
          setIsError(error.response.data.message);
        } else {
          setIsError(t('server_error'));
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (Platform.OS !== 'web') {
      addHistory(route.name);
    }
  }, [addHistory, route]);

  if (isLoading) {
    return <Loader />;
  } else {
    return (
      <ScrollView contentContainerStyle={commonStyles.container}>
        <Header navigation={navigation} route={route} />
        <View style={{...commonStyles.headerContainer, height: 130}}>
          <Pressable
            style={commonStyles.headBackButton}
            onPress={() => navigation.navigate('Profile')}>
            {Platform.OS === 'web' ? (
              <Image source={ArrowLeft} style={commonStyles.arrowLeft} />
            ) : (
              <ArrowLeft style={commonStyles.arrowLeft} />
            )}
          </Pressable>
          <View
            style={{...commonStyles.contentHead, height: 90, maxWidth: 530}}>
            <Text style={commonStyles.contentHeadText}>
              {t('editing_personal_link')}
            </Text>
          </View>
        </View>
        <View style={profileStyles.profileContainer}>
          <View style={profileStyles.formView}>
            <View style={profileStyles.linkDescriptionContainer}>
              <Text style={profileStyles.linkDescriptionText}>
                {t('ref_link_description')}
              </Text>
            </View>

            <Stack style={profileStyles.formStack}>
              <Text style={profileStyles.formLabel}>
                {t('desired_affilate_link_name')}
              </Text>
              <HStack>
                <TextInput
                  style={profileStyles.refLinkInput}
                  placeholder="mysite"
                  value={updatedUserData.ref_link}
                  onChangeText={text =>
                    setUpdatedUserData({...updatedUserData, ref_link: text})
                  }
                />
                <View style={profileStyles.shop_domain}>
                  <Text style={profileStyles.shop_domain_text}>
                    .{Config.SHOP_MAIN_DOMAIN}
                  </Text>
                </View>
              </HStack>
            </Stack>
            <HStack style={profileStyles.formBtnGroup}>
              <Box>
                <Pressable
                  onPress={() => {
                    navigation.navigate('Profile');
                  }}
                  style={profileStyles.formBtn}>
                  <Text style={profileStyles.frmButtonText}>{t('cancel')}</Text>
                </Pressable>
              </Box>
              <Box>
                <Pressable
                  onPress={() => updateRefLink()}
                  style={profileStyles.formBtn}>
                  <Text style={profileStyles.frmButtonText}>{t('update')}</Text>
                </Pressable>
              </Box>
            </HStack>
          </View>
        </View>
        <Footer />
        {isError ? (
          <ErrorModal error={isError} setIsError={setIsError} />
        ) : (
          <></>
        )}
        {info ? <InfoModal info={info} setInfo={setInfo} /> : <></>}
        {Platform.OS !== 'web' && <Empty />}
      </ScrollView>
    );
  }
};

export default ProfileEditLink;
