import {Platform, StyleSheet} from 'react-native';

const platformStyles = {
  web: (wp, hp, theme) =>
    StyleSheet.create({
      contentHead: {
        marginTop: 10,
      },
      threeIcon: {
        width: 80,
        height: 40,
        left: 5,
      },
      btnGroup: {
        marginTop: 31,
        gap: 12,
        marginBottom: 32,
        flex: 1,
        justifyContent: 'center',
      },
      btnProfile: {
        width: 184,
        height: 39,
        paddingHorizontal: 10,
        paddingVertical: 10,
        borderWidth: 1,
        borderStyle: 'solid',
        borderRadius: 10,
        borderColor: '#000000',
        alignItems: 'center',
        justifyContent: 'center',
      },
      btnText: {
        fontFamily: 'Inter',
        fontSize: 14,
        fontWeight: 'normal',
        lineHeight: 16,
        textAlign: 'center',
      },
      divider: {
        flex: 1,
      },
      itemCell: {
        fontFamily: theme.FONT_FAMILY_MEDIUM,
        color: theme.FONT_COLOR_BLUE,
        fontSize: 16,
        justifyContent: 'center',
      },
      trioStats: {
        marginTop: 32,
        gap: 12,
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'row',
        flexWrap: 'wrap',
      },
      trioStatBlack: {
        width: 184,
        height: 166,
        borderRadius: 20,
        backgroundColor: theme.BLACK,
      },
      trioStatBlue: {
        width: 184,
        height: 166,
        borderRadius: 20,
        backgroundColor: theme.BLUE,
      },
      trioStatTxt1: {
        width: 150,
        height: 58,
        marginTop: 20,
        marginLeft: 20,
        fontFamily: theme.FONT_FAMILY_MEDIUM,
        color: theme.FONT_COLOR_WHITE,
        fontSize: 25,
        lineHeight: 29,
        textAlign: 'left',
      },
      trioStatTxt2: {
        fontFamily: theme.FONT_FAMILY_MEDIUM,
        color: theme.FONT_COLOR_WHITE,
        height: 39,
        marginTop: 12,
        marginLeft: 20,
        fontSize: 36,
        lineHeight: 39,
        textAlign: 'left',
      },
      trioStatTxt3: {
        fontFamily: theme.FONT_FAMILY,
        color: theme.FONT_COLOR_WHITE,
        width: 50,
        height: 15,
        marginLeft: 20,
        fontSize: 14,
        lineHeight: 15,
        textAlign: 'left',
      },
      radioGroup: {
        padding: 10,
        marginTop: 20,
      },
      segmentButtons: {
        width: wp('90%'),
        maxWidth: 600,
        marginTop: 20,
        marginBottom: 20,
      }
    }),
  android: (wp, hp, theme) =>
    StyleSheet.create({
      contentHead: {
        marginTop: 10,
      },
      threeIcon: {
        width: 80,
        height: 40,
        left: 5,
      },
      btnGroup: {
        marginTop: 31,
        gap: 12,
        marginBottom: 32,
        flex: 1,
        justifyContent: 'center',
      },
      btnProfile: {
        width: 184,
        height: 39,
        paddingHorizontal: 10,
        paddingVertical: 10,
        borderWidth: 1,
        borderStyle: 'solid',
        borderRadius: 10,
        borderColor: '#000000',
        alignItems: 'center',
        justifyContent: 'center',
      },
      btnText: {
        fontFamily: 'Inter',
        fontSize: 14,
        fontWeight: 'normal',
        lineHeight: 16,
        textAlign: 'center',
      },
      divider: {
        flex: 1,
      },
      itemCell: {
        justifyContent: 'center',
      },
      trioStats: {
        marginTop: 32,
        gap: 12,
        alignItems: 'center',
        justifyContent: 'center',
      },
      trioStat: {
        width: 184,
        height: 166,
        borderRadius: 20,
        backgroundColor: '#EAEAEA',
      },
      trioStatTxt1: {
        width: 150,
        height: 58,
        marginTop: 20,
        marginLeft: 20,
        fontFamily: 'Inter',
        fontSize: 24,
        fontWeight: 'normal',
        lineHeight: 29,
        textAlign: 'left',
      },
      trioStatTxt2: {
        // width: 95,
        height: 39,
        marginTop: 12,
        marginLeft: 20,
        fontFamily: 'Inter',
        fontSize: 32,
        fontWeight: '900',
        lineHeight: 39,
        textAlign: 'left',
      },
      trioStatTxt3: {
        width: 50,
        height: 15,
        marginLeft: 20,
        fontFamily: 'Inter',
        fontSize: 12,
        fontWeight: '300',
        lineHeight: 15,
        textAlign: 'left',
      },
      radioGroup: {
        padding: 10,
        marginTop: 20,
      },
    }),
  ios: (wp, hp, theme) =>
    StyleSheet.create({
      contentHead: {
        marginTop: 10,
      },
      threeIcon: {
        width: 80,
        height: 40,
        left: 5,
      },
      btnGroup: {
        marginTop: 31,
        gap: 12,
        marginBottom: 32,
        flex: 1,
        justifyContent: 'center',
      },
      btnProfile: {
        width: 184,
        height: 39,
        paddingHorizontal: 10,
        paddingVertical: 10,
        borderWidth: 1,
        borderStyle: 'solid',
        borderRadius: 10,
        borderColor: '#000000',
        alignItems: 'center',
        justifyContent: 'center',
      },
      btnText: {
        fontFamily: 'Inter',
        fontSize: 14,
        fontWeight: 'normal',
        lineHeight: 16,
        textAlign: 'center',
      },
      divider: {
        flex: 1,
      },
      itemCell: {
        justifyContent: 'center',
      },
      trioStats: {
        marginTop: 32,
        gap: 12,
        alignItems: 'center',
        justifyContent: 'center',
      },
      trioStat: {
        width: 184,
        height: 166,
        borderRadius: 20,
        backgroundColor: '#EAEAEA',
      },
      trioStatTxt1: {
        width: 150,
        height: 58,
        marginTop: 20,
        marginLeft: 20,
        fontFamily: 'Inter',
        fontSize: 24,
        fontWeight: 'normal',
        lineHeight: 29,
        textAlign: 'left',
      },
      trioStatTxt2: {
        // width: 95,
        height: 39,
        marginTop: 12,
        marginLeft: 20,
        fontFamily: 'Inter',
        fontSize: 32,
        fontWeight: '900',
        lineHeight: 39,
        textAlign: 'left',
      },
      trioStatTxt3: {
        width: 50,
        height: 15,
        marginLeft: 20,
        fontFamily: 'Inter',
        fontSize: 12,
        fontWeight: '300',
        lineHeight: 15,
        textAlign: 'left',
      },
      radioGroup: {
        padding: 10,
        marginTop: 20,
      },
    }),
};

export default platformStyles[Platform.OS];
