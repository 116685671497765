import {Platform, StyleSheet} from 'react-native';

const platformStyles = StyleSheet.create({
  web: {
    contentHead: {
      marginTop: 20,
    },
    contentHeadText: {
      fontFamily: 'Inter',
      fontSize: 40,
      lineHeight: 48,
      textAlign: 'left',
      height: 48,
    },
    row: {
      width: 800,
      borderRadius: 10,
      borderWidth: 1,
      borderColor: '#000000',
      alignItems: 'center',
      // justifyContent: 'center',
      minHeight: 40,
      marginTop: 20,
    },
    key: {
      width: '40%',
      textAlign: 'center',
      fontFamily: 'Inter',
      fontSize: 14,
      fontWeight: 'normal',
    },
    value: {
      width: '50%',
      fontFamily: 'Inter',
      fontSize: 14,
      fontWeight: 'normal',
    },
    textArea: {
      width: '60%',
      backgroundColor: '#FEE',
      padding: 5,
    },
    text: {
      width: '60%',
      backgroundColor: '#dde7fb',
      padding: 5,
    },
    completed: {
      backgroundColor: '#077b05',
    },
    new: {
      backgroundColor: '#3E3EFF',
    },
    waiting: {
      backgroundColor: '#fda68b',
    },
    canceled: {
      backgroundColor: '#ffd2d2',
    },
  },
  android: {
    contentHead: {
      marginTop: 20,
    },
    contentHeadText: {
      fontFamily: 'Inter',
      fontSize: 20,
      lineHeight: 22,
      textAlign: 'center',
      height: 24,
    },
    row: {
      width: 320,
      borderRadius: 10,
      borderWidth: 1,
      borderColor: '#000000',
      alignItems: 'center',
      justifyContent: 'center',
      minHeight: 40,
      marginTop: 20,
    },
    key: {
      width: '20%',
      textAlign: 'center',
      fontFamily: 'Inter',
      fontSize: 14,
      fontWeight: 'normal',
    },
    value: {
      width: '70%',
      fontFamily: 'Inter',
      fontSize: 14,
      fontWeight: 'normal',
    },
    textArea: {
      width: '60%',
      backgroundColor: '#FEE',
      padding: 5,
    },
    completed: {
      backgroundColor: '#d3ffd2',
    },
    new: {
      backgroundColor: '#cacbff',
    },
    waiting: {
      backgroundColor: '#fda68b',
    },
    canceled: {
      backgroundColor: '#ffd2d2',
    },
  },
  ios: {
    contentHead: {
      marginTop: 20,
    },
    contentHeadText: {
      fontFamily: 'Inter',
      fontSize: 20,
      lineHeight: 22,
      textAlign: 'center',
      height: 24,
    },
    row: {
      width: 320,
      borderRadius: 10,
      borderWidth: 1,
      borderColor: '#000000',
      alignItems: 'center',
      justifyContent: 'center',
      minHeight: 40,
      marginTop: 20,
    },
    key: {
      width: '20%',
      textAlign: 'center',
      fontFamily: 'Inter',
      fontSize: 14,
      fontWeight: 'normal',
    },
    value: {
      width: '70%',
      fontFamily: 'Inter',
      fontSize: 14,
      fontWeight: 'normal',
    },
    textArea: {
      width: '60%',
      backgroundColor: '#FEE',
      padding: 5,
    },
    completed: {
      backgroundColor: '#d3ffd2',
    },
    new: {
      backgroundColor: '#cacbff',
    },
    waiting: {
      backgroundColor: '#fda68b',
    },
    canceled: {
      backgroundColor: '#ffd2d2',
    },
  },
});

export default {...platformStyles[Platform.OS]};
