import React, {useContext, useState, useEffect} from 'react';
import {
  View,
  Text,
  TextInput,
  StyleSheet,
  Pressable,
  ActivityIndicator,
  TouchableOpacity,
  Dimensions,
  Platform,
  KeyboardAvoidingView,
  ScrollView,
  Keyboard,
  TouchableWithoutFeedback,
  Image,
} from 'react-native';
import {KeyboardAwareScrollView} from 'react-native-keyboard-aware-scroll-view';

import {HStack} from 'native-base';
import axios from 'axios';
import Icon from 'react-native-vector-icons/FontAwesome';
import Ionicons from 'react-native-vector-icons/Ionicons';

import {
  widthPercentageToDP as wp,
  heightPercentageToDP as hp,
} from 'react-native-responsive-screen';

import {validateEmail} from '../../components/common/commonFunctions';

import ErrorModal from '../../components/common/ErrorModal';
import InfoModal from '../../components/common/InfoModal';
import Header from '../../components/common/Header';
import Footer from '../../components/common/Footer';

import HideIcon from '../../assets/img/hide.svg';
import ShowIcon from '../../assets/img/show.svg';

import {default as commonStylesMobile} from '../../styles/common_mobile';
import {default as theme} from '../../config/theme_config';

import Config from '../../config/config';
import {t} from 'i18next';
import {AuthContext} from '../../components/AuthProvider';

const ResetPassword = ({navigation, route}) => {
  const {addHistory, inputedData, setInputedData, i18n} = useContext(AuthContext);
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [info, setInfo] = useState('');
  const [tmpJwt, setTmpJwt] = useState('');
  const [userId, setUserId] = useState(0);
  const [isShowKeyboard, setIsShowKeyboard] = useState(true);

  const commonStyles = commonStylesMobile(wp, hp, theme);

  const sendVerificationCode = async () => {
    if (!validateEmail(email)) {
      setError(t('error_incorrect'));
      return false;
    }
    setInputedData(prevState => {return {...prevState, email: email}});
    setLoading(true);

    // console.log('phone: ', phone);
    await axios
      .post(
        `${Config.API_URL}/api/users/send_code`,
        {
          email: email,
          phone: inputedData.phone,
          lang: i18n.language,
        },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      )
      .then(response => {
        // console.log('send code response', response);
        if (response.data && response.data.message.result === true) {
          navigation.navigate('ResetPasswordCodeVerification');
        } else if (response.data && response.data.message.result === false) {
          setError(response.data.message.error);
        }
      })
      .catch(error => {
        console.log('send code error', error);
        if (error.response !== undefined) {
          setError(error.response.data.message.error);
        } else {
          setError(t('server_error'));
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const keyboardHide = () => {
    setIsShowKeyboard(false);
    Keyboard.dismiss();
  };

  const keyboardDismiss = () => {
    if (Platform.OS === 'ios') {
      Keyboard.dismiss();
    }
    return false;
  };

  useEffect(() => {
    if (Platform.OS !== 'web') {
      addHistory('ResetPassword');
    }
  }, [addHistory]);

  return (
    <TouchableWithoutFeedback onPress={() => keyboardDismiss()}>
      <ScrollView contentContainerStyle={commonStyles.container}>
        <Header navigation={false} route={false} />
        <View style={commonStyles.contentContainer}>
          <View
            style={
              Platform.OS === 'ios' ? styles.iosContentHead : styles.contentHead
            }>
            <Text
              style={
                Platform.OS === 'ios'
                  ? styles.iosContentHeadText
                  : commonStyles.contentHeadText
              }>
              {t('password_restoring')}
            </Text>
          </View>
          <KeyboardAvoidingView
            // behavior={Platform.OS === 'ios' ? 'padding' : 'height'}
            style={styles.content}>
            <View
              style={
                Platform.OS === 'ios' ? styles.iosContent : styles.content
              }>
              {Platform.OS !== 'ios' ? (
                <View style={styles.passwordBlock}>
                  <Text style={styles.infoText}>
                    {t('enter_email_for_password')}
                  </Text>
                  <Text style={styles.label}>{t('enter_email')}*</Text>
                  <TextInput
                    type={'text'}
                    placeholder="Email"
                    value={email}
                    onChangeText={text => setEmail(text)}
                    style={styles.phoneInput}
                  />
                  <HStack style={styles.btnGroup}>
                    <Pressable
                      onPress={() =>
                        Platform.OS === 'web'
                        ? navigation.push('PasswordVerifying', {email: email})
                        : navigation.navigate('PasswordVerifying', {email: email})
                      }
                      style={styles.button}>
                      <Text style={styles.buttonText}>{t('back')}</Text>
                    </Pressable>
                    <Pressable
                      onPress={sendVerificationCode}
                      style={styles.button}>
                      <Text style={styles.buttonText}>{t('send')}</Text>
                    </Pressable>
                  </HStack>
                </View>
              ) : (
                <View
                  style={{
                    ...styles.iosContent,
                    marginBottom: isShowKeyboard ? 250 : 170,
                  }}>
                  <Text style={styles.infoText}>
                    {t('enter_email_for_password')}
                  </Text>
                  <Text style={styles.label}>{t('enter_email')}*</Text>
                  <TextInput
                    type={'text'}
                    placeholder="Emai"
                    value={email}
                    onChangeText={text => setEmail(text)}
                    style={styles.phoneInput}
                    onFocus={() => setIsShowKeyboard(false)}
                  />
                  <View
                    style={
                      Platform.OS === 'ios'
                        ? styles.iosBtnGroup
                        : styles.btnGroup
                    }>
                    <Pressable
                      onPress={() => {
                        navigation.navigate('PasswordVerifying');
                        keyboardHide;
                      }}
                      style={styles.button}>
                      <Text style={styles.buttonText}>{t('back')}</Text>
                    </Pressable>
                    <Pressable
                      onPress={sendVerificationCode}
                      style={styles.button}>
                      <Text style={styles.buttonText}>{t('send')}</Text>
                    </Pressable>
                  </View>
                </View>
              )}
            </View>
          </KeyboardAvoidingView>
        </View>
        <Footer />
        {error ? <ErrorModal error={error} setIsError={setError} /> : <></>}
        {info ? <InfoModal info={info} setInfo={setInfo} /> : <></>}
        {loading === true && (
          <ActivityIndicator
            style={styles.activityIndicator}
            color="#0C090D"
            size="large"
          />
        )}
      </ScrollView>
    </TouchableWithoutFeedback>
  );
};

const styles = StyleSheet.create({
  content: {
    flex: 1,
    marginTop: 28,
    width: 251,
    alignSelf: 'center',
    alignItems: 'center',
    // minHeight: '100%',
  },
  contentContainer: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'flex-start',
    backgroundColor: '#FFF',
    width: wp('100%'),
    maxWidth: 1080,
    flexDirection: 'column',
  },
  contentHead: {
    flex: 1,
    marginTop: 10,
    flexGrow: 1,
    flexShrink: 0,
    backgroundColor: theme.BLUE,
    borderRadius: 20,
    width: 250,
    maxHeight: 80,
    alignItems: 'center',
    justifyContent: 'center',
    alignSelf: 'center',
    padding: 10,
  },
  passwordBlock: {
    flex: 1,
  },
  phoneInput: {
    width: 251,
    height: 42,
    padding: 10,
    borderRadius: 10,
    borderWidth: 2,
    gap: 10,
    borderColor: theme.BLACK,
    fontFamily: theme.FONT_FAMILY,
    fontSize: 20,
    lineHeight: 20,
    textAlign: 'left',
  },
  label: {
    fontFamily: theme.FONT_FAMILY_MEDIUM,
    fontSize: 12,
    lineHeight: 15,
    textAlign: 'left',
    color: theme.FONT_COLOR_BLUE,
  },
  btnGroup: {
    gap: 10,
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: 20,
  },
  button: {
    marginTop: 18,
    width: 120,
    height: 40,
    paddingVertical: 10,
    paddingHorizontal: 15,
    borderRadius: 10,
    gap: 10,
    backgroundColor: theme.BLUE,
    alignItems: 'center',
    justifyContent: 'center',
  },
  buttonText: {
    fontFamily: theme.FONT_FAMILY_MEDIUM,
    color: theme.FONT_COLOR_WHITE,
    fontSize: 16,
    lineHeight: 19,
    textAlign: 'center',
  },
  activityIndicator: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'transparent', // Прозрачный фон
  },
  infoText: {
    paddingBottom: 20,
    fontFamily: theme.FONT_FAMILY_MEDIUM,
    color: theme.FONT_COLOR_BLACK,
    fontSize: 14,
    lineHeight: 16,
    textAlign: 'left',
  },
  eye: {
    width: 30,
    height: 30,
    alignItems: 'center',
    justifyContent: 'center',
    marginLeft: -40,
  },
  eyeShow: {
    width: 20,
    height: 10,
    // alignItems: 'center',
    // justifyContent: 'center',
    // marginLeft: -40,
  },
  eyeHide: {
    width: 21,
    height: 15,
    // alignItems: 'center',
    // justifyContent: 'center',
    // marginLeft: -40,
  },
  inputContainer: {
    alignItems: 'center',
  },
  iosContent: {
    marginHorizontal: 40,
    // marginBottom: 50,
    // flex: 1,
    resizeMode: 'cover',
    // marginTop: 28,
    // width: 251,
    // alignSelf: 'center',
    alignItems: 'center',
    // justifyContent: 'flex-end',
    // backgroundColor: 'red',
  },
  iosPasswordBlock: {
    paddingTop: 60,
    // width: '100%', // Забезпечує, що блок займає всю ширину
  },
  iosContentHead: {
    height: 100,
    marginTop: 50,
    // marginBottom: 40,
    // backgroundColor: 'red',

    padding: 0,
  },
  iosBtnGroup: {
    display: 'flex',
    flexDirection: 'row', // Забезпечує розміщення кнопок горизонтально
    justifyContent: 'center', // Центрує кнопки у контейнері
    // backgroundColor: 'red',
    gap: 10,
  },
  iosContentHeadText: {
    fontFamily: 'Inter',
    fontSize: 40,
    fontWeight: 'normal',
    lineHeight: 48,
    textAlign: 'center',
    // height: 48,
  },
});

export default ResetPassword;
