import React from 'react';
import {StyleSheet, View, Pressable, Image} from 'react-native';

const PaginationFooter = props => {
  // console.log('PaginationFooter', props);
  return (
    //Footer View with Load More button
    <View style={styles.footerPagination}>
      {props.currentPage > 1 ? (
        <Pressable
          activeOpacity={0.9}
          onPress={props.loadPrev}
          //On Click of button load more data
          style={styles.loadMoreBtn}>
          <Image
            style={styles.loadMoreImg}
            source={require('../../assets/img/si_Arrow_left.png')}
          />
        </Pressable>
      ) : null}
      {props.currentPage < props.totalPages ? (
        <Pressable
          activeOpacity={0.9}
          onPress={props.loadNext}
          //On Click of button load more data
          style={styles.loadMoreBtn}>
          <Image
            style={styles.loadMoreImg}
            source={require('../../assets/img/si_Arrow_right.png')}
          />
        </Pressable>
      ) : null}
    </View>
  );
};

const styles = StyleSheet.create({
  footerPagination: {
    gap: 16,
    justifyContent: 'flex-end',
    alignItems: 'center',
    flexDirection: 'row',
  },
  loadMoreBtn: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  loadMoreImg: {
    width: 24,
    height: 24,
  },
});

export default PaginationFooter;
