import React, {useContext, useState, useEffect, useCallback} from 'react';
import {FlatList, View, Pressable, Image, Platform} from 'react-native';
import {Text, HStack, Radio, VStack} from 'native-base';
import {useIsFocused} from '@react-navigation/native';
import AxiosInstance from '../../components/common/axiosInstance';

import ArrowUp from '../../assets/img/arrow-down.svg';
import ArrowDown from '../../assets/img/arrow-up.svg';
import Header from '../../components/common/Header';
import Footer from '../../components/common/Footer';
import Loader from '../../components/common/Loader';
import ErrorModal from '../../components/common/ErrorModal';
import {AuthContext} from '../../components/AuthProvider';
import PaginationFooter from '../../components/common/paginationFooter';

import commonStyles from '../../styles/common';
import listStyles from '../../styles/list';
import withdrawStyles from '../../styles/withdraws';
import { t } from 'i18next';

const WithdrawsHeader = props => {
  const dateSort = (currSort, direction) => {
    if (currSort !== 'created_at') {
      props.setSortField('created_at');
    }
    direction === 'desc'
      ? props.setSortDirection('asc')
      : props.setSortDirection('desc');
  };
  return (
    <HStack style={listStyles.itemsHeader}>
      <Pressable
        onPress={() => {
          dateSort(props.sortField, props.sortDirection);
        }}>
        <HStack style={Platform.OS === 'ios' ? {alignItems: 'center'} : {}}>
          <Text style={{...listStyles.itemsHeaderText, marginLeft: -20}}>
            {t('date')}
          </Text>
        </HStack>
      </Pressable>
      <Text style={listStyles.itemsHeaderText}>{t('sum')}</Text>
      <Text style={listStyles.itemsHeaderText}>{t('status')}</Text>
      <Text style={listStyles.itemsHeaderText}>{t('updated')}</Text>
    </HStack>
  );
};

const WithdrawItem = ({item}) => {
  let status_str = '';
  let statusStyle = '';
  switch (item.status) {
    case 'new':
      status_str = t('new');
      statusStyle = withdrawStyles.new;
      break;
    case 'waiting':
      status_str = t('waiting');
      statusStyle = withdrawStyles.waiting;
      break;
    case 'completed':
      status_str = t('completed');
      statusStyle = withdrawStyles.completed;
      break;
    case 'canceled':
      status_str = t('canceled')
      statusStyle = withdrawStyles.canceled;
      break;
  }
  return (
    <HStack style={[listStyles.itemRow, statusStyle]}>
      <Text style={listStyles.itemCol}>{item.created_at}</Text>
      <Text style={listStyles.itemCol}>{item.amount}</Text>
      <Text style={listStyles.itemCol}>{status_str}</Text>
      <Text style={listStyles.itemCol}>{item.updated_at}</Text>
    </HStack>
  );
};

const WithdrawScreen = ({navigation, route}) => {
  const {user, logout} = useContext(AuthContext);
  const [withdraws, setWithdraws] = useState({});
  const [loading, setLoading] = useState(true);
  const [isError, setIsError] = useState(null);
  const [pagination, setPagination] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [sortField, setSortField] = useState('created_at');
  const [sortDirection, setSortDirection] = useState('desc');
  const [status, setStatus] = useState('');

  const isFocused = useIsFocused();
  const loadWithdraws = useCallback(async () => {
    setLoading(true);
    const api = AxiosInstance({user, logout});
    let url = `/api/v1/withdraw?page=${currentPage}&order_field=${sortField}&order=${sortDirection}`;
    if (status !== null) {
      url += `&status=${status}`;
    }
    await api(url, {
      method: 'GET',
      mode: 'cors',
    })
      .then(response => {
        setWithdraws(response.data.message.data);
        setPagination(response.data.message.pagination);
      })
      .catch(error => {
        setIsError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [user, currentPage, status, sortField, sortDirection, logout]);

  const loadNext = () => {
    let nextPage =
      pagination.totalPages > currentPage ? currentPage + 1 : currentPage;
    setCurrentPage(nextPage);
  };

  const loadPrev = () => {
    let prevPage = currentPage > 1 ? currentPage - 1 : currentPage;
    setCurrentPage(prevPage);
  };

  useEffect(() => {
    console.log('Withdraws useEffect ');
    if (isFocused) {
      loadWithdraws();
    }
  }, [loadWithdraws, isFocused]);

  if (loading) {
    return <Loader />;
  } else {
    return (
      <View style={commonStyles.container}>
        <Header navigation={navigation} route={route} />
        <View style={commonStyles.contentContainer}>
          <View style={commonStyles.contentHead}>
            <Text style={commonStyles.contentHeadText}>
              {t('withdraw_requests')}
            </Text>
          </View>
          <Radio.Group
            name="status"
            value={status}
            onChange={nextValue => {
              // setCurrentPage(1);
              setStatus(nextValue);
            }}
            style={commonStyles.radioGroup}>
            <HStack space={1} w="75%">
              {Platform.OS === 'ios' ? (
                <VStack space={4} style={{marginTop: 20}}>
                  <HStack space={4} justifyContent="center">
                    <Radio value={'new'}>{t('new')}</Radio>
                    <Radio value={'completed'}>{t('completed')}</Radio>
                    <Radio value={''}>все</Radio>
                  </HStack>
                </VStack>
              ) : (
                <VStack space={2}>
                  <HStack space={2} justifyContent="center">
                    <Radio
                      value={'new'}
                      style={commonStyles.radioText}
                      size="sm">
                      {t('new')}
                    </Radio>
                    <Radio
                      value={'completed'}
                      style={commonStyles.radioText}
                      size="sm">
                      {t('completed')}
                    </Radio>
                    <Radio value={''} style={commonStyles.radioText} size="sm">
                      {t('all')}
                    </Radio>
                  </HStack>
                </VStack>
              )}
            </HStack>
          </Radio.Group>
          <FlatList
            ListHeaderComponent={
              <WithdrawsHeader
                sortField={sortField}
                sortDirection={sortDirection}
                setSortField={setSortField}
                setSortDirection={setSortDirection}
              />
            }
            data={withdraws}
            renderItem={({item}) => <WithdrawItem item={item} />}
            keyExtractor={item => item.id}
            ListFooterComponent={
              <HStack style={listStyles.itemsFooterContainer}>
                <HStack style={commonStyles.btnGroup}>
                  <Pressable
                    style={commonStyles.btnProfile}
                    onPress={() => navigation.navigate('Profile')}>
                    <Text>{t('profile')}</Text>
                  </Pressable>
                  <Pressable
                    style={commonStyles.btnCommon}
                    onPress={() => setIsError('In work')}>
                    <Text>{t('create_request')}</Text>
                  </Pressable>
                  <View style={commonStyles.divider}>
                    <Text></Text>
                  </View>
                  <PaginationFooter
                    currentPage={currentPage}
                    totalPages={pagination.totalPages}
                    loadPrev={loadPrev}
                    loadNext={loadNext}
                  />
                </HStack>
              </HStack>
            }
          />
        </View>
        <Footer navigation={navigation}/>
        {isError && <ErrorModal error={isError} setIsError={setIsError} />}
      </View>
    );
  }
};

export default WithdrawScreen;
