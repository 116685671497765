import React, {useContext, useEffect, useState} from "react";
import {
  Platform,
  View,
  Pressable,
  Text,
  TextInput,
  ScrollView,
  TouchableOpacity,
  Image,
} from 'react-native';
import axios from 'axios';
import {HStack} from 'native-base';
import {
  widthPercentageToDP as wp,
  heightPercentageToDP as hp,
} from 'react-native-responsive-screen';
import Ionicons from 'react-native-vector-icons/Ionicons';

import {AuthContext} from '../../components/AuthProvider';

import HideIcon from '../../assets/img/hide.svg';
import ShowIcon from '../../assets/img/show.svg';

import ErrorModal from '../../components/common/ErrorModal';
import InfoModal from '../../components/common/InfoModal';
import Header from '../../components/common/Header';
import Footer from '../../components/common/Footer';
import Loader from "../../components/common/Loader";

import profileStylesF from '../../styles/profile';
import profileStylesMobileF from '../../styles/profile_mobile';
import commonStylesF from '../../styles/common';
import commonStylesMobileF from '../../styles/common_mobile';
import stylesF from '../../styles/login';

import {validateEmail} from '../../components/common/commonFunctions';

import Config from '../../config/config';

const EmailCreating = ({navigation, route}) => {
  const {
    t,
    addHistory,
    inputedData,
    screenWidth,
    theme,
    i18n,
  } = useContext(AuthContext);

  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(null);
  const [info, setInfo] = useState(null);
  const [checkEmailCode, setCheckEmailCode] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [verifyPassword, setVerifyPassword] = useState('');
  const [code, setCode] = useState('');
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [isShowKeyboard, setIsShowKeyboard] = useState(false);

  const fontSizeBig =
    Math.ceil(screenWidth / 18) < 40 ? Math.ceil(screenWidth / 14) : 40;
  const fontSizeMd =
    Math.ceil(screenWidth / 20) < 24 ? Math.ceil(screenWidth / 16) : 24;

  const commonStyles =
    screenWidth < 1080
      ? commonStylesMobileF(wp, hp, theme)
      : commonStylesF(wp, hp, theme);

  const profileStyles =
    screenWidth < 1080
      ? profileStylesMobileF(wp, hp, fontSizeBig, fontSizeMd, theme)
      : profileStylesF(wp, hp, fontSizeBig, fontSizeMd, theme);

  const styles = stylesF(wp, theme);

  const hideLogoutButton = Platform.OS === 'ios';

  const handleEmailCreate = async () => {
    // сохраняет введенный пользователем email и пароль и отправляет подтверждение на указанный email

    if (!validateEmail(email)) {
      setIsError(t('enter_correct_email'));
      return;
    }

    if (password === '' || password.length < 6) {
      setIsError(t('error_password_length'));
      return;
    }

    if (password !== verifyPassword) {
      setIsError(t('password_mismatch'));
      return;
    }

    setIsLoading(true);

    try {
      let response = await axios.post(
        `${Config.API_URL}/api/v1/users/send_confirm`,
        {
          email: email,
          password: password,
          lang: i18n.language,
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: inputedData.token,
          },
        }
      );

      if (response.data.code === 200 && response.data.status === 'success') {
        console.log('setInfo');
        setInfo(t('check_email_and_enter_code'));
        // setEmailCreating(false);
        setCheckEmailCode(true);
      } else if (response.data.message.error !== undefined) {
        console.log('setIsError code 200');
        setIsError(response.data.message.error);
      }
    } catch (error) {
      console.log('Email confirm send error', error.response.data.message);
      setIsError(error.response.data.message);
    } finally {
      setIsLoading(false);
    }
  };

  const checkVerificationCode = async () => {
    if (code.length < 6) {
      setIsError(t('error_code_length'));
      return false;
    }

    setIsLoading(true);

    await axios
      .post(
        `${Config.API_URL}/api/users/check_code`,
        {
          email: email,
          phone: inputedData.phone,
          code: code,
          activate: true,
        },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      )
      .then(response => {
        console.log('check code response', response.data);
        if (response.data && response.data.status === 'success') {
          setCheckEmailCode(false);
          Platform.OS === 'web'
          ? navigation.push('PasswordVerifying')
          : navigation.navigate('PasswordVerifying');
        } else if (response.data && response.data.status === 'error') {
          setIsError(response.data.message);
        }
      })
      .catch(error => {
        console.log('check code error', error);
        if (error.response !== undefined) {
          setIsError(error.response.data.message.error);
        } else {
          setIsError(t('server_error'));
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (Platform.OS !== 'web') {
      addHistory(route.name);
    }
    // console.log('EmailCreating useEffect')
  }, [addHistory, route]);
  if (isLoading) {
    return <Loader/>
  } else {
    return (
      <View style={commonStyles.container}>
        <ScrollView contentContainerStyle={commonStyles.container}>
          <Header
            navigation={navigation}
            route={route}
            hideLogoutButton={hideLogoutButton}
          />
          <View
            style={
              Platform.OS === 'ios'
                ? {
                    ...styles.iosProfileContainer,
                    marginBottom: isShowKeyboard ? 200 : 160,
                  }
                : profileStyles.profileContainer
            }>
            <View style={styles.contentHead}>
              <Text style={commonStyles.contentHeadText}>
                {t('password_creating')}
              </Text>
            </View>
            {checkEmailCode ? (
              <View style={styles.content}>
                <Text style={styles.infoText}>
                  {t('on_the_email_was_sended')} ({email}){' '}
                  {t('email_with_verification_code')}
                </Text>
                <Text style={styles.label}>
                  {t('enter_received_code')}*
                </Text>
                <TextInput
                  type={'text'}
                  keyboardType="numeric"
                  placeholder={t('code_from_email')}
                  value={code}
                  onChangeText={text => setCode(text)}
                  style={styles.phoneInput}
                />
                <HStack style={styles.btnGroup}>
                  <Pressable
                    onPress={() => setCheckEmailCode(false)}
                    style={styles.buttonSmall}>
                    <Text style={styles.buttonText}>{t('back')}</Text>
                  </Pressable>
                  <Pressable
                    onPress={checkVerificationCode}
                    style={styles.buttonSmall}>
                    <Text style={styles.buttonText}>{t('send')}</Text>
                  </Pressable>
                </HStack>
              </View>
            ) : (
              <View style={styles.content}>
                <Text style={styles.label}>{t('enter_email')}*</Text>
                <HStack style={styles.inputContainer}>
                <TextInput
                  type={'text'}
                  placeholder="Email"
                  value={email}
                  onChangeText={text => setEmail(text)}
                  style={styles.phoneInput}
                />
                </HStack>
                <Text style={styles.label}>{t('enter_password')}*</Text>
                <HStack style={styles.inputContainer}>
                  <TextInput
                    secureTextEntry={!isPasswordVisible}
                    type={'password'}
                    placeholder={t('password')}
                    value={password}
                    onChangeText={text => setPassword(text)}
                    style={styles.phoneInput}
                  />
                  {Platform.OS === 'ios' ? (
                    <TouchableOpacity
                      style={styles.eye}
                      onPress={() =>
                        setIsPasswordVisible(!isPasswordVisible)
                      }>
                      <Ionicons
                        name={isPasswordVisible ? 'eye' : 'eye-off'}
                        size={20}
                        color="#000"
                      />
                    </TouchableOpacity>
                  ) : (
                    <TouchableOpacity
                      style={styles.eye}
                      onPress={() =>
                        setIsPasswordVisible(!isPasswordVisible)
                      }>
                      {Platform.OS === 'web' ? (
                        <Image
                          source={isPasswordVisible ? HideIcon : ShowIcon}
                          style={
                            isPasswordVisible
                              ? styles.eyeHide
                              : styles.eyeShow
                          }
                        />
                      ) : isPasswordVisible ? (
                        <HideIcon style={styles.eyeHide} />
                      ) : (
                        <ShowIcon style={styles.eyeShow} />
                      )}
                    </TouchableOpacity>
                  )}
                </HStack>
                <Text style={styles.label}>{t('repeat_password')}*</Text>

                <HStack style={styles.inputContainer}>
                  <TextInput
                    secureTextEntry={!isPasswordVisible}
                    type={'password'}
                    placeholder={t('repeat_password')}
                    value={verifyPassword}
                    onChangeText={text => setVerifyPassword(text)}
                    style={styles.phoneInput}
                    onFocus={() => setIsShowKeyboard(false)}
                  />
                  {Platform.OS === 'ios' ? (
                    <TouchableOpacity
                      style={styles.eye}
                      onPress={() =>
                        setIsPasswordVisible(!isPasswordVisible)
                      }>
                      {/* <Text>{isPasswordVisible ? t('hide') : t('show')}</Text> */}
                      <Ionicons
                        name={isPasswordVisible ? 'eye' : 'eye-off'}
                        size={20}
                        color="#000"
                      />
                    </TouchableOpacity>
                  ) : (
                    <TouchableOpacity
                      style={styles.eye}
                      onPress={() =>
                        setIsPasswordVisible(!isPasswordVisible)
                      }>
                      {Platform.OS === 'web' ? (
                        <Image
                          source={isPasswordVisible ? HideIcon : ShowIcon}
                          style={
                            isPasswordVisible
                              ? styles.eyeHide
                              : styles.eyeShow
                          }
                        />
                      ) : isPasswordVisible ? (
                        <HideIcon style={styles.eyeHide} />
                      ) : (
                        <ShowIcon style={styles.eyeShow} />
                      )}
                    </TouchableOpacity>
                  )}
                </HStack>
                <HStack style={styles.btnGroup}>
                  <Pressable
                    onPress={() => Platform.OS === 'web'
                    ? navigation.push('CodeVerifying')
                    : navigation.navigate('CodeVerifying')
                    }
                    style={styles.buttonSmall}>
                    <Text style={styles.buttonText}>{t('back')}</Text>
                  </Pressable>
                  <Pressable
                    onPress={handleEmailCreate}
                    style={styles.buttonSmall}>
                    <Text style={styles.buttonText}>{t('create')}</Text>
                  </Pressable>
                </HStack>
              </View>
            )}
          </View>
          <Footer navigation={navigation} />
        </ScrollView>
        {isError ? (<ErrorModal error={isError} setIsError={setIsError} />) : (<></>)}
        {info ? <InfoModal info={info} setInfo={setInfo} /> : <></>}
    </View>
    );
  }
}

export default EmailCreating;