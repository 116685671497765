import {Platform, StyleSheet} from 'react-native';

const platformStyles = {
  web: theme =>
    StyleSheet.create({
      itemsHeader: {
        width: '90%',
        height: 72,
        alignItems: 'center',
        justifyContent: 'center',
        borderBottomWidth: 1,
        borderStyle: 'solid',
        borderBottomColor: '#D9D9D9',
        marginTop: 16,
        marginBottom: 4,
      },
      itemsCardHeader: {
        alignItems: 'center',
        justifyContent: 'center',
        borderBottomWidth: 1,
        borderStyle: 'solid',
        borderBottomColor: '#D9D9D9',
        marginTop: 16,
        marginBottom: 4,
      },
      itemsHeaderText: {
        fontFamily: theme.FONT_FAMILY,
        color: theme.FONT_COLOR_BLUE,
        fontSize: 16,
        fontWeight: 'normal',
        lineHeight: 19,
        textAlign: 'center',
      },
      itemsHeaderCardText: {
        width: 148,
        fontFamily: 'Inter',
        fontSize: 16,
        fontWeight: 'normal',
        lineHeight: 19,
        textAlign: 'left',
      },
      itemRow: {
        width: '90%',
        justifyContent: 'space-between',
        borderRadius: 10,
        marginTop: 12,
        backgroundColor: theme.BLUE,
        height: 70,
      },
      itemCardRow: {
        width: '90%',
        alignItems: 'center',
      },
      itemCol: {
        textAlign: 'center',
        justifyContent: 'center',
        fontFamily: theme.FONT_FAMILY_MEDIUM,
        color: theme.FONT_COLOR_WHITE,
        fontSize: 16,
        fontWeight: 'normal',
        lineHeight: 19,
      },
      itemCell: {
        justifyContent: 'center',
      },
      itemsFooterContainer: {
        flex: 'content',
        width: '90%',
      },
      dataTable: {
        width: '98%',
        alignItems: 'center',
      },
    }),
  android: theme =>
    StyleSheet.create({
      itemsHeader: {
        width: '90%',
        height: 72,
        alignItems: 'center',
        justifyContent: 'center',
        borderBottomWidth: 1,
        borderStyle: 'solid',
        borderBottomColor: '#D9D9D9',
        marginTop: 16,
        marginBottom: 4,
      },
      itemsCardHeader: {
        alignItems: 'center',
        justifyContent: 'center',
        borderBottomWidth: 1,
        borderStyle: 'solid',
        borderBottomColor: '#D9D9D9',
        marginTop: 16,
        marginBottom: 4,
      },
      itemsHeaderText: {
        fontFamily: theme.FONT_FAMILY,
        color: theme.FONT_COLOR_BLUE,
        fontSize: 16,
        fontWeight: 'normal',
        lineHeight: 19,
        textAlign: 'center',
      },
      itemsHeaderCardText: {
        width: 148,
        fontFamily: 'Inter',
        fontSize: 16,
        fontWeight: 'normal',
        lineHeight: 19,
        textAlign: 'left',
      },
      itemRow: {
        width: '90%',
        justifyContent: 'space-between',
        borderRadius: 10,
        marginTop: 2,
        backgroundColor: theme.BLUE,
        height: 70,
      },
      itemCardRow: {
        width: '90%',
        alignItems: 'center',
      },
      itemCol: {
        textAlign: 'center',
        fontFamily: theme.FONT_FAMILY_MEDIUM,
        color: theme.FONT_COLOR_WHITE,
        fontSize: 16,
        fontWeight: 'normal',
        lineHeight: 19,
      },
      itemCell: {
        justifyContent: 'center',
      },
      itemsFooterContainer: {
        // flex: 'content',
        width: '90%',
      },
      dataTable: {
        width: '98%',
        alignItems: 'center',
      },
    }),
  ios: theme =>
    StyleSheet.create({
      itemsHeader: {
        flex: 1,
        width: 350,
        height: 42,
        alignItems: 'center',
        justifyContent: 'center',
        borderBottomWidth: 1,
        borderStyle: 'solid',
        borderBottomColor: '#D9D9D9',
        marginTop: 16,
        marginBottom: 4,
        alignSelf: 'center',
        paddingHorizontal: 10,
      },
      itemsHeaderText: {
        width: 72,
        fontFamily: 'Inter',
        fontSize: 12,
        lineHeight: 14,
        textAlign: 'center',
        color: '#000000',
        marginLeft: -5,
      },
      itemRow: {
        width: 350,
        height: 45,
        alignItems: 'center',
        justifyContent: 'center',
        borderWidth: 1,
        borderRadius: 10,
        borderStyle: 'solid',
        borderColor: '#D9D9D9',
        marginTop: 10,
        alignSelf: 'center',
        paddingHorizontal: 10, // Додано для відступів всередині рядка
      },
      itemCol: {
        width: '20.5%',
        textAlign: 'center',
        fontSize: 16,
        fontWeight: '400',
        lineHeight: 16,
        fontFamily: theme.FONT_FAMILY,
        color: theme.FONT_COLOR_WHITE,
        marginHorizontal: 1, // Додано для відступів між колонками
      },
      itemsFooterContainer: {
        flex: 'content',
      },
    }),
};

export default platformStyles[Platform.OS];
