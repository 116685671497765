import * as React from 'react';
import {useEffect, useState, useContext, useCallback} from 'react';
import {StyleSheet, TouchableHighlight, View} from 'react-native';
import {Center, Text, Heading, HStack, Divider} from 'native-base';
import {
  widthPercentageToDP as wp,
  heightPercentageToDP as hp,
} from 'react-native-responsive-screen';
import {AuthContext} from '../../components/AuthProvider';
import {createStackNavigator} from '@react-navigation/stack';
import TriosScreen from './Trios';
import axios from 'axios';

import Header from '../../components/common/AdminHeader';
import Loader from '../../components/common/Loader';
import ErrorModal from '../../components/common/ErrorModal';

import commonStylesF from '../../styles/common';
import adminStyles from '../../styles/admin';

const StatisticsStack = createStackNavigator();

const StatisticsScreen = ({navigation}) => {
  const {user, theme, t, Config} = useContext(AuthContext);
  const [isError, setIsError] = useState(false);
  const [stat, setStat] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  const commonStyles = commonStylesF(wp, hp, theme);

  const getData = useCallback(async () => {
    setIsLoading(true);
    await axios(`${Config.API_URL}/api/v1/admin/stat`, {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        Authorization: user.Authorization,
      },
    })
      .then(response => {
        if (response.status === 200) {
          setStat(response.data.message.data);
        } else if (response.status === 'error') {
          console.log(response);
          setIsError(response.data.message);
        }
      })
      .catch(error => {
        if (error.response) {
          console.log('AdminStat error.response', error.response);
          setIsError(error.response.data.message);
        } else if (error.request) {
          console.log('AdminStat error.request', error.request);
          setIsError(JSON.stringify(error.request));
        } else {
          console.log('AdminStat unknown error', error);
          setIsError(JSON.stringify(error));
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [user, setIsLoading, Config]);

  useEffect(() => {
    console.log('Admin Statistics useEffect');
    getData();
  }, [getData]);

  if (isLoading) {
    return <Loader />;
  } else {
    return (
      <View style={commonStyles.container}>
        <Header navigation={navigation} />
        <View style={commonStyles.contentContainer}>
          <View style={commonStyles.contentHead}>
            <Text style={commonStyles.contentHeadText}>Статистика</Text>
          </View>
          <View style={styles.statContainer}>
            <TouchableHighlight
              onPress={() => navigation.navigate('ActiveUsers')}>
              <Text style={styles.statLabel}>
                Пользователи (Активные / Все)
              </Text>
            </TouchableHighlight>
            <HStack alignItems="center">
              <Center size={16} bg="primary.500" rounded="sm">
                <Text style={styles.statCount}>
                  {stat.users ? stat.users.active : 0}
                </Text>
              </Center>
              <Divider
                bg="emerald.500"
                thickness="2"
                mx="2"
                orientation="vertical"
              />
              <Center size={16} bg="secondary.500" rounded="sm">
                <Text style={styles.statCount}>
                  {stat.users ? stat.users.all : 0}
                </Text>
              </Center>
            </HStack>
          </View>
          <View style={styles.statContainer}>
            <TouchableHighlight onPress={() => navigation.navigate('Trios')}>
              <Text style={styles.statLabel}>
                Тройки (Завершенные/Неполные)
              </Text>
            </TouchableHighlight>
            <HStack alignItems="center">
              <Center size={32} bg="primary.500" rounded="sm">
                <Text style={styles.statCount}>
                  {stat.trios && stat.trios.completed
                    ? stat.trios.completed.total_cnt
                    : 0}
                </Text>
                <HStack>
                  <Center size={16}>
                    <Text>
                      {stat.trios && stat.trios.completed.total_sum
                        ? stat.trios.completed.total_sum
                        : 0}
                    </Text>
                  </Center>
                  <Divider orientation="vertical" />
                  <Center size={16}>
                    <Text>
                      {stat.trios && stat.trios.completed.total_reward
                        ? stat.trios.completed.total_reward
                        : 0}
                    </Text>
                  </Center>
                </HStack>
              </Center>
              <Divider
                bg="emerald.500"
                thickness="2"
                mx="2"
                orientation="vertical"
              />
              <Center size={32} bg="secondary.500" rounded="sm">
                <Text style={styles.statCount}>
                  {stat.trios && stat.trios.waiting
                    ? stat.trios.waiting.total_cnt
                    : 0}
                </Text>
                <HStack>
                  <Center size={16}>
                    <Text>
                      {stat.trios && stat.trios.waiting.total_sum
                        ? stat.trios.waiting.total_sum
                        : 0}
                    </Text>
                  </Center>
                  <Divider orientation="vertical" />
                  <Center size={16}>
                    <Text>
                      {stat.trios && stat.trios.waiting.total_reward
                        ? stat.trios.waiting.total_reward
                        : 0}
                    </Text>
                  </Center>
                </HStack>
              </Center>
            </HStack>
          </View>
          {/* <Spacer/> */}
          <View style={styles.statContainer}>
            <TouchableHighlight
              onPress={() => navigation.navigate('Transactions')}>
              <Text style={styles.statLabel}>
                Операции (Начислено / Выплачено)
              </Text>
            </TouchableHighlight>
            <HStack alignItems="center">
              <Center size={16} bg="primary.500" rounded="sm">
                <Text style={styles.statCount}>
                  {stat.transactions ? stat.transactions.sum_reward : 0}
                </Text>
              </Center>
              <Divider
                bg="emerald.500"
                thickness="2"
                mx="2"
                orientation="vertical"
              />
              <Center size={16} bg="secondary.500" rounded="sm">
                <Text style={styles.statCount}>
                  {stat.transactions ? stat.transactions.sum_paid : 0}
                </Text>
              </Center>
            </HStack>
          </View>
        </View>
        {isError && <ErrorModal error={isError} setIsError={setIsError} />}
      </View>
    );
  }
};

const TransactionsScreen = () => {
  return (
    <Center>
      <Heading>
        <Text color="emerald.500">Транзакции</Text>
      </Heading>
    </Center>
  );
};

const AdminStatistics = () => {
  return (
    <StatisticsStack.Navigator
      screenOptions={{
        headerShown: false,
      }}>
      <StatisticsStack.Screen
        name="Statistics"
        component={StatisticsScreen}
        options={{title: 'Статистика', headerShown: false}}
      />
      <StatisticsStack.Screen
        name="Trios"
        component={TriosScreen}
        options={{title: 'Тройки'}}
      />
      <StatisticsStack.Screen
        name="Transactions"
        component={TransactionsScreen}
        options={{title: 'Транзакции'}}
      />
    </StatisticsStack.Navigator>
  );
};

const styles = StyleSheet.create({
  statsContainer: {
    flexDirection: 'column',
    marginTop: 0,
    marginBottom: 20,
    // height: 800,
  },
  statContainer: {
    alignItems: 'center',
    flex: 1,
    marginTop: 10,
    marginBottom: 10,
  },
  statCount: {
    fontSize: 20,
    fontWeight: 'bold',
  },
  statLabel: {
    fontSize: 16,
    color: '#999',
  },
  subStatContainer: {
    // width: '20%',
    flexDirection: 'row',
  },
});

export default AdminStatistics;
