import {Dimensions, Platform, StyleSheet} from 'react-native';

const platformStyles = {
  web: (wp, hp, theme) =>
    StyleSheet.create({
      headerLogo:{
        width: 208,
        height: 98,
        marginTop: 120,
      },
      logoContainer: {
        marginTop: -95,
      },
      firstPageLogo: {
        width: 203,
        height: 95,
        marginLeft: -25,
      },
      logoTextTrio: {
        fontFamily: theme.FONT_FAMILY_BOLD,
        color: theme.FONT_COLOR_BLUE,
        fontSize: 54,
        marginTop: -5,
        // marginLeft: 10,
        marginBottom: 20,
      },
      logoTextBottom: {
        fontFamily: theme.FONT_FAMILY_MEDIUM,
        fontSize: 15,
        lineHeight: 15,
        color: theme.FONT_COLOR_BLACK,
        // marginLeft: 10,
      },
      btnGroup: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: 20,
        gap: 10,
      },
      btnCommon: {
        width: 99,
        height: 27,
        backgroundColor: theme.BLACK,
        borderRadius: 10,
        alignItems: 'center',
        justifyContent: 'center',
      },
      btnCommonTxtSmall: {
        fontFamily: theme.FONT_FAMILY_MEDIUM,
        color: theme.FONT_COLOR_WHITE,
        fontSize: 12,
        lineHeight: 12,
      },
      block1: {
        flexDirection: 'row',
        marginTop: 40,
        width: '100%',
        maxWidth: '100%',
        justifyContent: 'space-between',
      },
      block1Text: {
        marginTop: 130,
        paddingLeft: 24,
      },
      block1TxtBlack: {
        fontFamily: theme.FONT_FAMILY_MEDIUM,
        color: theme.FONT_COLOR_BLACK,
        fontSize: 28,
        lineHeight: 26,
      },
      block1TxtLightBlue: {

      },
      block1Img: {
        width: 244,
        height: 394,
        marginLeft: -140,
      },
      block2: {
        flexDirection: 'row',
        width: '100%',
        maxWidth: '100%',
        paddingHorizontal: '3%',
        // justifyContent: 'space-between',
      },
      block2Left: {
        width: '50%',
        alignItems: 'center',
        gap: 20,
        marginTop: -100,
      },
      block2BoxBlue: {
        width: 146,
        height: 178,
        backgroundColor: theme.BLUE,
        borderRadius: 10,
        paddingHorizontal: 10,
        paddingVertical: 15,
      },
      block2TextHead:{
        fontFamily: theme.FONT_FAMILY_BOLD,
        color: theme.FONT_COLOR_WHITE,
        fontSize: 10,
        lineHeight: 10,
      },
      block2TextContent: {
        fontFamily: theme.FONT_FAMILY,
        color: theme.FONT_COLOR_WHITE,
        fontSize: 10,
        lineHeight: 10,
        marginTop: 10,
      },
      block2Right: {
        width: '50%',
        alignItems: 'center',
        gap: 20,
      },
      block2BoxBlack:{
        width: 146,
        height: 178,
        backgroundColor: theme.BLACK,
        borderRadius: 10,
        paddingHorizontal: 10,
        paddingVertical: 15,
      },
      block3: {
        width: '100%',
        paddingLeft: 20,
        marginTop: 5,
      },
      block3Img: {
        width: 156,
        height: 72,
        marginTop: -78,
        marginLeft: -5,
      },
      block3Txt1: {
        fontFamily: theme.FONT_FAMILY_MEDIUM,
        color: theme.FONT_COLOR_BLACK,
        fontSize: 28,
        lineHeight: 27,
      },
      block4: {
        width: '100%',
        marginTop: 50,
      },
      block4TextContainer: {
        alignItems: 'flex-end',
        paddingRight: 20,
      },
      block4Text: {
        fontFamily: theme.FONT_FAMILY_MEDIUM,
        color: theme.FONT_COLOR_BLACK,
        fontSize: 20,
        lineHeight: 19,
        textAlign: 'right',
      },
      block4Text1: {
        fontFamily: theme.FONT_FAMILY_MEDIUM,
        color: theme.FONT_COLOR_BLACK,
        fontSize: 28,
        lineHeight: 28,
      },
      block4Part2: {
        width: '100%',
        flexDirection: 'row',
        justifyContent: 'space-between',
      },
      ringsLeft: {
        marginTop: -80,
      },
      left_ring_1: {
        width: 46,
        height: 70,
      },
      left_ring_2: {
        width: 110,
        height: 124,
        marginTop: -176,
      },
      left_ring_3: {
        width: 74,
        height: 116,
        marginTop: 10,
      },
      trioArea: {
        width: 201,
        height: 56,
        backgroundColor: theme.BLUE,
        borderRadius: 10,
        paddingHorizontal: 20,
        paddingVertical: 10,
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: 45,
        marginLeft: -30,
      },
      trioAreaTxt: {
        fontFamily: theme.FONT_FAMILY_MEDIUM,
        color: theme.FONT_COLOR_WHITE,
        fontSize: 24,
        lineHeight: 23,
      },
      ringsRight: {
        marginTop: 10,
        marginLeft: -50,
      },
      right_ring_1: {
        width: 50,
        height: 115,
        alignSelf: 'flex-end',
      },
      right_ring_2: {
        width: 128,
        height: 125,
        marginTop: -30,
      },
      block4Part3: {
        paddingLeft: 20,
        marginTop: -70,
      },
      block5: {
        width: '100%',
        marginTop: 130,
        alignItems: 'center',
        justifyContent: 'center',
        marginBottom: 130,
      },
      block5TextContainer: {
        width: '80%',
        flexWrap: 'wrap',
      },
      block5Text: {
        fontFamily: theme.FONT_FAMILY_MEDIUM,
        color: theme.FONT_COLOR_BLACK,
        fontSize: 24,
        lineHeight: 24,
        textAlign: 'center',
      },
      block5BtnGroup: {
        marginTop: 30,
        alignItems: 'center',
        justifyContent: 'center',
        gap: 10,
        flexDirection: 'row',
      },
      btnBlack: {
        width: 130,
        height: 35,
        backgroundColor: theme.BLACK,
        borderRadius: 10,
        alignItems: 'center',
        justifyContent: 'center',
      },
      btnBlackTxt: {
        fontFamily: theme.FONT_FAMILY_MEDIUM,
        fontSize: 17,
        color: theme.FONT_COLOR_WHITE,
      }
    }),
  android: (wp, hp, theme) =>
    StyleSheet.create({
      headerLogo:{
        width: 208,
        height: 98,
        marginTop: 120,
      },
      logoContainer: {
        marginTop: -95,
      },
      firstPageLogo: {
        width: 203,
        height: 95,
        marginLeft: -25,
      },
      logoTextTrio: {
        fontFamily: theme.FONT_FAMILY_BOLD,
        color: theme.FONT_COLOR_BLUE,
        fontSize: 54,
        marginTop: -15,
        // marginLeft: 10,
        marginBottom: 20,
      },
      logoTextBottom: {
        fontFamily: theme.FONT_FAMILY_MEDIUM,
        fontSize: 15,
        lineHeight: 15,
        color: theme.FONT_COLOR_BLACK,
        // marginLeft: 10,
      },
      btnGroup: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: 20,
        gap: 10,
      },
      btnCommon: {
        width: 99,
        height: 27,
        backgroundColor: theme.BLACK,
        borderRadius: 10,
        alignItems: 'center',
        justifyContent: 'center',
      },
      btnCommonTxtSmall: {
        fontFamily: theme.FONT_FAMILY_MEDIUM,
        color: theme.FONT_COLOR_WHITE,
        fontSize: 12,
        lineHeight: 12,
      },
      block1: {
        flexDirection: 'row',
        marginTop: 40,
        width: '100%',
        maxWidth: '100%',
        justifyContent: 'space-between',
      },
      block1Text: {
        marginTop: 130,
        paddingLeft: 24,
      },
      block1TxtBlack: {
        fontFamily: theme.FONT_FAMILY_MEDIUM,
        color: theme.FONT_COLOR_BLACK,
        fontSize: 28,
        lineHeight: 26,
      },
      block1TxtLightBlue: {

      },
      block1Img: {
        width: 244,
        height: 394,
        marginLeft: -80,
      },
      block2: {
        flexDirection: 'row',
        width: '100%',
        maxWidth: '100%',
        paddingHorizontal: '3%',
        // justifyContent: 'space-between',
      },
      block2Left: {
        width: '50%',
        alignItems: 'center',
        gap: 20,
        marginTop: -100,
      },
      block2BoxBlue: {
        width: 146,
        height: 178,
        backgroundColor: theme.BLUE,
        borderRadius: 10,
        paddingHorizontal: 10,
        paddingVertical: 15,
      },
      block2TextHead:{
        fontFamily: theme.FONT_FAMILY_BOLD,
        color: theme.FONT_COLOR_WHITE,
        fontSize: 10,
        lineHeight: 10,
      },
      block2TextContent: {
        fontFamily: theme.FONT_FAMILY,
        color: theme.FONT_COLOR_WHITE,
        fontSize: 10,
        lineHeight: 10,
        marginTop: 10,
      },
      block2Right: {
        width: '50%',
        alignItems: 'center',
        gap: 20,
      },
      block2BoxBlack:{
        width: 146,
        height: 178,
        backgroundColor: theme.BLACK,
        borderRadius: 10,
        paddingHorizontal: 10,
        paddingVertical: 15,
      },
      block3: {
        width: '100%',
        paddingLeft: 20,
        marginTop: 5,
      },
      block3Img: {
        width: 156,
        height: 72,
        marginTop: -78,
        marginLeft: -5,
      },
      block3Txt1: {
        fontFamily: theme.FONT_FAMILY_MEDIUM,
        color: theme.FONT_COLOR_BLACK,
        fontSize: 28,
        lineHeight: 27,
      },
      block4: {
        width: '100%',
        marginTop: 50,
      },
      block4TextContainer: {
        alignItems: 'flex-end',
        paddingRight: 20,
      },
      block4Text: {
        fontFamily: theme.FONT_FAMILY_MEDIUM,
        color: theme.FONT_COLOR_BLACK,
        fontSize: 20,
        lineHeight: 19,
        textAlign: 'right',
      },
      block4Text1: {
        fontFamily: theme.FONT_FAMILY_MEDIUM,
        color: theme.FONT_COLOR_BLACK,
        fontSize: 28,
        lineHeight: 28,
      },
      block4Part2: {
        width: '100%',
        flexDirection: 'row',
        justifyContent: 'space-between',
      },
      ringsLeft: {
        marginTop: -80,
      },
      left_ring_1: {
        width: 46,
        height: 70,
      },
      left_ring_2: {
        width: 110,
        height: 124,
        marginTop: -176,
      },
      left_ring_3: {
        width: 74,
        height: 116,
        marginTop: 10,
      },
      trioArea: {
        width: 205,
        height: 56,
        backgroundColor: theme.BLUE,
        borderRadius: 10,
        paddingHorizontal: 10,
        paddingVertical: 5,
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: 45,
        marginLeft: -30,
      },
      trioAreaTxt: {
        fontFamily: theme.FONT_FAMILY_MEDIUM,
        color: theme.FONT_COLOR_WHITE,
        fontSize: 24,
        lineHeight: 23,
      },
      ringsRight: {
        marginTop: 10,
        marginLeft: -50,
      },
      right_ring_1: {
        width: 50,
        height: 115,
        alignSelf: 'flex-end',
      },
      right_ring_2: {
        width: 128,
        height: 125,
        marginTop: -30,
      },
      block4Part3: {
        paddingLeft: 20,
        marginTop: -70,
      },
      block5: {
        width: '100%',
        marginTop: 130,
        alignItems: 'center',
        justifyContent: 'center',
        marginBottom: 130,
      },
      block5TextContainer: {
        width: wp('80%'),
        // maxWidth: '80%',
        // flexWrap: 'wrap',
      },
      block5Text: {
        fontFamily: theme.FONT_FAMILY_MEDIUM,
        color: theme.FONT_COLOR_BLACK,
        fontSize: 24,
        lineHeight: 24,
        textAlign: 'center',
        width: wp('80%'),
      },
      block5BtnGroup: {
        marginTop: 30,
        alignItems: 'center',
        justifyContent: 'center',
        gap: 10,
        flexDirection: 'row',
      },
      btnBlack: {
        width: 130,
        height: 35,
        backgroundColor: theme.BLACK,
        borderRadius: 10,
        alignItems: 'center',
        justifyContent: 'center',
      },
      btnBlackTxt: {
        fontFamily: theme.FONT_FAMILY_MEDIUM,
        fontSize: 17,
        color: theme.FONT_COLOR_WHITE,
      }
    }),
  ios: (wp, hp, theme) =>
  StyleSheet.create({

  })
}

export default platformStyles[Platform.OS];