import React, {useState, useContext} from 'react';
import {Share, Alert, Pressable, Platform, View} from 'react-native';
import {
  InstapaperShareButton,
  FacebookShareButton,
  TelegramShareButton,
  EmailShareButton,
  InstapaperIcon,
  FacebookIcon,
  EmailIcon,
  TelegramIcon,
} from 'react-share';
import Ionicons from 'react-native-vector-icons/Ionicons';
import Icon from 'react-native-vector-icons/FontAwesome';

import { AuthContext } from '../AuthProvider';
import FormModal from './FormModal';
import styles from '../../styles/header_mobile';

const ShareComponent = ({url}) => {
  const [shareUrl, setShareUrl] = useState(url);
  const [title, setTitle] = useState('PDParis');
  const [description, setDescription] = useState('');
  const [modalVisible, setModalVisible] = useState(false);
  const [currentResolve, setCurrentResolve] = useState(null);

  const openModal = () => {
    setModalVisible(true);
  };

  const closeModal = () => {
    setModalVisible(false);
  };

  const handleSubmit = formData => {
    setTitle(formData.subject);
    setDescription(formData.message);
    if (currentResolve) {
      currentResolve(true); // Разрешаем Promise для продолжения шеринга
      setCurrentResolve(null);
    }
    closeModal();
  };

  const handleBeforeOnClick = () => {
    openModal();
    return new Promise(resolve => {
      setCurrentResolve(() => resolve);
    });
  };

  return (
    <div>
      <FormModal
        visible={modalVisible}
        onClose={closeModal}
        onSubmit={handleSubmit}
        sendVia={'messenger'}
        msgType={'invite'}
      />
      <TelegramShareButton
        url={shareUrl}
        title={description}
        beforeOnClick={handleBeforeOnClick}>
        <TelegramIcon size={32} round />
      </TelegramShareButton>
      <EmailShareButton
        url={shareUrl}
        subject={title}
        body={description}
        beforeOnClick={handleBeforeOnClick}>
        <EmailIcon size={32} round />
      </EmailShareButton>
      <FacebookShareButton
        url={shareUrl}
        title={title}
        quote={title}
        beforeOnClick={handleBeforeOnClick}>
        <FacebookIcon size={32} round />
      </FacebookShareButton>
      <InstapaperShareButton
        url={shareUrl}
        title={title}
        description={description}
        beforeOnClick={handleBeforeOnClick}>
        <InstapaperIcon size={32} round />
      </InstapaperShareButton>
    </div>
  );
};

const ShareWithChoice = ({url}) => {
  const {theme} = useContext(AuthContext);
  const [modalVisible, setModalVisible] = useState(false);

  const closeModal = () => {
    setModalVisible(false);
  };

  const openModal = () => {
    setModalVisible(true);
  }

  const handleSubmit = (formData) => {
    onShare(url, formData.message, formData.subject);
    closeModal();
  }

  return (
    <>
      <Pressable
        onPress={() => {
          openModal();
        }}>
        {Platform.OS === 'ios' ? (
          <Ionicons name="share" size={25} color="#007AFF" />
        ) : (
          <Icon
            name="share"
            size={30}
            color={theme.FONT_COLOR_BLACK}
          />
        )}
      </Pressable>
      <FormModal
        visible={modalVisible}
        onClose={closeModal}
        onSubmit={handleSubmit}
        sendVia={'messenger'}
        msgType={'invite'}
      />
    </>
  );
}

const onShare = async (url, message, title) => {
  let _message = `${message} ${url}`;
  try {
    const result = await Share.share({
      message: _message,
      title: title,
    });

    if (result.action === Share.sharedAction) {
      if (result.activityType) {
        // поделиться с действием
        console.log('поделиться с действием');
      } else {
        // поделиться без дополнительных действий
        console.log('поделиться без дополнительных действий');
      }
    } else if (result.action === Share.dismissedAction) {
      // закрытие диалогового окна
      console.log('закрытие диалогового окна');
    }
  } catch (error) {
    Alert.alert(error.message);
  }
};

const validateEmail = email => {
  const re = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
  return re.test(String(email).toLowerCase());
};

const replaceWithAsterisks = str => {
  if (!str) {
    return '***';
  }

  if (str.length <= 4) {
    return str;
  }

  var lastFour = str.slice(-4);
  var masked = str.slice(0, -4).replace(/./g, '*');
  return masked + lastFour;
};

const replaceNameWithAsterics = fullName => {
  if (!fullName) {
    return '***';
  }
  // Split the name into parts
  const parts = fullName.trim().split(/\s+/);

  // If there's only one part, we assume it's the surname and mask the entire part
  if (parts.length === 1) {
    return '***';
  }

  // Mask the first part (surname)
  const maskedSurname = '***';

  // Keep the initials and/or name parts as is
  const initialsOrNames = parts.slice(1).join(' ');

  return `${maskedSurname} ${initialsOrNames}`;
};

const replaceEmailWithAsterics = email => {
  if (!email) {
    return '***';
  }
  const atIndex = email.indexOf('@');
  if (atIndex === -1) return email; // Return the email as is if it doesn't contain '@'

  const userPart = email.substring(0, atIndex);
  const domainPart = email.substring(atIndex + 1);
  const domainDotIndex = domainPart.lastIndexOf('.');

  // Edge case handling: if there's no dot in the domain part, or the user/domain part is too short
  if (domainDotIndex === -1 || userPart.length < 2) return email;

  // Construct the masked parts
  const maskedUser = userPart.substring(0, 2) + '***';
  const maskedDomain = '***' + domainPart.substring(domainDotIndex);

  return maskedUser + '@' + maskedDomain;
};

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

const nameToFio = fullName => {
  let initials = '';
  let lastName = '';

  if (!fullName) {
    return '***';
  }
  // Разделяем полное имя на части
  const parts = fullName.trim().split(' ');

  // Получаем фамилию
  if (parts[0]) {
    lastName = parts[0];
  }
  // Получаем инициалы имени
  if (parts[1]) {
    initials += parts[1].charAt(0).toUpperCase() + '.';
  }
  // Получаем инициалы отчества
  if (parts[2]) {
    initials += parts[2].charAt(0).toUpperCase() + '.';
  }

  // Возвращаем строку с фамилией и инициалами
  return `${lastName} ${initials}`;
};

const formInitials = name => {
  if (!name) {
    return '';
  }
  let tmp = name.split(' ');
  if (tmp.length >= 2) {
    return tmp[0].substring(0, 1) + tmp[1].substring(0, 1);
  } else {
    return tmp[0].substring(0, 1);
  }
};

const formatPhoneNumber = phoneNumber => {
  if (!phoneNumber) {
    return '***';
  }
  // Убедитесь, что номер телефона - это строка, чтобы использовать методы строк
  const phoneString = phoneNumber.toString().replace(/\D/g, '');

  // Используйте шаблонную строку для форматирования
  return `+${phoneString.slice(0, 2)} (${phoneString.slice(
    2,
    5
  )}) ${phoneString.slice(5, 8)}-${phoneString.slice(
    8,
    10
  )}-${phoneString.slice(10, 12)}`;
};

export {
  validateEmail,
  replaceWithAsterisks,
  replaceEmailWithAsterics,
  replaceNameWithAsterics,
  onShare,
  ShareComponent,
  capitalizeFirstLetter,
  nameToFio,
  formInitials,
  formatPhoneNumber,
  ShareWithChoice,
};
