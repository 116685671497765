import axios from 'axios';
// import Config from '../../config/config.js.sample/index.js';
import Config from '../../config/config.js';

const AxiosInstance = globalContext => {
  const instance = axios.create({
    baseURL: `${Config.API_URL}`, // Замените на свой базовый URL
    timeout: 10000, // Настройте таймаут, если необходимо
    headers: {
      'Content-Type': 'application/json',
      Authorization: globalContext.user.Authorization,
    },
  });

  // Глобальный обработчик успешных ответов
  instance.interceptors.response.use(
    response => {
      // console.log('AXIOS INSTANCE RESPONSE');
      return response;
    },
    error => {
      console.log('AXIOS INSTANCE ERROR', error);
      if (error.response) {
        console.log('Axios error response', error.response);

        if (error.response.status === 401) {
          globalContext.logout();
          return Promise.reject(error.response.data.message);
        } else if (error.response.status === 403) {
          return Promise.reject(error.response.data);
        }

        if (error.response.status < 500) {
          return Promise.reject(error.response.data.message);
        } else {
          return Promise.reject('server error');
        }

      } else {
        // return Promise.reject(error.message);
        return Promise.reject('server error');
      }
    }
  );

  return instance;
};

export default AxiosInstance;
