import * as React from 'react';
import {Platform} from 'react-native';
import {NativeBaseProvider} from 'native-base';
import {PaperProvider} from 'react-native-paper';
import 'intl-pluralrules';
import {AuthProvider} from './components/AuthProvider';
import AppNavigator from './components/navigation/AppNavigator';

const App = () => {
  return (
    <AuthProvider>
      <NativeBaseProvider>
        <PaperProvider>
          <React.Fragment>
            {Platform.OS === 'web' ? (
              <style type="text/css">{`
                @font-face {
                  font-family: 'MaterialCommunityIcons';
                  src: url(${require('react-native-vector-icons/Fonts/MaterialCommunityIcons.ttf')}) format('truetype');
                }
                @font-face {
                  font-family: 'Mariupol';
                  src: local('Mariupol'), url(${require('./assets/fonts/Mariupol-Regular.ttf')}) format('truetype');
                  font-weight: normal;
                  font-style: normal;
                }
                @font-face {
                  font-family: 'Mariupol-Medium';
                  src: local('Mariupol-Medium'), url(${require('./assets/fonts/Mariupol-Medium.ttf')}) format('truetype');
                  font-weight: normal;
                  font-style: normal;
                }
                @font-face {
                  font-family: 'Mariupol-Bold';
                  src: local('Mariupol-Bold'), url(${require('./assets/fonts/Mariupol-Bold.ttf')}) format('truetype');
                  font-weight: normal;
                  font-style: normal;
                }
              `}</style>
            ) : null}
            <AppNavigator />
          </React.Fragment>
        </PaperProvider>
      </NativeBaseProvider>
    </AuthProvider>
  );
};

export default App;
