import React, {useContext} from 'react';
import {View, Text, StyleSheet, Pressable} from 'react-native';
import { AuthContext } from '../AuthProvider';
import theme from '../../config/theme_config';

const ErrorScreen = () => {
  const {logout, t} = useContext(AuthContext);
  return (
    <View style={styles.container}>
      <Text style={styles.text}>{t('403_error')}</Text>
      <Pressable
        onPress={() => logout()}
        style={styles.logoutBtn}
      >
        <Text style={styles.btnText}>{t('go_to_main')}</Text>
      </Pressable>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  text: {
    fontFamily: theme.FONT_FAMILY_BOLD,
    fontWeight: 'bold',
  },
  logoutBtn: {
    alignItems: 'center',
    justifyContent: 'center',
    width: 150,
    height: 50,
    borderRadius: 10,
    backgroundColor: theme.LIGHT_BLUE,
  },
  btnText: {
    fontFamily: theme.FONT_FAMILY_MEDIUM,
    color: theme.FONT_COLOR_WHITE,
    fontSize: 16,
  }
});

export default ErrorScreen;
