import * as React from 'react';
import {useContext} from 'react';
import {AuthContext} from '../components/AuthProvider';

import AdminScreen from './AdminScreen';
import ProfileView from './Profile/ProfileScreen';

const PrivateScreen = () => {
  const {user} = useContext(AuthContext);
  let isAdmin = user && user.role === 0;

  if (isAdmin) {
    return <AdminScreen />;
  } else {
    return <ProfileView />;
  }
};

export default PrivateScreen;
