import { StyleSheet } from "react-native";

const styles = (wp, theme) => StyleSheet.create({
  contentHead: {
    marginTop: 62,
    alignItems: 'center',
    backgroundColor: theme.BLUE,
    borderRadius: 10,
    padding: 10,
    marginBottom: 20,
    maxWidth: wp('85%'),
  },
  content: {
    flex: 1,
    maxWidth: 1080,
    marginTop: 28,
    alignSelf: 'center',
    alignItems: 'center',
    // height: 1000,
  },
  phoneInput: {
    // flex: 1,
    fontFamily: theme.FONT_FAMILY,
    width: 251,
    height: 42,
    padding: 10,
    borderRadius: 10,
    borderWidth: 2,
    gap: 10,
    borderColor: theme.BLACK,
    fontSize: 20,
    lineHeight: 20,
    textAlign: 'left',
    alignItems: 'center',
  },
  label: {
    fontFamily: theme.FONT_FAMILY_MEDIUM,
    fontSize: 12,
    lineHeight: 15,
    textAlign: 'left',
    color: theme.FONT_COLOR_BLUE,
  },
  labelBlack: {
    fontFamily: theme.FONT_FAMILY,
    fontSize: 12,
    lineHeight: 15,
    textAlign: 'left',
    color: theme.FONT_COLOR_BLACK,
  },
  btnGroup: {
    gap: 10,
  },
  buttonBig: {
    marginTop: 18,
    width: 250,
    height: 40,
    paddingVertical: 10,
    paddingHorizontal: 20,
    borderRadius: 10,
    gap: 10,
    backgroundColor: theme.BLUE,
    alignItems: 'center',
    justifyContent: 'center',
  },
  buttonSmall: {
    marginTop: 18,
    width: 120,
    height: 40,
    paddingVertical: 10,
    paddingHorizontal: 15,
    borderRadius: 10,
    gap: 10,
    backgroundColor: theme.BLUE,
    alignItems: 'center',
    justifyContent: 'center',
  },
  buttonText: {
    fontFamily: theme.FONT_FAMILY_MEDIUM,
    color: theme.FONT_COLOR_WHITE,
    fontSize: 16,
    lineHeight: 19,
    textAlign: 'center',
  },
  infoText: {
    paddingBottom: 20,
    paddingHorizontal: 20,
    fontFamily: theme.FONT_FAMILY_MEDIUM,
    fontSize: 14,
    lineHeight: 16,
    textAlign: 'center',
  },
  eyeShow: {
    width: 20,
    height: 10,
    alignItems: 'center',
    justifyContent: 'center',
    // marginLeft: -50,
  },
  eyeHide: {
    width: 21,
    height: 15,
    alignItems: 'center',
    justifyContent: 'center',
    // marginLeft: -40,
  },
  eye: {
    width: 30,
    height: 30,
    alignItems: 'center',
    justifyContent: 'center',
    marginLeft: -30,
    paddingRight: 10,
  },
  inputContainer: {
    alignItems: 'center',
  },
  forgotPassword: {
    // height: 48,
    // justifyContent: 'flex-end',
  },
  forgotPasswordText: {
    fontFamily: theme.FONT_FAMILY,
    color: theme.FONT_COLOR_LIGHT_BLUE,
    fontSize: 14,
    lineHeight: 15,
    textDecorationLine: 'underline',
  },
  passwordLabel: {
    width: 240,
    height: 20,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  iosContent: {
    flex: 1,
    maxWidth: 1080,
    marginTop: 28,
    marginBottom: 100,
    alignSelf: 'center',
    alignItems: 'center',
    // backgroundColor: 'red',

    // height: 1000,
  },
  iosProfileContainer: {
    marginHorizontal: 40,
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '15%',
    // marginBottom: 100,
    height: '100%',
    // backgroundColor: 'red',
    // marginBottom: 100,
  },
});

export default styles;