import {Platform} from 'react-native';

let ImagePicker = null;

if (
  process.env.REACT_APP_PLATFORM &&
  process.env.REACT_APP_PLATFORM === 'web'
) {
  ImagePicker = require('./ImagePicker.web');
} else if (Platform.OS === 'android') {
  ImagePicker = require('./ImagePicker.android');
} else if (Platform.OS === 'ios') {
  ImagePicker = require('./ImagePicker.ios');
}

export default ImagePicker.default;
