import React, {useContext, useEffect, useState} from 'react';
import {
  Text,
  View,
  ScrollView,
  Dimensions,
  Image,
  ImageBackground,
  Pressable,
  Platform,
} from 'react-native';
import {
  widthPercentageToDP as wp,
  heightPercentageToDP as hp,
} from 'react-native-responsive-screen';

import {AuthContext} from '../../components/AuthProvider';

import LoginHeaderLogo from '../../assets/img/LoginHeaderLogo_mobile.svg';
import Rectangles from '../../assets/img/rectangles.svg';
import Percents from '../../assets/img/50_percents_mobile.svg';
import Share from '../../assets/img/share_mobile.svg';
import Ellipse from '../../assets/img/ellipse_mobile.svg';
import LeftRing1 from '../../assets/img/left_ring_1_mobile.svg';
import LeftRing2 from '../../assets/img/left_ring_2_mobile.svg';
import LeftRing3 from '../../assets/img/left_ring_3_mobile.svg';
import RightRing1 from '../../assets/img/right_ring_1_mobile.svg';
import RightRing2 from '../../assets/img/right_ring_2_mobile.svg';

import Header from '../../components/common/Header';
import Footer from '../../components/common/Footer';

import {default as commonStylesF} from '../../styles/common';
import {default as firstPageStylesF} from '../../styles/firstPage_mobile';

const FirstPage = ({navigation, route}) => {
  const {t, theme, screenWidth} = useContext(AuthContext);

  let commonStyles = commonStylesF(wp, hp, theme);
  let firstPageStyles = firstPageStylesF(wp, hp, theme);

  return (
    <ScrollView contentContainerStyle={commonStyles.container}>
      <Header navigation={false} route={false} />
      <View style={commonStyles.contentContainer}>
        {/* Заголовок */}
        <View style={firstPageStyles.headerLogo}>
          {Platform.OS === 'web' ? (
          <Image
            style={firstPageStyles.firstPageLogo}
            source={LoginHeaderLogo}
          />
          ) : (
            <LoginHeaderLogo style={firstPageStyles.firstPageLogo}/>
          )}
          <View style={firstPageStyles.logoContainer}>
            <Text style={firstPageStyles.logoTextTrio}>{t('trio')}</Text>
            <Text style={firstPageStyles.logoTextBottom}>
              {t('partner_program')}
            </Text>
            <View style={firstPageStyles.btnGroup}>
              <Pressable
                style={firstPageStyles.btnCommon}
                onPress={() => navigation.navigate('Login')}
              >
                <Text style={firstPageStyles.btnCommonTxtSmall}>
                  {t('registration')}
                </Text>
              </Pressable>
              <Pressable
                style={firstPageStyles.btnCommon}
                onPress={() => navigation.navigate('Login')}
                >
                <Text style={firstPageStyles.btnCommonTxtSmall}>{t('login')}</Text>
              </Pressable>
            </View>
          </View>
        </View>
        {/* Конец заголовок */}
        {/* Block1 */}
        <View style={firstPageStyles.block1}>
          <View style={firstPageStyles.block1Text}>
            <Text style={firstPageStyles.block1TxtBlack}>{t('why')} </Text>
            <Text style={firstPageStyles.block1TxtBlack}>{t('should')} </Text>
            <Text style={firstPageStyles.block1TxtBlack}>{t('join')} </Text>
            <Text style={firstPageStyles.block1TxtBlack}>
              <Text style={firstPageStyles.block1TxtBlack}>{t('to')} </Text>
              <Text style={{color: theme.FONT_COLOR_BLUE}}>{t('to_trio')}</Text>
              <Text style={firstPageStyles.block1TxtBlack}>?</Text>
            </Text>
          </View>
          {Platform.OS === 'web' ? (
          <Image
            style={firstPageStyles.block1Img}
            source={Rectangles}
          />) : (
            <Rectangles style={firstPageStyles.block1Img} />
          )}
        </View>
        {/* End Block1 */}
        {/* Block2 */}
        <View style={firstPageStyles.block2}>
          <View style={firstPageStyles.block2Left}>
            <View style={firstPageStyles.block2BoxBlue}>
              <Text style={firstPageStyles.block2TextHead}>
                {t('quick_earnings')}
              </Text>
              <Text style={firstPageStyles.block2TextContent}>
                {t('quick_earnings_text1')}
              </Text>
              <Text style={firstPageStyles.block2TextContent}>
                {t('quick_earnings_text2')}
              </Text>
            </View>
            <View style={firstPageStyles.block2BoxBlue}>
              <Text style={firstPageStyles.block2TextHead}>
                {t('automatic_registration')}
              </Text>
              <Text style={firstPageStyles.block2TextContent}>
                {t('automatic_registration_text1')}
              </Text>
              <Text style={firstPageStyles.block2TextContent}>
                {t('automatic_registration_text2')}
              </Text>
            </View>
          </View>
          <View style={firstPageStyles.block2Right}>
            <View style={firstPageStyles.block2BoxBlack}>
              <Text style={firstPageStyles.block2TextHead}>
                {t('no_limits')}
              </Text>
              <Text style={firstPageStyles.block2TextContent}>
                {t('no_limits_text')}
              </Text>
              {Platform.OS === 'web' ? (
                <Image
                  style={{
                    width: 127,
                    height: 84,
                    position: 'absolute',
                    top: 90,
                  }}
                  source={Percents}
                />
              ) : (
                <Percents
                  style={{
                    width: 127,
                    height: 84,
                    position: 'absolute',
                    top: 90,
                    marginLeft: 15,
                  }}
                />
              )}
            </View>
            <View style={firstPageStyles.block2BoxBlack}>
              <Text style={firstPageStyles.block2TextHead}>
                {t('lightness_and_simplicity')}
              </Text>
              <Text style={firstPageStyles.block2TextContent}>
                {t('lightness_and_simplicity_text')}
              </Text>
              {Platform.OS === 'web' ? (
                <Image
                  style={{
                    width: 110,
                    height: 106,
                    position: 'absolute',
                    top: 75,
                  }}
                  source={Share}
                />
              ) : (
                <Share
                  style={{
                    width: 110,
                    height: 106,
                    position: 'absolute',
                    top: 75,
                    marginLeft: 15
                  }}
                />
              )}
            </View>
          </View>
        </View>
        {/**Block 3 */}
        <View style={firstPageStyles.block3}>
          <Text style={firstPageStyles.block3Txt1}>{t('what_is')} </Text>
          <Text style={firstPageStyles.block3Txt1}>«<Text style={{color: theme.FONT_COLOR_BLUE}}>{t('trio')}</Text>»?</Text>
          <Text style={firstPageStyles.block3Txt1}>{t('lets_calc')}</Text>
          {Platform.OS === 'web' ? (
          <Image
            style={firstPageStyles.block3Img}
            source={Ellipse}
          />
          ) : (
            <Ellipse style={firstPageStyles.block3Img} />
          )}
        </View>
        {/** Block 4*/}
        <View style={firstPageStyles.block4}>
          <View style={firstPageStyles.block4TextContainer}>
            <Text style={firstPageStyles.block4Text}>{t('suggest')} <Text style={{color: theme.FONT_COLOR_BLUE}}>{t('to_one')}</Text> {t('to_friend')}</Text>
            <Text style={firstPageStyles.block4Text}>{t('purchase_our_goods')}</Text>
            <Text style={{...firstPageStyles.block4Text, marginTop: 20}}>{t('then_again')} <Text style={{color: theme.FONT_COLOR_BLUE}}>{t('to_one')}</Text>.</Text>
            <Text style={firstPageStyles.block4Text}>{t('and_again')} <Text style={{color: theme.FONT_COLOR_BLUE}}>{t('to_one')}</Text>.</Text>
          </View>
          <View style={firstPageStyles.block4Part2}>
          {Platform.OS === 'web' ? (
            <View style={firstPageStyles.ringsLeft}>

              <Image
                source={LeftRing1}
                style={firstPageStyles.left_ring_1}
              />
              <Image
                source={LeftRing3}
                style={firstPageStyles.left_ring_3}
              />
              <Image
                source={LeftRing2}
                style={firstPageStyles.left_ring_2}
              />
            </View>
          ) : (
            <View style={firstPageStyles.ringsLeft}>
              <Image
                source={require('../../assets/img/left_ring_1_mobile.png')}
                style={firstPageStyles.left_ring_1}
              />
              <Image
                source={require('../../assets/img/left_ring_3_mobile.png')}
                style={firstPageStyles.left_ring_3}
              />
              <Image
                source={require('../../assets/img/left_ring_2_mobile.png')}
                style={firstPageStyles.left_ring_2}
              />
            </View>
          )}
            <View style={firstPageStyles.trioArea}>
              <Text style={firstPageStyles.trioAreaTxt}>{t('sum_of_trio')} {t('trio')}</Text>
            </View>
            {Platform.OS === 'web' ? (
              <View style={firstPageStyles.ringsRight}>
                <Image
                  source={RightRing1}
                  style={firstPageStyles.right_ring_1}
                />
                <Image
                  source={RightRing2}
                  style={firstPageStyles.right_ring_2}
                />
              </View>
            ) : (
              <View style={firstPageStyles.ringsRight}>
                <Image
                  source={require('../../assets/img/right_ring_1_mobile.png')}
                  style={firstPageStyles.right_ring_1}
                />
                <Image
                  source={require('../../assets/img/right_ring_2_mobile.png')}
                  style={firstPageStyles.right_ring_2}
                />
              </View>
            )}
          </View>
          <View style={firstPageStyles.block4Part3}>
            <Text style={firstPageStyles.block4Text1}>
              <Text style={{color: theme.FONT_COLOR_BLUE}}>{t('trio')}</Text> = <Text style={{color: theme.FONT_COLOR_BLUE}}>{t('50_percents')}</Text> {t('from1')}
            </Text>
            <Text style={firstPageStyles.block4Text1}>{t('from_order_sum1')} </Text>
            <Text style={firstPageStyles.block4Text1}><Text style={{color: theme.FONT_COLOR_BLUE}}>{t('trios')}</Text> {t('for_you')}</Text>
          </View>
        </View>
        {/** Block 5 */}
        <View style={firstPageStyles.block5}>
          <View style={firstPageStyles.block5TextContainer}>
            <Text style={firstPageStyles.block5Text}><Text style={{color: theme.FONT_COLOR_BLUE}}>{t('authorize')}</Text> {t('to_start_earning')} {t('with_friends')}</Text>
          </View>
          <View style={firstPageStyles.block5BtnGroup}>
            <Pressable
              style={firstPageStyles.btnBlack}
              onPress={() => navigation.navigate('Login')}
              >
              <Text style={firstPageStyles.btnBlackTxt}>{t('registration')}</Text>
            </Pressable>
            <Pressable
              style={firstPageStyles.btnBlack}
              onPress={() => navigation.navigate('Login')}
            >
              <Text style={firstPageStyles.btnBlackTxt}>
                {t('login')}
              </Text>
            </Pressable>
          </View>
        </View>
      </View>
      <Footer navigation={navigation}/>
    </ScrollView>
  );
};

export default FirstPage;
