import React, {useContext, useState} from 'react';
import {AuthContext} from '../../components/AuthProvider';

import TriosList from './TrioList';
import TrioDetails from './TrioDetails';
// import ReferalDetails from './ReferalDetails';
// import OrderDetails from './OrderDetails';

const TriosScreen = ({navigation, route}) => {
  const {id: trioId} = route.params || {};
  const {activeTrio, activeReferal, activeOrder} = useContext(AuthContext);
  const [status, setStatus] = useState('waiting');

  if (trioId !== undefined) {
    return (
      <TrioDetails navigation={navigation} route={route} trioId={trioId} />
    );
  }
  // else if (activeReferal) {
  //   return <ReferalDetails navigation={navigation} route={route} />;
  // }
  // else if (activeOrder) {
  //   return <OrderDetails navigation={navigation} route={route} />;
  // }
  else {
    return (
      <TriosList
        navigation={navigation}
        route={route}
        status={status}
        setStatus={setStatus}
        trioId={trioId}
      />
    );
  }
};

export default TriosScreen;
