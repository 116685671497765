import {Platform, StyleSheet} from 'react-native';

const platformStyles = {
  web: (wp, hp, theme) =>
    StyleSheet.create({
      contentHead: {
        marginTop: 10,
        alignSelf: 'center',
      },
      contentContainer: {
        flex: 1,
        backgroundColor: '#FFF',
        flexDirection: 'column',
        width: wp('100%'),
        maxWidth: 1080,
        alignItems: 'center',
      },
      btnGroup: {
        marginTop: 31,
        gap: 12,
        marginBottom: 32,
        flex: 1,
      },
      btnActivity: {
        width: 184,
        height: 39,
        paddingHorizontal: 10,
        paddingVertical: 10,
        borderWidth: 1,
        borderStyle: 'solid',
        borderRadius: 10,
        borderColor: '#000000',
        // backgroundColor: '#D9D9D9',
        alignItems: 'center',
        justifyContent: 'center',
      },
      btnText: {
        color: '#000000',
        fontSize: 14,
        lineHeight: 15,
      },
      row: {
        width: wp('80%'),
        maxWidth: 800,
        borderRadius: 10,
        borderWidth: 1,
        // borderColor: '#000000',
        alignItems: 'center',
        // justifyContent: 'center',
        minHeight: 40,
        marginTop: 20,
        backgroundColor: theme.BLUE,
        overflow: 'scroll',
      },
      key: {
        width: wp('20%'),
        textAlign: 'center',
        fontFamily: theme.FONT_FAMILY_MEDIUM,
        color: theme.FONT_COLOR_WHITE,
        fontSize: 14,
        fontWeight: 'normal',
      },
      value: {
        width: wp('70%'),
        fontFamily: theme.FONT_FAMILY_MEDIUM,
        color: theme.FONT_COLOR_WHITE,
        fontSize: 14,
        fontWeight: 'normal',
      },
      divider: {
        flex: 1,
      },
      card: {
        width: wp('90%'),
        margin: 2,
        maxWidth: 980,
        alignSelf: 'center',
        backgroundColor: theme.BLUE,
      },
      cardTitle: {
        flexDirection: 'row',
        display: 'flex',
        justifyContent: 'space-between',
        paddingTop: 10,
        paddingBottom: 5,
        width: wp('80%')
      },
      cardTitleText: {
        fontFamily: theme.FONT_FAMILY_MEDIUM,
        color: theme.FONT_COLOR_WHITE,
        fontSize: 16,
      },
    }),
  android: (wp, hp, theme) =>
    StyleSheet.create({
      contentHead: {
        marginTop: 10,
        alignSelf: 'center',
      },
      contentContainer: {
        flex: 1,
        backgroundColor: '#FFF',
        flexDirection: 'column',
        width: wp('100%'),
        maxWidth: 1080,
        alignItems: 'center',
      },
      btnGroup: {
        marginTop: 31,
        gap: 12,
        marginBottom: 32,
        flex: 1,
      },
      btnActivity: {
        width: 184,
        height: 39,
        paddingHorizontal: 10,
        paddingVertical: 10,
        borderWidth: 1,
        borderStyle: 'solid',
        borderRadius: 10,
        borderColor: '#000000',
        // backgroundColor: '#D9D9D9',
        alignItems: 'center',
        justifyContent: 'center',
      },
      btnText: {
        color: '#000000',
        fontSize: 14,
        lineHeight: 15,
      },
      row: {
        width: wp('80%'),
        maxWidth: 800,
        borderRadius: 10,
        borderWidth: 1,
        // borderColor: '#000000',
        alignItems: 'center',
        // justifyContent: 'center',
        minHeight: 40,
        marginTop: 20,
        backgroundColor: theme.BLUE,
        overflow: 'scroll',
      },
      key: {
        width: wp('20%'),
        textAlign: 'center',
        fontFamily: theme.FONT_FAMILY_MEDIUM,
        color: theme.FONT_COLOR_WHITE,
        fontSize: 14,
        fontWeight: 'normal',
      },
      value: {
        width: wp('70%'),
        fontFamily: theme.FONT_FAMILY_MEDIUM,
        color: theme.FONT_COLOR_WHITE,
        fontSize: 14,
        fontWeight: 'normal',
      },
      divider: {
        flex: 1,
      },
      card: {
        width: wp('90%'),
        margin: 2,
        maxWidth: 980,
        alignSelf: 'center',
        backgroundColor: theme.BLUE,
      },
      cardTitle: {
        flexDirection: 'row',
        display: 'flex',
        justifyContent: 'space-between',
        paddingTop: 10,
        paddingBottom: 5,
        width: wp('80%')
      },
      cardTitleText: {
        fontFamily: theme.FONT_FAMILY_MEDIUM,
        color: theme.FONT_COLOR_WHITE,
        fontSize: 16,
      },
    }),
  ios: (wp, hp, theme) =>
    StyleSheet.create({
      contentHead: {
        marginTop: 22,
        alignSelf: 'center',
      },
      contentContainer: {
        backgroundColor: '#FFF',
        flexDirection: 'column',
      },
      btnGroup: {
        marginTop: 31,
        gap: 12,
        marginBottom: 82,
      },
      btnActivity: {
        width: 184,
        height: 39,
        paddingHorizontal: 10,
        paddingVertical: 10,
        borderWidth: 1,
        borderStyle: 'solid',
        borderRadius: 10,
        borderColor: '#000000',
        // backgroundColor: '#D9D9D9',
        alignItems: 'center',
        justifyContent: 'center',
      },
      btnText: {
        fontFamily: 'Inter',
        fontSize: 12,
        lineHeight: 14,
        color: '#000000',
      },
      divider: {
        flex: 1,
      },
      row: {
        width: 320,
        borderRadius: 10,
        borderWidth: 1,
        borderColor: '#000000',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: 40,
        marginTop: 20,
      },
      key: {
        width: '20%',
        textAlign: 'center',
        fontFamily: 'Inter',
        fontSize: 14,
        fontWeight: 'normal',
      },
      value: {
        width: '70%',
        fontFamily: 'Inter',
        fontSize: 14,
        fontWeight: 'normal',
      },
    }),
};

export default platformStyles[Platform.OS];
