// Breadcrumbs.js

import React, { useContext } from 'react';
import { View, Text, Pressable } from 'react-native';
import { AuthContext } from '../../components/AuthProvider';
import { HStack } from 'native-base';

import breadcrumbsStyle from '../../styles/breadcrumbs';

const Breadcrumbs = () => {
  const { breadcrumbs, regenerateBreadcrumbs } = useContext(AuthContext);

  return (
    <View style={breadcrumbsStyle.container}>
      <View style={breadcrumbsStyle.crumbs}>
      {
        breadcrumbs.map((breadcrumb, index) => (
          <HStack key={index}>
            <Pressable onPress={() => {
                breadcrumb.goto();
                regenerateBreadcrumbs(index);
              }}>
              <Text style={breadcrumbsStyle.crumbText}>{breadcrumb.text}</Text>
              </Pressable>
            {
              index < breadcrumbs.length - 1 &&
              (<Text style={breadcrumbsStyle.separator}>/</Text>)
            }
          </HStack>
          )
        )
      }
      </View>
    </View>
  );
};

export default Breadcrumbs;
