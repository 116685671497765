import React, {useContext, useEffect, useState} from 'react';
import {
  Platform,
  View,
  Pressable,
  Text,
  TextInput,
  ScrollView,
  TouchableOpacity,
  Image,
} from 'react-native';
import axios from 'axios';
import {HStack} from 'native-base';
import {
  widthPercentageToDP as wp,
  heightPercentageToDP as hp,
} from 'react-native-responsive-screen';

import {AuthContext} from '../../components/AuthProvider';

import HideIcon from '../../assets/img/hide.svg';
import ShowIcon from '../../assets/img/show.svg';

import ErrorModal from '../../components/common/ErrorModal';
import InfoModal from '../../components/common/InfoModal';
import Header from '../../components/common/Header';
import Footer from '../../components/common/Footer';
import Loader from '../../components/common/Loader';

import profileStylesF from '../../styles/profile';
import profileStylesMobileF from '../../styles/profile_mobile';
import commonStylesF from '../../styles/common';
import commonStylesMobileF from '../../styles/common_mobile';
import stylesF from '../../styles/login';

const PasswordVerifying = ({navigation, route}) => {
  const {t, screenWidth, theme, login, addHistory, inputedData, setInputedData} =
    useContext(AuthContext);

  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [info, setInfo] = useState(false);
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [password, setPassword] = useState('');
  const [prevWidth, setPrevWidth] = useState(screenWidth);

  const fontSizeBig =
    Math.ceil(screenWidth / 18) < 40 ? Math.ceil(screenWidth / 14) : 40;
  const fontSizeMd =
    Math.ceil(screenWidth / 20) < 24 ? Math.ceil(screenWidth / 16) : 24;

  const commonStyles =
    screenWidth < 1080
      ? commonStylesMobileF(wp, hp, theme)
      : commonStylesF(wp, hp, theme);

  const profileStyles =
    screenWidth < 1080
      ? profileStylesMobileF(wp, hp, fontSizeBig, fontSizeMd, theme)
      : profileStylesF(wp, hp, fontSizeBig, fontSizeMd, theme);

  const styles = stylesF(wp, theme);

  const hideLogoutButton = Platform.OS === 'ios';

  const handleEmailLogin = () => {
    if (inputedData.phone === '' || password === '') {
      setIsError(t('enter_password'));
      return;
    }
    setIsLoading(true);
    login(inputedData.phone, password, navigation).finally(() => setIsLoading(false));
  };

  useEffect(() => {
    setPrevWidth(screenWidth);
  }, [screenWidth]);

  useEffect(() => {
    if (Platform.OS !== 'web') {
      addHistory(route.name);
    }
  }, [addHistory, route]);

  if (isLoading) {
    return <Loader/>
  } else {
    return (
      <View style={commonStyles.container}>
        <ScrollView contentContainerStyle={commonStyles.container}>
          <Header
            navigation={navigation}
            route={route}
            hideLogoutButton={hideLogoutButton}
          />
          <View style={profileStyles.profileContainer}>
            <View style={styles.contentHead}>
              <Text style={commonStyles.contentHeadText}>{t('login')}</Text>
            </View>
            <View style={styles.content}>
              <Text style={styles.infoText}>
                {t('enter_password_from_new_account')}
              </Text>
              <View style={styles.passwordLabel}>
                <Text style={styles.label}>{t('enter_password')}*</Text>
                <Pressable
                  style={styles.forgotPassword}
                  onPress={() => {
                    Platform.OS === 'web'
                    ? navigation.push('ResetPassword')
                    : navigation.navigate('ResetPassword')
                  }}>
                  <Text style={styles.forgotPasswordText}>
                    {t('forgot_password')}?
                  </Text>
                </Pressable>
              </View>
              <HStack style={styles.inputContainer}>
                <TextInput
                  secureTextEntry={!isPasswordVisible}
                  type={'password'}
                  placeholder={t('password')}
                  value={password}
                  onChangeText={text => setPassword(text)}
                  style={styles.phoneInput}
                />
                <TouchableOpacity
                  style={styles.eye}
                  onPress={() =>
                    setIsPasswordVisible(!isPasswordVisible)
                  }>
                  {Platform.OS === 'web' ? (
                    <Image
                      source={isPasswordVisible ? HideIcon : ShowIcon}
                      style={
                        isPasswordVisible
                          ? styles.eyeHide
                          : styles.eyeShow
                      }
                    />
                  ) : isPasswordVisible ? (
                    <HideIcon style={styles.eyeHide} />
                  ) : (
                    <ShowIcon style={styles.eyeShow} />
                  )}
                </TouchableOpacity>
              </HStack>

              <HStack style={styles.btnGroup}>
                <Pressable
                  onPress={() => {
                    Platform.OS === 'web'
                    ? navigation.push('Login')
                    : navigation.navigate('Login')
                  }}
                  style={styles.buttonSmall}>
                  <Text style={styles.buttonText}>{t('back')}</Text>
                </Pressable>
                <Pressable
                  onPress={handleEmailLogin}
                  style={styles.buttonSmall}>
                  <Text style={styles.buttonText}>{t('log_in')}</Text>
                </Pressable>
              </HStack>
            </View>
          </View>
          <Footer navigation={navigation} />
        </ScrollView>
        {isError ? <ErrorModal error={isError} setIsError={setIsError} /> : <></>}
        {info ? <InfoModal info={info} setInfo={setInfo} /> : <></>}
        {/* {Platform.OS !== 'web' && <Empty />} */}
      </View>
    );
  }
};

export default PasswordVerifying;
