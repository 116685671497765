import {Platform, StyleSheet} from 'react-native';

const platformStyles = StyleSheet.create({
  web: {
    completed: {
      backgroundColor: '#d3ffd2',
    },
    new: {
      backgroundColor: '#cacbff',
    },
    waiting: {
      backgroundColor: '#fda68b',
    },
    canceled: {
      backgroundColor: '#ffd2d2',
    },
  },
  android: {
    completed: {
      backgroundColor: '#d3ffd2',
    },
    new: {
      backgroundColor: '#cacbff',
    },
    waiting: {
      backgroundColor: '#fda68b',
    },
    canceled: {
      backgroundColor: '#ffd2d2',
    },
  },
  ios: {
    completed: {
      backgroundColor: '#d3ffd2',
    },
    new: {
      backgroundColor: '#cacbff',
    },
    waiting: {
      backgroundColor: '#fda68b',
    },
    canceled: {
      backgroundColor: '#ffd2d2',
    },
  },
});

export default {...platformStyles[Platform.OS]};
