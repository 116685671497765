import React, {useContext, useState, useEffect} from 'react';
import {
  View,
  Text,
  TextInput,
  StyleSheet,
  Pressable,
  ActivityIndicator,
  TouchableOpacity,
  Dimensions,
  Platform,
  KeyboardAvoidingView,
  ScrollView,
  Keyboard,
  TouchableWithoutFeedback,
  Image,
} from 'react-native';
import {KeyboardAwareScrollView} from 'react-native-keyboard-aware-scroll-view';

import {HStack} from 'native-base';
import axios from 'axios';

import {
  widthPercentageToDP as wp,
  heightPercentageToDP as hp,
} from 'react-native-responsive-screen';

import ErrorModal from '../../components/common/ErrorModal';
import InfoModal from '../../components/common/InfoModal';
import Header from '../../components/common/Header';
import Footer from '../../components/common/Footer';

import {default as commonStylesMobile} from '../../styles/common_mobile';
import {default as theme} from '../../config/theme_config';

import Config from '../../config/config';
import {AuthContext} from '../../components/AuthProvider';

const ResetPasswordCodeVerification = ({navigation, route}) => {
  const {t, inputedData, setInputedData, addHistory} = useContext(AuthContext);

  const [code, setCode] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [info, setInfo] = useState('');
  const [isShowKeyboard, setIsShowKeyboard] = useState(false);

  const commonStyles = commonStylesMobile(wp, hp, theme);

  // const {email} = route.params;
  // console.log('ROUTE PARAMS ', route.params);
  const keyboardDismiss = () => {
    if (Platform.OS === 'ios') {
      Keyboard.dismiss();
    }
    return false;
  }

  const checkVerificationCode = async () => {
    if (code.length < 6) {
      setError(t('error_code_length'));
      return false;
    }

    setLoading(true);

    await axios
      .post(
        `${Config.API_URL}/api/users/check_code`,
        {
          email: inputedData.email,
          phone: inputedData.phone,
          code: code,
        },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      )
      .then(response => {
        console.log('check code response', response.data);
        if (response.data && response.data.status === 'success') {
          // console.log('Code checked OK');
          // setTmpJwt(response.data.message.jwt);
          // setUserId(response.data.message.user_id);
          setInputedData(prevState => {
            return {
              ...prevState,
              userId: response.data.message.user_id,
              tmpJwt: response.data.message.jwt
            }
          });
          console.log('setInputedData', inputedData);
          navigation.navigate('ResetPasswordUpdate'); // страница обновления пароля
        } else if (response.data && response.data.status === 'error') {
          setError(response.data.message);
        }
      })
      .catch(error => {
        // console.log('check code error', error);
        if (error.response !== undefined) {
          setError(error.response.data.message.error);
        } else {
          setError(t('server_error'));
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (Platform.OS !== 'web') {
      addHistory('ResetPasswordCodeVerifying');
    }
  }, [addHistory]);

  return (
    <TouchableWithoutFeedback onPress={() => keyboardDismiss()}>
      <ScrollView contentContainerStyle={commonStyles.container}>
        <Header navigation={false} route={false} />
        <View style={commonStyles.contentContainer}>
          <View
            style={
              Platform.OS === 'ios' ? styles.iosContentHead : styles.contentHead
            }>
            <Text
              style={
                Platform.OS === 'ios'
                  ? styles.iosContentHeadText
                  : commonStyles.contentHeadText
              }>
              {t('password_restoring')}
            </Text>
          </View>
          <KeyboardAvoidingView
            // behavior={Platform.OS === 'ios' ? 'padding' : 'height'}
            style={styles.content}>
            <View
              style={
                Platform.OS === 'ios' ? styles.iosContent : styles.content
              }>
              {Platform.OS !== 'ios' ? (
                <>
                  <Text style={styles.infoText}>
                    {t('on_the_email_was_sended')} ({inputedData.email}){' '}
                    {t('email_with_verification_code')}
                  </Text>
                  <Text style={styles.label}>{t('enter_received_code')}*</Text>
                  <TextInput
                    type={'text'}
                    keyboardType="numeric"
                    placeholder={t('code_from_email')}
                    value={code}
                    onChangeText={text => setCode(text)}
                    style={styles.phoneInput}
                  />
                  <HStack style={styles.btnGroup}>
                    <Pressable
                      onPress={() =>
                        Platform.OS === 'web'
                        ? navigation.push('ResetPassword')
                        : navigation.navigate('ResetPassword')
                      }
                      style={styles.button}>
                      <Text style={styles.buttonText}>{t('back')}</Text>
                    </Pressable>
                    <Pressable
                      onPress={checkVerificationCode}
                      style={styles.button}>
                      <Text style={styles.buttonText}>{t('send')}</Text>
                    </Pressable>
                  </HStack>
                </>
              ) : (
                <View
                  style={{
                    ...styles.iosContent,
                    marginBottom: isShowKeyboard ? 250 : 170,
                  }}>
                  <Text style={styles.infoText}>
                    {t('on_the_email_was_sended')} ({inputedData.email}){' '}
                    {t('email_with_verification_code')}
                  </Text>
                  <Text style={styles.label}>{t('enter_received_code')}*</Text>
                  <TextInput
                    type={'text'}
                    placeholder={t('code_from_email')}
                    value={code}
                    onChangeText={text => setCode(text)}
                    style={styles.phoneInput}
                  />
                  <HStack style={styles.btnGroup}>
                    <Pressable
                      // onPress={() => setCodeSended(false)}
                      onPress={() =>
                        Platform.OS === 'web'
                        ? navigation.push('ResetPassword')
                        : navigation.navigate('ResetPassword')
                      }
                      style={styles.button}>
                      <Text style={styles.buttonText}>{t('back')}</Text>
                    </Pressable>
                    <Pressable
                      onPress={checkVerificationCode}
                      style={styles.button}>
                      <Text style={styles.buttonText}>{t('send')}</Text>
                    </Pressable>
                  </HStack>
                </View>
              )}
            </View>
          </KeyboardAvoidingView>
        </View>
        <Footer />
        {error ? <ErrorModal error={error} setIsError={setError} /> : <></>}
        {info ? <InfoModal info={info} setInfo={setInfo} /> : <></>}
        {loading === true && (
          <ActivityIndicator
            style={styles.activityIndicator}
            color="#0C090D"
            size="large"
          />
        )}
      </ScrollView>
    </TouchableWithoutFeedback>
  );
};

const styles = StyleSheet.create({
  iosContentHead: {
    height: 100,
    marginTop: 50,
    padding: 0,
  },
  iosContentHeadText: {
    fontFamily: 'Inter',
    fontSize: 40,
    fontWeight: 'normal',
    lineHeight: 48,
    textAlign: 'center',
  },
  contentHead: {
    flex: 1,
    marginTop: 10,
    flexGrow: 1,
    flexShrink: 0,
    backgroundColor: theme.BLUE,
    borderRadius: 20,
    width: 250,
    maxHeight: 80,
    alignItems: 'center',
    justifyContent: 'center',
    alignSelf: 'center',
    padding: 10,
  },
  iosContent: {
    marginHorizontal: 40,
    resizeMode: 'cover',
    alignItems: 'center',
  },
  content: {
    flex: 1,
    marginTop: 28,
    width: 251,
    alignSelf: 'center',
    alignItems: 'center',
  },
  infoText: {
    paddingBottom: 20,
    fontFamily: theme.FONT_FAMILY_MEDIUM,
    color: theme.FONT_COLOR_BLACK,
    fontSize: 14,
    lineHeight: 16,
    textAlign: 'left',
  },
  label: {
    fontFamily: theme.FONT_FAMILY_MEDIUM,
    fontSize: 12,
    lineHeight: 15,
    textAlign: 'left',
    color: theme.FONT_COLOR_BLUE,
  },
  phoneInput: {
    width: 251,
    height: 42,
    padding: 10,
    borderRadius: 10,
    borderWidth: 2,
    gap: 10,
    borderColor: theme.BLACK,
    fontFamily: theme.FONT_FAMILY,
    fontSize: 20,
    lineHeight: 20,
    textAlign: 'left',
  },
  btnGroup: {
    gap: 10,
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: 20,
  },
  button: {
    marginTop: 18,
    width: 120,
    height: 40,
    paddingVertical: 10,
    paddingHorizontal: 15,
    borderRadius: 10,
    gap: 10,
    backgroundColor: theme.BLUE,
    alignItems: 'center',
    justifyContent: 'center',
  },
  buttonText: {
    fontFamily: theme.FONT_FAMILY_MEDIUM,
    color: theme.FONT_COLOR_WHITE,
    fontSize: 16,
    lineHeight: 19,
    textAlign: 'center',
  },
  activityIndicator: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'transparent', // Прозрачный фон
  },
});

export default ResetPasswordCodeVerification;