import {Platform, StyleSheet} from 'react-native';

const platformStyles = {
  web: (wp, hp, theme) =>
    StyleSheet.create({
      contentHead: {
        marginTop: 10,
      },
      editShortDesc: {
        marginTop: 47,
        width: 281,
        height: 100,
        gap: 11,
      },
      editAvatar: {
        width: 100,
        height: 100,
        borderRadius: 100,
      },
      editName: {
        fontFamily: 'Inter',
        fontSize: 40,
        fontWeight: 'normal',
        lineHeight: 48,
        textAlign: 'left',
        width: 200,
      },
      userPhone: {
        fontFamily: 'Inter',
        fontSize: 16,
        fontWeight: 'normal',
        lineHeight: 19,
        textAlign: 'left',
        color: '#707070',
      },
      trioStats: {
        marginTop: 32,
        // gap: 12,
        width: wp('95%'),
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'center',
        alignItems: 'center',
      },
      trioStat: {
        width: 175,
        height: 166,
        borderRadius: 20,
        backgroundColor: '#EAEAEA',
        margin: 10,
      },
      trioStatBlack: {
        width: 175,
        height: 166,
        borderRadius: 20,
        backgroundColor: theme.BLACK,
        margin: 10,
      },
      trioStatBlue: {
        width: 175,
        height: 166,
        borderRadius: 20,
        backgroundColor: theme.BLUE,
        margin: 10,
      },
      trioStatTxt1: {
        width: 150,
        height: 58,
        marginTop: 20,
        marginLeft: 20,
        fontFamily: theme.FONT_FAMILY_MEDIUM,
        color: theme.FONT_COLOR_WHITE,
        fontSize: 24,
        lineHeight: 29,
        textAlign: 'left',
      },
      trioStatTxt2: {
        // width: 95,
        height: 39,
        marginTop: 12,
        marginLeft: 20,
        fontFamily: theme.FONT_FAMILY_BOLD,
        color: theme.FONT_COLOR_WHITE,
        fontSize: 32,
        fontWeight: '900',
        lineHeight: 39,
        textAlign: 'left',
      },
      trioStatTxt3: {
        width: 50,
        height: 15,
        marginLeft: 20,
        fontFamily: theme.FONT_FAMILY,
        color: theme.FONT_COLOR_WHITE,
        fontSize: 12,
        fontWeight: '300',
        lineHeight: 15,
        textAlign: 'left',
      },
      itemsHeader: {
        width: 808,
        height: 72,
        alignItems: 'center',
        justifyContent: 'center',
        // gap: 60,
        borderBottomWidth: 1,
        borderStyle: 'solid',
        // borderBottomColor: '#D9D9D9',
        marginTop: 16,
        marginBottom: 4,
      },
      itemsHeaderText: {
        width: 148,
        fontFamily: 'Inter',
        fontSize: 16,
        fontWeight: 'normal',
        lineHeight: 19,
        textAlign: 'center',
      },
      itemRow: {
        width: wp('98%'),
        height: 72,
        alignItems: 'center',
        justifyContent: 'space-between',
        border: 1,
        borderRadius: 10,
        borderStyle: 'solid',
        borderColor: '#D9D9D9',
        marginTop: 12,
      },
      itemCol: {
        // width: '25%',
        textAlign: 'center',
        fontFamily: 'Inter',
        fontSize: 16,
        fontWeight: 'normal',
        lineHeight: 19,
      },
      btnGroup: {
        marginTop: 31,
        gap: 12,
        marginBottom: 32,
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
      },
      button: {
        width: 184,
        height: 39,
        paddingHorizontal: 10,
        paddingVertical: 10,
        borderWidth: 1,
        borderStyle: 'solid',
        borderRadius: 10,
        borderColor: '#000000',
        // backgroundColor: '#D9D9D9',
        alignItems: 'center',
        justifyContent: 'center',
      },
      btnText: {
        fontFamily: 'Inter',
        fontSize: 16,
        fontWeight: 'normal',
        lineHeight: 18,
        textAlign: 'center',
      },
      itemsFooterContainer: {
        flex: 1,
      },
      dataTable: {
        width: wp('98%'),
        alignItems: 'center',
      },
      trioInfo: {
        width: 340,
        height: 92,
        marginTop: 60,
        marginLeft: 60,
        alignSelf: 'flex-start',
      },
      trioName: {
        fontFamily: theme.FONT_FAMILY_MEDIUM,
        color: theme.FONT_COLOR_BLUE,
        fontSize: 48,
      },
      trioBalance: {
        fontFamily: theme.FONT_FAMILY_MEDIUM,
        color: theme.FONT_COLOR_BLACK,
        fontSize: 32,
      }

    }),
  android: (wp, hp, theme) =>
    StyleSheet.create({
      contentHead: {
        marginTop: 10,
      },
      editShortDesc: {
        marginTop: 47,
        width: 281,
        height: 100,
        gap: 11,
      },
      editAvatar: {
        width: 100,
        height: 100,
        borderRadius: 100,
      },
      editName: {
        fontFamily: 'Inter',
        fontSize: 40,
        fontWeight: 'normal',
        lineHeight: 48,
        textAlign: 'left',
        width: 200,
      },
      userPhone: {
        fontFamily: 'Inter',
        fontSize: 16,
        fontWeight: 'normal',
        lineHeight: 19,
        textAlign: 'left',
        color: '#707070',
      },
      trioStats: {
        marginTop: 32,
        // gap: 12,
        width: wp('95%'),
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'center',
        alignItems: 'center',
      },
      trioStat: {
        width: 175,
        height: 166,
        borderRadius: 20,
        backgroundColor: '#EAEAEA',
        margin: 10,
      },
      trioStatTxt1: {
        width: 150,
        height: 58,
        marginTop: 20,
        marginLeft: 20,
        fontFamily: 'Inter',
        fontSize: 24,
        fontWeight: 'normal',
        lineHeight: 29,
        textAlign: 'left',
      },
      trioStatTxt2: {
        // width: 95,
        height: 39,
        marginTop: 12,
        marginLeft: 20,
        fontFamily: 'Inter',
        fontSize: 32,
        fontWeight: '900',
        lineHeight: 39,
        textAlign: 'left',
      },
      trioStatTxt3: {
        width: 50,
        height: 15,
        marginLeft: 20,
        fontFamily: 'Inter',
        fontSize: 12,
        fontWeight: '300',
        lineHeight: 15,
        textAlign: 'left',
      },
      itemsHeader: {
        width: 808,
        height: 72,
        alignItems: 'center',
        justifyContent: 'center',
        // gap: 60,
        borderBottomWidth: 1,
        borderStyle: 'solid',
        // borderBottomColor: '#D9D9D9',
        marginTop: 16,
        marginBottom: 4,
      },
      itemsHeaderText: {
        width: 148,
        fontFamily: 'Inter',
        fontSize: 16,
        fontWeight: 'normal',
        lineHeight: 19,
        textAlign: 'center',
      },
      itemRow: {
        width: wp('98%'),
        height: 72,
        alignItems: 'center',
        justifyContent: 'space-between',
        border: 1,
        borderRadius: 10,
        borderStyle: 'solid',
        borderColor: '#D9D9D9',
        marginTop: 12,
      },
      itemCol: {
        // width: '25%',
        textAlign: 'center',
        fontFamily: 'Inter',
        fontSize: 16,
        fontWeight: 'normal',
        lineHeight: 19,
      },
      btnGroup: {
        marginTop: 31,
        gap: 12,
        marginBottom: 32,
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
      },
      button: {
        width: 184,
        height: 39,
        paddingHorizontal: 10,
        paddingVertical: 10,
        borderWidth: 1,
        borderStyle: 'solid',
        borderRadius: 10,
        borderColor: '#000000',
        // backgroundColor: '#D9D9D9',
        alignItems: 'center',
        justifyContent: 'center',
      },
      btnText: {
        fontFamily: 'Inter',
        fontSize: 16,
        fontWeight: 'normal',
        lineHeight: 18,
        textAlign: 'center',
      },
      itemsFooterContainer: {
        flex: 1,
      },
      dataTable: {
        width: wp('98%'),
        alignItems: 'center',
      },
    }),
  ios: (wp, hp, theme) =>
    StyleSheet.create({
      contentHead: {
        marginTop: 10,
      },
      editShortDesc: {
        marginTop: 47,
        width: 281,
        height: 100,
        gap: 11,
      },
      editAvatar: {
        width: 100,
        height: 100,
        borderRadius: 100,
      },
      editName: {
        fontFamily: 'Inter',
        fontSize: 40,
        fontWeight: 'normal',
        lineHeight: 48,
        textAlign: 'left',
        width: 200,
      },
      userPhone: {
        fontFamily: 'Inter',
        fontSize: 16,
        fontWeight: 'normal',
        lineHeight: 19,
        textAlign: 'left',
        color: '#707070',
      },
      trioStats: {
        marginTop: 32,
        // gap: 12,
        width: wp('95%'),
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'center',
        alignItems: 'center',
      },
      trioStat: {
        width: 175,
        height: 166,
        borderRadius: 20,
        backgroundColor: '#EAEAEA',
        margin: 10,
      },
      trioStatTxt1: {
        width: 150,
        height: 58,
        marginTop: 20,
        marginLeft: 20,
        fontFamily: 'Inter',
        fontSize: 24,
        fontWeight: 'normal',
        lineHeight: 29,
        textAlign: 'left',
      },
      trioStatTxt2: {
        // width: 95,
        height: 39,
        marginTop: 12,
        marginLeft: 20,
        fontFamily: 'Inter',
        fontSize: 32,
        fontWeight: '900',
        lineHeight: 39,
        textAlign: 'left',
      },
      trioStatTxt3: {
        width: 50,
        height: 15,
        marginLeft: 20,
        fontFamily: 'Inter',
        fontSize: 12,
        fontWeight: '300',
        lineHeight: 15,
        textAlign: 'left',
      },
      itemsHeader: {
        width: 808,
        height: 72,
        alignItems: 'center',
        justifyContent: 'center',
        // gap: 60,
        borderBottomWidth: 1,
        borderStyle: 'solid',
        // borderBottomColor: '#D9D9D9',
        marginTop: 16,
        marginBottom: 4,
      },
      itemsHeaderText: {
        width: 148,
        fontFamily: 'Inter',
        fontSize: 16,
        fontWeight: 'normal',
        lineHeight: 19,
        textAlign: 'center',
      },
      itemRow: {
        width: wp('98%'),
        height: 72,
        alignItems: 'center',
        justifyContent: 'space-between',
        border: 1,
        borderRadius: 10,
        borderStyle: 'solid',
        borderColor: '#D9D9D9',
        marginTop: 12,
      },
      itemCol: {
        // width: '25%',
        textAlign: 'center',
        fontFamily: 'Inter',
        fontSize: 16,
        fontWeight: 'normal',
        lineHeight: 19,
      },
      btnGroup: {
        marginTop: 31,
        gap: 12,
        marginBottom: 32,
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
      },
      button: {
        width: 184,
        height: 39,
        paddingHorizontal: 10,
        paddingVertical: 10,
        borderWidth: 1,
        borderStyle: 'solid',
        borderRadius: 10,
        borderColor: '#000000',
        // backgroundColor: '#D9D9D9',
        alignItems: 'center',
        justifyContent: 'center',
      },
      btnText: {
        fontFamily: 'Inter',
        fontSize: 16,
        fontWeight: 'normal',
        lineHeight: 18,
        textAlign: 'center',
      },
      itemsFooterContainer: {
        flex: 1,
      },
      dataTable: {
        width: wp('98%'),
        alignItems: 'center',
      },
    }),
};

export default platformStyles[Platform.OS];
