import {Platform, StyleSheet} from 'react-native';

const platformStyles = StyleSheet.create({
  web: {
    container: {
      width: 1080,
      backgroundColor: '#FFF',
      alignItems: 'center',
    },
    crumbs: {
      width: 800,
      backgroundColor: '#FFF',
      alignItems: 'left',
      flexDirection: 'row',
    },
    crumbText: {
      fontSize: 16,
      // fontWeight: 'bold',
      color: '#000000', // Цвет текста
    },
    separator: {
      marginHorizontal: 5,
      fontSize: 16,
      color: 'gray', // Цвет разделителя
    },
  },
  android: {
    container: {
      width: 1080,
      backgroundColor: '#FFF',
      alignItems: 'center',
    },
    crumbs: {
      // width: 800,
      backgroundColor: '#FFF',
      alignItems: 'left',
      flexDirection: 'row',
    },
    crumbText: {
      fontSize: 16,
      // fontWeight: 'bold',
      color: '#000000', // Цвет текста
    },
    separator: {
      marginHorizontal: 5,
      fontSize: 16,
      color: 'gray', // Цвет разделителя
    },
  },
  ios: {
    container: {
      width: 1080,
      backgroundColor: '#FFF',
      alignItems: 'center',
    },
    crumbs: {
      // width: 800,
      backgroundColor: '#FFF',
      alignItems: 'left',
      flexDirection: 'row',
    },
    crumbText: {
      fontSize: 16,
      // fontWeight: 'bold',
      color: '#000000', // Цвет текста
    },
    separator: {
      marginHorizontal: 5,
      fontSize: 16,
      color: 'gray', // Цвет разделителя
    },
  },
});

export default {...platformStyles[Platform.OS]};
