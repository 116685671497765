// PrivacyPolicy.js
import React, {useContext, useEffect, useState} from 'react';
import {View, Text, StyleSheet, ScrollView, Platform, Linking, Pressable} from 'react-native';
import {
  widthPercentageToDP as wp,
  heightPercentageToDP as hp,
} from 'react-native-responsive-screen';

import {AuthContext} from '../AuthProvider';

import Empty from './Empty';
import Header from './Header';
import Footer from './Footer';

import {default as commonStylesDesktop} from '../../styles/common';
import {default as commonStylesMobile} from '../../styles/common_mobile';

import theme from '../../config/theme_config';

const PrivacyPolicy = ({navigation, route}) => {
  const {t, screenWidth} = useContext(AuthContext);
  const [prevScreenWidth, setPrevScreenWidth] = useState(screenWidth);
  let commonStyles =
    screenWidth < 1080
      ? commonStylesMobile(wp, hp, theme)
      : commonStylesDesktop(wp, hp, theme);

  useEffect(() => {
    setPrevScreenWidth(screenWidth);
  }, [screenWidth]);

  return (
    <ScrollView contentContainerStyle={commonStyles.container}>
      <Header navigation={navigation} />

      <View style={styles.contentContainer}>
        <View style={styles.headContainer}>
          <Text style={styles.headText}>{t('policy')}</Text>
        </View>
        <View style={styles.firstBlock}>
          <Text style={styles.textBlockItem}>
            <Text style={styles.paragraph}>{t('privacy_text_1')}</Text>
            <Text style={styles.paragraph}>{t('privacy_text_2')}</Text>
          </Text>
        </View>
        <View style={styles.secondBlock}>
          <View style={styles.secondBlockItem}>
            <Text style={styles.blockHeadText}>{t('privacy_text_3')}</Text>
            <Text style={styles.paragraph}>{t('privacy_text_4')}</Text>
            <Text style={styles.paragraph}>{t('privacy_text_5')}</Text>
          </View>

          <View style={styles.secondBlockItem}>
            <Text style={styles.blockHeadText}>{t('privacy_text_6')}</Text>
            <Text style={styles.paragraph}>{t('privacy_text_7')}</Text>
            <Text style={styles.paragraph}>{t('privacy_text_8')}</Text>
          </View>
          <View style={styles.secondBlockItem}>
            <Text style={styles.blockHeadText}>{t('privacy_text_9')}</Text>
            <Text style={styles.paragraph}>{t('privacy_text_10')}</Text>
            <Text style={styles.paragraph}>{t('privacy_text_11')}</Text>
          </View>
          <View style={styles.secondBlockItem}>
            <Text style={styles.blockHeadText}>{t('privacy_text_12')}</Text>
            <Text style={styles.paragraph}>{t('privacy_text_13')}</Text>
            <Text style={styles.paragraph}>{t('privacy_text_14')}</Text>
          </View>
          <View style={styles.secondBlockItem}>
            <Text style={styles.blockHeadText}>{t('privacy_text_15')}</Text>
            <Text style={styles.paragraph}>{t('privacy_text_16')}</Text>
          </View>
          <View style={styles.secondBlockItem}>
            <Text style={{...styles.blockHeadText, marginBottom: 0}}>{t('privacy_text_19')}</Text>
            <Text style={styles.paragraph}>{t('privacy_text_20')}
            {
              Platform.OS === 'web' ? (
                <a href="https://developers.google.com/terms/api-services-user-data-policy">{t('privacy_text_22')}</a>
              ) : (
                <Pressable onPress={() => Linking.openURL('https://developers.google.com/terms/api-services-user-data-policy')}>
                  <Text>{t('privacy_text_22')}</Text>
                </Pressable>
              )
            }, {t('privacy_text_21')}
          </Text>
          </View>
        </View>
        <View style={styles.thirdBlock}>
          <Text style={styles.textBlockItem}>{t('privacy_text_17')}</Text>
        </View>
        <View style={styles.lastBlock}>
          <Text style={styles.lastBlockText}>{t('privacy_text_18')}</Text>
        </View>
      </View>
      <Empty />
      <Footer navigation={navigation} />
    </ScrollView>
  );
};

const styles = StyleSheet.create({
  container: {
    // flex: 1,
    height: hp('100%'),
    alignItems: 'center',
    justifyContent: 'center',
    // flexDirection: 'column',
    backgroundColor: theme.WHITE,
  },

  contentContainer: {
    // flex: 1,
    width: '100%',
    // height: hp('100%'),
    maxWidth: 1080,
    paddingHorizontal: '6%',
  },
  headContainer: {
    width: '100%',
    maxWidth: 500,
    // heigh: 'auto',
    paddingVertical: '6%',
  },
  headText: {
    fontFamily: theme.FONT_FAMILY,
    fontSize: 40,
    lineHeight: 40,
    color: theme.FONT_COLOR_BLACK,
  },
  paragraph: {
    marginTop: 15,
    display: 'inline-block',
  },
  textBlockItem: {
    fontFamily: theme.FONT_FAMILY,
    fontSize: 16,
    color: theme.FONT_COLOR_BLACK,
  },
  firstBlock: {
    marginTop: 50,
    width: '100%',
    maxWidth: 540,
  },
  secondBlock: {
    width: '100%',
    maxWidth: 1080,
    flexDirection: 'row',
    flexWrap: 'wrap',
    gap: 30,
    marginTop: 50,
  },
  secondBlockItem: {
    width: '80%',
    maxWidth: 330,
    marginTop: 40,
  },
  thirdBlock: {
    marginTop: 150,
    width: '100%',
    maxWidth: 540,
  },
  lastBlock: {
    marginTop: 100,
    width: '100%',
    maxWidth: 800,
    marginBottom: 100,
  },
  lastBlockText: {
    fontFamily: theme.FONT_FAMILY,
    color: theme.FONT_COLOR_BLACK,
    fontSize: 40,
  },
  blockHeadText: {
    fontFamily: theme.FONT_FAMILY,
    color: theme.FONT_COLOR_BLACK,
    fontSize: 24,
    marginBottom: 35,
  },
});

export default PrivacyPolicy;
