import * as React from 'react';
import {Platform} from 'react-native';
import {createStackNavigator} from '@react-navigation/stack';
import {createBottomTabNavigator} from '@react-navigation/bottom-tabs';
import Ionicons from 'react-native-vector-icons/Ionicons';

import AdminUsers from './Admin/Users';
import AdminStatistics from './Admin/Statistics';
import AdminWithdrawScreen from './Admin/Withdraws';
import AdminMessages from './Admin/Messages';

const AdminStack =
  Platform.OS === 'web' ? createStackNavigator() : createBottomTabNavigator();

const AdminNavigationWeb = () => {
  return (
    <>
      <AdminStack.Navigator
        screenOptions={{
          headerShown: false,
        }}
        >
        <AdminStack.Screen name="ActiveUsers" component={AdminUsers} />
        <AdminStack.Screen name="AdminStatistics" component={AdminStatistics} />
        <AdminStack.Screen
          name="AdminWithdraws"
          component={AdminWithdrawScreen}
        />
        <AdminStack.Screen name="AdminMessages" component={AdminMessages} />
      </AdminStack.Navigator>
    </>
  );
};

const AdminNavigationMobile = () => {
  return (
    <>
      <AdminStack.Navigator
        screenOptions={({route}) => ({
          tabBarIcon: ({focused, color, size}) => {
            let iconName;

            if (route.name === 'ActiveUsers') {
              iconName = focused ? 'id-card' : 'id-card-outline';
            } else if (route.name === 'AdminStatistics') {
              iconName = focused ? 'cash' : 'cash-outline';
            } else if (route.name === 'AdminWithdraws') {
              iconName = focused ? 'card' : 'card-outline';
            } else if (route.name === 'AdminMessages') {
              iconName = focused ? 'settings' : 'settings-outline';
            }
            // You can return any component that you like here!
            return <Ionicons name={iconName} size={size} color={color} />;
          },
          tabBarActiveTintColor: 'black',
          tabBarInactiveTintColor: 'gray',
          headerShown: false,
          tabBarHideOnKeyboard: true,
          freezeOnBlur: true,
        })}
        initialRouteName="ActiveUsers">
        <AdminStack.Screen
          name="ActiveUsers"
          component={AdminUsers}
          options={{tabBarLabel: 'Пользователи'}}
        />
        <AdminStack.Screen
          name="AdminStatistics"
          component={AdminStatistics}
          options={{tabBarLabel: 'Статистика'}}
        />
        <AdminStack.Screen
          name="AdminWithdraws"
          component={AdminWithdrawScreen}
          options={{tabBarLabel: 'Заявки на выплату'}}
        />
        <AdminStack.Screen
          name="AdminMessages"
          component={AdminMessages}
          options={{tabBarLabel: 'Сообщения'}}
        />
      </AdminStack.Navigator>
    </>
  );
};

const AdminScreen =
  Platform.OS === 'web' ? AdminNavigationWeb : AdminNavigationMobile;

export default AdminScreen;
