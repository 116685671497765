import React from 'react';
import AsyncStorage from '@react-native-async-storage/async-storage';
import axios from 'axios';
import AxiosInstance from '../../components/common/axiosInstance';

import Config from '../../config/config';

const saveDataToStorage = async (data, key) => {
  try {
    await AsyncStorage.setItem(key, JSON.stringify(data));
    // console.log(`save ${key} to Storage`, data);
  } catch (error) {
    // console.log(`save ${key} to Storage error: `, error);
  }
};

const getDataFromStorage = async key => {
  try {
    let data = await AsyncStorage.getItem(key);
    let savedData;
    // console.log(`get ${key} from Storage`, data);
    savedData = data !== null && data !== undefined ? JSON.parse(data) : false;
    // console.log(`get ${key} fromStorage cnt`, Object.keys(savedData).length);
    return savedData;
  } catch (error) {
    console.log(`get ${key} from Storage error: `, error);
    return false;
  }
};

// const googleRefreshTokens = async refresh_token => {
//   await axios
//     .post(
//       `${Config.API_URL}/oauth2.php`,
//       {
//         refresh_token: refresh_token,
//       },
//       {
//         headers: {
//           'Content-Type': 'application/x-www-form-urlencoded',
//         },
//       }
//     )
//     .then(response => {
//       // console.log('RESPONSE FROM BACKEND, REFRESHING TOKEN', response);
//       let now = new Date();
//       if (response.data.token !== undefined) {
//         response.data.token.expires_at =
//           now.getTime() + response.data.token.expires_in;
//         saveDataToStorage(response.data.token, 'tokens').then(data => {
//           return response.data.token;
//         });
//       } else {
//         console.log('Не удалось получить access token от Google');
//         return false;
//       }
//     })
//     .catch(error => {
//       console.log('ERROR RESPONSE FROM BACKEND, REFRESHING TOKEN', error);
//       return false;
//     });
// };

// const getGoogleTokensFromStorage = async () => {
//   let token = await getDataFromStorage('tokens');

//   // console.log('getDataFromStorage', token)
//   if (token === undefined || token === false) {
//     // нет сохраненных токенов
//     // console.log('getGoogleTokensFromStorage no tokens');
//     return false;
//   } else {
//     let now = new Date();
//     now = now.getTime();
//     if (token.expires_at !== undefined) {
//       // проверяем актуальность и содержимое
//       // console.log('start checking expires', new Date(token.expires_at), new Date(now));
//       if (token.expires_at > now) {
//         // актуален, возвращаем
//         // console.log('checking expires: OK', token.expires_at, now);
//         return token;
//       } else if (token.expires_at <= now && token.refresh_token !== undefined) {
//         // просрочен
//         // console.log('checking expires: NOT OK');
//         let refreshedToken = await googleRefreshTokens(token.refresh_token);
//         // console.log('refreshed  token', refreshedToken);
//         if (refreshedToken !== false && refreshedToken !== undefined) {
//           await saveDataToStorage(refreshedToken, 'tokens');
//           return refreshedToken;
//         } else {
//           return false;
//         }
//       } else {
//         return false;
//       }
//     } else {
//       return false;
//     }
//   }
// };

const saveContactsToDB = (
  contacts,
  google_account_id,
  contactType,
  user,
  logout
) => {
  const batchSize = 100;
  const api = AxiosInstance({user, logout});

  api(`/api/v1/user_contacts/save`, {
    method: 'POST',
    mode: 'cors',
    timeout: 60000,
    data: {
      contacts: contacts,
      google_account_id: google_account_id,
      contact_type: contactType,
    },
  })
    .then(response => {
      console.log('saveContactsToDB response', google_account_id, response.data);
    })
    .catch(error => {
      console.log('saveContactsToDB error:', error);
    });
};

const sendInviteToContacts = async (
  contactsToSend,
  setIsError,
  setInfo,
  setIsLoading,
  user,
  logout,
  t,
  msgFormData = {}
) => {
  const api = AxiosInstance({user, logout});
  await api('/api/v1/invitations/bulk', {
    method: 'POST',
    mode: 'cors',
    data: {
      contacts: contactsToSend,
      msgFormData: msgFormData,
    },
  })
    .then(response => {
      console.log('Bulk create response:', response.data.message);
      if (response.data.code === 200) {
        let info = '';
        if (response.data.message.created) {
          info +=
            t('prepared_to_send') +
            ` ${response.data.message.created} ` +
            t('of_invitations') +
            ' \n';
        }
        if (response.data.message.errors.length) {
          info +=
            `${response.data.message.errors.length} ` +
            t('contain_incomplete_data') +
            '\n';
        }
        if (response.data.message.exists.length) {
          info += `${response.data.message.exists.length} ` + t('already_sent');
        }
        setInfo(info);
      } else {
        setIsError(t('server_error'));
        console.log('Response Bulk create error:', response.data.message);
      }
    })
    .catch(error => {
      console.log('Catch Bulk create error:', error);
      if (error.code === 403) {
        setIsError(error.message);
      } else {
        setIsError(t('server_error'));
      }
    })
    .finally(() => {
      setIsLoading(false);
    });
};

export {
  saveDataToStorage,
  getDataFromStorage,
  // getGoogleTokensFromStorage,
  // googleRefreshTokens,
  saveContactsToDB,
  sendInviteToContacts,
};
