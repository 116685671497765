import React, {useContext} from 'react';
import {Pressable, StyleSheet, Image, Modal} from 'react-native';
import {Text, View} from 'native-base';
import HTML, {RenderHTML} from 'react-native-render-html';

import {capitalizeFirstLetter} from './commonFunctions';
import {AuthContext} from '../../components/AuthProvider';

const ErrorModal = ({error, setIsError}) => {
  const {setErrors, t} = useContext(AuthContext);

  return (
    <View style={styles.container}>
      <Modal
        animationType="none"
        transparent={true}
        visible={true}
        onRequestClose={() => {
          setIsError(false);
        }}>
        <View style={styles.errorModalContainer}>
          <View style={styles.errorModalContent}>
            <Text style={styles.errorModalCaption}>
              {capitalizeFirstLetter(t('error'))}
            </Text>
            {/* <Text style={styles.errorText}> */}
            <View style={styles.errorContent}>
              <RenderHTML
                source={{html: t(error)}}
                contentWidth={300}
                enableExperimentalBRCollapsing={true}
              />
            </View>
            {/* </Text> */}
            <Pressable
              style={styles.errorModalBtn}
              onPress={() => {
                setIsError(false);
                setErrors(null);
              }}>
              <Image
                style={styles.errorIconClose}
                source={require('../../assets/img/si_Arrow_right.png')}
              />
            </Pressable>
          </View>
        </View>
      </Modal>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
    justifyContent: 'center',
    position: 'fixed',
    alignSelf: 'center',
    zIndex: 11,
    top: '-70%',
  },
  errorModalContainer: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  errorModalContent: {
    width: 320,
    height: 160,
    borderRadius: 40,
    borderWidth: 1,
    backgroundColor: '#FFF',
    gap: 12,
    paddingLeft: 20,
  },
  errorContent: {
    height: 50,
  },
  errorModalCaption: {
    fontFamily: 'Inter',
    fontSize: 24,
    fontWeight: 'bold',
    lineHeight: 29,
    textAlign: 'left',
    marginTop: 20,
  },
  errorModalBtn: {
    width: 48,
    height: 48,
    alignSelf: 'flex-end',
    // marginTop: 10,
    marginRight: 10,
    marginTop: -12,
    justifyContent: 'center',
  },
  errorIconClose: {
    width: 24,
    height: 24,
    zIndex: 11,
  },
  errorText: {
    fontFamily: 'Inter',
    fontSize: 12,
    fontWeight: 'bold',
    textAlign: 'left',
  },
});

export default ErrorModal;
