import React, {useContext, useState, useEffect} from 'react';
import {View, Pressable, Image, Platform} from 'react-native';
import {Text, HStack, ScrollView} from 'native-base';
import {useIsFocused} from '@react-navigation/native';
import {Card, Badge, DataTable} from 'react-native-paper';
import Ionicons from 'react-native-vector-icons/Ionicons';
import IOSCustomPagination from '../../components/common/IOSCustomPagination';

import {
  widthPercentageToDP as wp,
  heightPercentageToDP as hp,
} from 'react-native-responsive-screen';
import AxiosInstance from '../../components/common/axiosInstance';

import Header from '../../components/common/Header';
import Footer from '../../components/common/Footer';
import Loader from '../../components/common/Loader';
import ErrorModal from '../../components/common/ErrorModal';
import {AuthContext} from '../../components/AuthProvider';
import ActivityDetails from './ActivityDetails';

import ArrowLeft from '../../assets/img/arrow_left_white.svg';

import {default as commonStylesF} from '../../styles/common';
import {default as commonStylesMobileF} from '../../styles/common_mobile';
import {default as listStylesF} from '../../styles/list';
import {default as activityStylesF} from '../../styles/activity';
import {default as activityStylesMobileF} from '../../styles/activity_mobile';

const ActivityHeaderTable = props => {
  const {t, theme} = useContext(AuthContext);
  const listStyles = listStylesF(theme);
  const dateSort = direction => {
    direction === 'desc'
      ? props.setSortDirection('asc')
      : props.setSortDirection('desc');
  };
  return (
    <DataTable.Header style={listStyles.itemsHeader}>
      <DataTable.Title
        style={listStyles.itemCell}
        sortDirection={
          props.sortDirection === 'desc' ? 'descending' : 'ascending'
        }>
        <Pressable
          onPress={() => {
            dateSort(props.sortDirection);
          }}>
          <HStack style={Platform.OS === 'ios' ? {alignItems: 'center'} : {}}>
            <Text style={{...listStyles.itemsHeaderText, marginLeft: 10}}>
              {t('date')}
            </Text>
          </HStack>
        </Pressable>
      </DataTable.Title>
      <DataTable.Title style={listStyles.itemCell}>
        <Text
          style={{
            ...listStyles.itemsHeaderText,
            fontSize: Platform.OS === 'ios' ? 14 : listStyles.itemCol.fontSize,
          }}>
          {t('method')}
        </Text>
      </DataTable.Title>
      <DataTable.Title style={listStyles.itemCell}>
        <Text style={listStyles.itemsHeaderText}>{t('part')}</Text>
      </DataTable.Title>
      <DataTable.Title style={listStyles.itemCell}>
        <Text style={listStyles.itemsHeaderText}>{t('result')}</Text>
      </DataTable.Title>
    </DataTable.Header>
  );
};

const ActivityItemTable = ({navigation, item}) => {
  const {setActiveHistory, t, theme, setScreenParams} = useContext(AuthContext);
  const listStyles = listStylesF(theme);

  let status;
  let respJson = JSON.parse(item.response);
  status =
    respJson.code >= 200 && respJson.code < 400 ? t('success') : t('error');

  return (
    <DataTable.Row
      style={listStyles.itemRow}
      // onPress={() => setActiveHistory(item)}
      onPress={() => {
        setScreenParams(item);
        navigation.navigate('ActivityDetails');
      }}
      >
      <DataTable.Cell style={listStyles.itemCell}>
        <Text
          style={{
            ...listStyles.itemCol,
            fontSize:
              Platform.OS === 'ios' ? 12 : listStyles.itemsHeaderText.fontSize,
          }}>
          {item.timestamp}
        </Text>
      </DataTable.Cell>
      <DataTable.Cell style={listStyles.itemCell}>
        <Text
          style={{
            ...listStyles.itemCol,
            fontSize:
              Platform.OS === 'ios' ? 14 : listStyles.itemsHeaderText.fontSize,
          }}>
          {item.method}
        </Text>
      </DataTable.Cell>
      <DataTable.Cell style={listStyles.itemCell}>
        <Text
          style={{
            ...listStyles.itemCol,
            fontSize:
              Platform.OS === 'ios' ? 14 : listStyles.itemsHeaderText.fontSize,
          }}>
          {t(item.route_name)}
        </Text>
      </DataTable.Cell>
      <DataTable.Cell style={listStyles.itemCell}>
        <Text
          style={{
            ...listStyles.itemCol,
            fontSize:
              Platform.OS === 'ios' ? 14 : listStyles.itemsHeaderText.fontSize,
          }}>
          {status}
        </Text>
      </DataTable.Cell>
    </DataTable.Row>
  );
};

const ActivityHeaderCard = props => {
  const {t, theme} = useContext(AuthContext);
  const listStyles = listStylesF(theme);

  const dateSort = direction => {
    direction === 'desc'
      ? props.setSortDirection('asc')
      : props.setSortDirection('desc');
  };

  return (
    <DataTable.Header style={listStyles.itemsHeader}>
      <DataTable.Title
        style={listStyles.itemCell}
        sortDirection={
          props.sortDirection === 'desc' ? 'descending' : 'ascending'
        }>
        <Pressable
          onPress={() => {
            dateSort(props.sortDirection);
          }}>
          <HStack style={Platform.OS === 'ios' ? {alignItems: 'center'} : {}}>
            <Text style={{...listStyles.itemsHeaderText, marginLeft: 10}}>
              {t('date')}
            </Text>
          </HStack>
        </Pressable>
      </DataTable.Title>
    </DataTable.Header>
  );
};

const ActivityItemCard = ({item, navigation}) => {
  const {setActiveHistory, t, screenWidth, theme, setScreenParams} = useContext(AuthContext);
  const activityStyles =
    screenWidth < 1080
      ? activityStylesMobileF(wp, hp, theme)
      : activityStylesF(wp, hp, theme);
  let respJson = JSON.parse(item.response);
  let status =
    respJson.code >= 200 && respJson.code < 400 ? t('success') : t('error');
  let backgroundColor =
    respJson.code >= 200 && respJson.code < 400 ? '#04aa26' : '#b3261e';
  const Title = (
    <View style={activityStyles.cardTitle}>
      <Text style={activityStyles.cardTitleText}>{item.timestamp}</Text>
      <Badge
        size={25}
        style={{
          backgroundColor: backgroundColor,
          color: theme.FONT_COLOR_WHITE,
        }}>
        {status}
      </Badge>
    </View>
  );

  return (
    <DataTable.Row
      key={item.id}
      style={{width: wp('90%'), alignItems: 'center'}}>
      <Card
        mode={'outlined'}
        style={activityStyles.card}
        // onPress={() => setActiveHistory(item)}
        onPress={() => {
          setScreenParams(item);
          navigation.navigate('ActivityDetails');
        }}
      >
        <Card.Title title={Title} />
        <Card.Content>
          <Text style={activityStyles.cardTitleText}>
            {t('method')}: {item.method}
          </Text>
          <Text style={activityStyles.cardTitleText}>
            {t('part')}: {t(item.route_name)}
          </Text>
        </Card.Content>
      </Card>
    </DataTable.Row>
  );
};

const ActivityScreen = ({navigation, route}) => {
  const {user, logout, activeHistory, t, screenWidth, theme, addHistory} =
    useContext(AuthContext);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(null);
  const [activity, setActivity] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [pagination, setPagination] = useState({});
  const [sortField, setSortField] = useState('timestamp');
  const [sortDirection, setSortDirection] = useState('desc');
  const [prevScreenWidth, setPrevScreenWidth] = useState(screenWidth);
  const [from, setFrom] = useState(0);
  const [to, setTo] = useState(0);
  const isFocused = useIsFocused();

  const commonStyles =
    screenWidth < 1080
      ? commonStylesMobileF(wp, hp, theme)
      : commonStylesF(wp, hp, theme);
  const listStyles = listStylesF(theme);

  const ActivityHeader =
    screenWidth < 700 ? ActivityHeaderCard : ActivityHeaderTable;
  const ActivityItem = screenWidth < 700 ? ActivityItemCard : ActivityItemTable;

  useEffect(() => {
    const getActivity = async () => {
      setIsLoading(true);
      const api = AxiosInstance({user, logout});
      const url = `/api/v1/activity?page=${currentPage}&order_field=${sortField}&order=${sortDirection}`;
      await api(url, {
        method: 'GET',
        mode: 'cors',
      })
        .then(response => {
          let _from = 0;
          let _to = 0;
          setActivity(response.data.message.data);
          setPagination(response.data.message.pagination);
          _from =
          response.data.message.pagination.totalRows > 0
            ? (currentPage - 1) * response.data.message.pagination.perPage + 1
            : 0;
          _to =
            response.data.message.pagination.totalRows >
            currentPage * response.data.message.pagination.perPage
              ? currentPage * response.data.message.pagination.perPage
              : response.data.message.pagination.totalRows;
          setFrom(_from);
          setTo(_to);
        })
        .catch(error => {
          setIsError(error);
        })
        .finally(() => {
          setIsLoading(false);
        });
    };

    if (isFocused) {
      getActivity();
    }
  }, [currentPage, sortField, sortDirection, user, logout, isFocused]);

  useEffect(() => {
    setPrevScreenWidth(screenWidth);
  }, [screenWidth]);

  useEffect(() => {
    if (Platform.OS !== 'web') {
      addHistory(route.name);
    }
  }, [addHistory, route]);

  if (isLoading) {
    return <Loader />;
  }
  // else if (activeHistory) {
  //   return <ActivityDetails navigation={navigation} route={route}/>;
  // }
  else {
    return (
      <ScrollView contentContainerStyle={commonStyles.container}>
        <Header navigation={navigation} route={route} />
        <View style={commonStyles.contentContainer}>
          <View style={commonStyles.headerContainer}>
            <Pressable
              style={commonStyles.headBackButton}
              onPress={() => navigation.navigate('Profile')}>
              {Platform.OS === 'web' ? (
                <Image source={ArrowLeft} style={commonStyles.arrowLeft} />
              ) : (
                <ArrowLeft style={commonStyles.arrowLeft} />
              )}
            </Pressable>
            <View style={commonStyles.contentHead}>
              <Text style={commonStyles.contentHeadText}>{t('history')}</Text>
            </View>
          </View>
          {Platform.OS === 'ios' ? (
            // Кастомна пагінація і рендеринг даних для iOS
            <View style={listStyles.dataTable}>
              <ActivityHeader
                sortField={sortField}
                sortDirection={sortDirection}
                setSortField={setSortField}
                setSortDirection={setSortDirection}
              />
              {activity.map(item => (
                <ActivityItem item={item} key={item.id} />
              ))}
              <IOSCustomPagination
                currentPage={currentPage}
                totalPages={Math.ceil(pagination.totalPages)}
                onPageChange={newPage => setCurrentPage(newPage)}
                label={`${from}-${to} of ${pagination.totalRows}`}
              />
            </View>
          ) : (
            // Стандартна пагінація для інших платформ
            <DataTable style={listStyles.dataTable}>
              <ActivityHeader
                sortField={sortField}
                sortDirection={sortDirection}
                setSortField={setSortField}
                setSortDirection={setSortDirection}
              />
              {activity.map(item => (
                <ActivityItem item={item} key={item.id} navigation={navigation}/>
              ))}
              <DataTable.Pagination
                page={currentPage - 1}
                numberOfPages={Math.ceil(pagination.totalPages)}
                onPageChange={page => setCurrentPage(page + 1)}
                label={`${from}-${to} of ${pagination.totalRows}`}
                numberOfItemsPerPage={pagination.perPage}
                showFastPaginationControls
              />
            </DataTable>
          )}
        </View>
        <Footer navigation={navigation} />
        {isError && <ErrorModal error={isError} setIsError={setIsError} />}
      </ScrollView>
    );
  }
};

export default ActivityScreen;
