import React, {useContext} from 'react';
import {Platform, Pressable} from 'react-native';
import {Text, Center, HStack, View, Box} from 'native-base';
import {AuthContext} from '../../components/AuthProvider';
import {widthPercentageToDP as wp} from 'react-native-responsive-screen';

import Empty from './Empty';
import {default as footerStylesMobile} from '../../styles/footer_mobile';
import {default as footerStylesDesktop} from '../../styles/footer';


const FooterWeb = ({navigation = false}) => {
  const {t, screenWidth, theme} = useContext(AuthContext);
  const footerStyles =
    screenWidth < 1080
      ? footerStylesMobile(wp, theme)
      : footerStylesDesktop(theme);
  const footerLinksDirection =
    wp('100%') < 700 ? {flexDirection: 'column'} : {flexDirection: 'row'};

    return (
    <Center>
      <View style={{...footerStyles.footerContainer, ...footerLinksDirection}}>
        <View style={footerStyles.footerLogoContainer}>
          <Text style={footerStyles.logoText}>{t('trio')}</Text>
        </View>

        <View
          style={{
            ...footerStyles.footerLinksContainer,
            ...footerLinksDirection,
          }}>
          <View style={footerStyles.footerLinksBlock}>
            <Pressable
              onPress={() => {
                navigation
                  ? navigation.navigate('PrivacyPolicy')
                  : console.log('navigation is null');
              }}>
              <Text style={footerStyles.statLabel}>{t('policy')}</Text>
            </Pressable>
            <Pressable
              onPress={() => {
                navigation
                  ? navigation.navigate('Contacts')
                  : console.log('navigation is null');
              }}>
              <Text style={footerStyles.statLabel}>{t('feedback')}</Text>
            </Pressable>
          </View>
          <View style={footerStyles.footerLinksBlock}>
            <Pressable
              onPress={() => {
                navigation
                  ? navigation.navigate('Contacts')
                  : console.log('navigation is null');
              }}>
              <Text style={footerStyles.statLabel}>{t('contacts')}</Text>
            </Pressable>
            <Pressable
              onPress={() => {
                navigation
                  ? navigation.navigate('About')
                  : console.log('navigation is null');
              }}>
              <Text style={footerStyles.statLabel}>{t('about')}</Text>
            </Pressable>
          </View>
        </View>
      </View>
      <Empty style={footerStyles.empty} />
    </Center>
  );
};

const FooterMobile = () => {
  const footerStyles = footerStylesDesktop;
  return (
    <Center>
      <HStack style={footerStyles.footerContainer}>
        <Empty style={footerStyles.empty} />
      </HStack>
    </Center>
  );
};

// const Footer = Platform.OS === 'web' ? FooterWeb : FooterMobile;
const Footer = FooterWeb; // Вернул футер и для мобильных
export default Footer;
