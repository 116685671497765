import * as React from 'react';
import {useEffect, useState, useContext, useCallback} from 'react';
import {
  StyleSheet,
  TextInput,
  View,
  ActivityIndicator,
  Platform,
  Pressable,
} from 'react-native';
import {Text, HStack} from 'native-base';
import {Picker} from '@react-native-picker/picker';
import {AuthContext} from '../../components/AuthProvider';
import AxiosInstance from '../../components/common/axiosInstance';
import {
  widthPercentageToDP as wp,
  heightPercentageToDP as hp,
} from 'react-native-responsive-screen';

import Header from '../../components/common/AdminHeader';
import ErrorModal from '../../components/common/ErrorModal';
import InfoModal from '../../components/common/InfoModal';

import commonStylesF from '../../styles/common';
import adminStyles from '../../styles/admin';

import Config from '../../config/config';

const AddMessage = ({navigation, setAddMessage, setNeedUpdate, _message}) => {
  const {user, logout, t, theme} = useContext(AuthContext);
  const [isError, setIsError] = useState(false);
  const [info, setInfo] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState({
    type: '',
    channel: '',
    announce: '',
    message: '',
    button_name: '',
    lang: '',
  });

  const commonStyles = commonStylesF(wp, hp, theme);

  const createMessage = async () => {
    if (!message.type) {
      setInfo(t('empty_message_type'));
      return false;
    }
    if (!message.channel) {
      setInfo(t('empty_message_channel'));
      return false;
    }
    if (!message.message) {
      setInfo(t('empty_message_content'));
      return false;
    }
    if (!message.lang) {
      setInfo(t('empty_message_lang'));
      return false;
    }

    setIsLoading(true);
    try {
      const api = AxiosInstance({user, logout});
      const response = await api(`${Config.API_URL}/api/v1/messages`, {
        method: 'POST',
        mode: 'cors',
        data: message,
      });
      if (response.data.code === 201) {
        setAddMessage(false);
        setNeedUpdate(true);
      } else {
        setIsError(JSON.stringify(response.data));
      }
    } catch (error) {
      setIsError(error);
    } finally {
      setIsLoading(false);
    }
  };

  const updateMessage = async () => {
    console.log(message);
    if (!message.type) {
      setInfo(t('empty_message_type'));
      return false;
    }
    if (!message.channel) {
      setInfo(t('empty_message_channel'));
      return false;
    }
    if (!message.message) {
      setInfo(t('empty_message_content'));
      return false;
    }
    if (!message.lang) {
      setInfo(t('empty_message_lang'));
      return false;
    }

    setIsLoading(true);
    try {
      const api = AxiosInstance({user, logout});
      const response = await api(
        `${Config.API_URL}/api/v1/messages/${_message.id}`,
        {
          method: 'PUT',
          mode: 'cors',
          data: message,
        }
      );
      if (response.data.status === 'success') {
        setAddMessage(false);
        setNeedUpdate(true);
      } else {
        setIsError(JSON.stringify(response.data));
      }
    } catch (error) {
      setIsError(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (_message) {
      setMessage(_message);
    }
  }, [_message]);

  return (
    <View style={commonStyles.container}>
      <Header navigation={navigation} />
      <View style={commonStyles.contentContainer}>
        <View style={commonStyles.contentHead}>
          <Text style={commonStyles.contentHeadText}>{_message ? t('edit_message') : t('create_message')}</Text>
        </View>
        <HStack style={adminStyles.row}>
          <Text style={adminStyles.key}>{t('message_type')}</Text>
          <Picker
            selectedValue={message.type}
            style={{height: 50, width: 150}}
            onValueChange={(itemValue, itemIndex) =>
              setMessage({...message, type: itemValue})
            }>
            <Picker.Item label="-" value="" />
            <Picker.Item label={t('admin_invite')} value="invite" />
            {/** сообщение юзеру о завершении тройки */}
            <Picker.Item label={t('admin_trio_completed')} value="trio_completed" />
            {/** сообщение админу о формировании новой заявки на выплату */}
            <Picker.Item label={t('admin_withdraw_request')} value="withdraw_requested" />
            {/** сообщение юзеру о формировании им новой заявки на выплату */}
            <Picker.Item label={t('admin_withdraw_request_to_user')} value="withdraw_request" />
             {/** сообщение юзеру о выполнени его заявки на выплату */}
            <Picker.Item label={t('admin_withdraw_completed')} value="withdraw_completed" />
            {/** сообщение на email с кодом для верификации email при регистрации */}
            <Picker.Item label={t('registration_verification_code')} value="registration_verification_code" />
            {/** сообщение на email с кодом для верификации при сбросе пароля */}
            <Picker.Item label={t('pwd_restore_verification_code')} value="pwd_restore_verification_code" />
          </Picker>
        </HStack>
        <HStack style={adminStyles.row}>
          <Text style={adminStyles.key}>{t('message_channel')}</Text>
          <Picker
            selectedValue={message.channel}
            style={{height: 50, width: 150}}
            onValueChange={(itemValue, itemIndex) =>
              setMessage({...message, channel: itemValue})
            }>
            <Picker.Item label="-" value="" />
            <Picker.Item label="SMS" value="sms" />
            <Picker.Item label="Email" value="email" />
            <Picker.Item label={t('admin_messenger')} value="messenger" />
          </Picker>
        </HStack>
        <HStack style={adminStyles.row}>
          <Text style={adminStyles.key}>{t('message_subject')}</Text>
          <TextInput
            style={adminStyles.text}
            value={message.announce}
            onChangeText={text => setMessage({...message, announce: text})}
          />
        </HStack>
        <HStack style={adminStyles.row}>
          <Text style={adminStyles.key}>{t('message_content')} *</Text>
          <TextInput
            style={adminStyles.textArea}
            multiline={true}
            numberOfLines={4}
            value={message.message}
            onChangeText={text => setMessage({...message, message: text})}
          />
        </HStack>
        <HStack style={adminStyles.row}>
          <Text style={adminStyles.key}>{t('viber_button')}</Text>
          <TextInput
            style={adminStyles.text}
            value={message.button_name}
            onChangeText={text => setMessage({...message, button_name: text})}
          />
        </HStack>
        <HStack style={adminStyles.row}>
          <Text style={adminStyles.key}>{t('message_language')}</Text>
          {/* <Text style={adminStyles.value}></Text> */}
          <Picker
            selectedValue={message.lang}
            style={{height: 50, width: 60}}
            onValueChange={(itemValue, itemIndex) =>
              setMessage({...message, lang: itemValue})
            }>
            <Picker.Item label="-" value="" />
            <Picker.Item label="UA" value="ua" />
            <Picker.Item label="RU" value="ru" />
            <Picker.Item label="EN" value="en" />
          </Picker>
        </HStack>
        <HStack style={commonStyles.btnGroup}>
          <Pressable
            style={commonStyles.btnCommonBig}
            onPress={() => setAddMessage(false)}>
            <Text style={commonStyles.btnCommonTxtBig}>{t('back_to_messages')}</Text>
          </Pressable>
          <Pressable
            style={commonStyles.btnCommonBig}
            onPress={() => (_message ? updateMessage() : createMessage())}>
            <Text style={commonStyles.btnCommonTxtBig}>{t('save_message')}</Text>
          </Pressable>
        </HStack>
      </View>
      {isLoading === true && (
        <ActivityIndicator
          style={styles.activityIndicator}
          color="#0C090D"
          size="large"
        />
      )}
      {isError && <ErrorModal error={isError} setIsError={setIsError} />}
      {info && <InfoModal info={info} setInfo={setInfo} />}
    </View>
  );
};

const styles = StyleSheet.create({
  activityIndicator: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'transparent', // Прозрачный фон
  },
});

export default AddMessage;
