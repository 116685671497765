import React, {useContext, useState, useEffect} from 'react';
import {
  View,
  Pressable,
  Platform,
  ScrollView,
  StyleSheet,
  Image,
} from 'react-native';
import {Text, HStack} from 'native-base';
import {Card, Badge, DataTable} from 'react-native-paper';
import {
  widthPercentageToDP as wp,
  heightPercentageToDP as hp,
} from 'react-native-responsive-screen';
import JSONPretty from 'react-json-pretty';
import JSONTree from 'react-native-json-tree';
import {AuthContext} from '../../components/AuthProvider';
import Header from '../../components/common/Header';
import Footer from '../../components/common/Footer';
import Loader from '../../components/common/Loader';
import ErrorModal from '../../components/common/ErrorModal';

import ArrowLeft from '../../assets/img/arrow_left_white.svg';

import {default as commonStylesF} from '../../styles/common';
import {default as commonStylesMobileF} from '../../styles/common_mobile';
import {default as activityStylesF} from '../../styles/activity';
import {default as activityStylesMobileF} from '../../styles/activity_mobile';

const ActivityDetails = ({navigation, route}) => {
  const {t, screenWidth, theme, addHistory, screenParams, setScreenParams} =
    useContext(AuthContext);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(null);
  const [prevScreenWidth, setPrevScreenWidth] = useState(screenWidth);
  const [activeHistory, setActiveHistory] = useState(screenParams);

  let commonStyles =
    screenWidth < 1080
      ? commonStylesMobileF(wp, hp, theme)
      : commonStylesF(wp, hp, theme);
  let activityStyles =
    screenWidth < 1080
      ? activityStylesMobileF(wp, hp, theme)
      : activityStylesF(wp, hp, theme);

  useEffect(() => {
    setIsLoading(false);
  }, []);

  useEffect(() => {
    setPrevScreenWidth(screenWidth);
  }, [screenWidth]);

  useEffect(() => {
    if (Platform.OS !== 'web') {
      // console.log('ActivityDetails', route);
      addHistory(route.name);
    }
  }, [addHistory, route]);

  if (isLoading) {
    return <Loader />;
  } else {
    return (
      <ScrollView contentContainerStyle={commonStyles.container}>
        <Header navigation={navigation} route={route} />
        <ScrollView contentContainerStyle={styles.scrollViewContainer}>
          <View style={{...commonStyles.contentContainer, marginBottom: 20}}>
            <View style={commonStyles.headerContainer}>
              <Pressable
                style={commonStyles.headBackButton}
                onPress={() => {
                  setScreenParams({});
                  Platform.OS === 'web'
                  ? navigation.replace('History')
                  : navigation.navigate('History')
                }}
              >
                {Platform.OS === 'web' ? (
                  <Image source={ArrowLeft} style={commonStyles.arrowLeft} />
                ) : (
                  <ArrowLeft style={commonStyles.arrowLeft} />
                )}
              </Pressable>
              <View style={commonStyles.contentHead}>
                <Text style={commonStyles.contentHeadText}>
                  {t('activity')} {activeHistory.id}
                </Text>
              </View>
            </View>
            <HStack style={activityStyles.row}>
              <Text style={activityStyles.key}>{t('date')}</Text>
              <Text style={activityStyles.value}>
                {activeHistory.timestamp}
              </Text>
            </HStack>
            <HStack style={activityStyles.row}>
              <Text style={activityStyles.key}>{t('ip')}</Text>
              <Text style={activityStyles.value}>
                {activeHistory.ipaddress}
              </Text>
            </HStack>
            <HStack style={activityStyles.row}>
              <Text style={activityStyles.key}>{t('method')}</Text>
              <Text style={activityStyles.value}>{activeHistory.method}</Text>
            </HStack>
            <HStack style={activityStyles.row}>
              <Text style={activityStyles.key}>URI</Text>
              <Text style={activityStyles.value}>{activeHistory.uri}</Text>
            </HStack>
            {Platform.OS === 'web' ? (
              <>
                <HStack style={activityStyles.row}>
                  <Text style={activityStyles.key}>{t('request')}</Text>
                  <JSONPretty
                    style={activityStyles.value}
                    data={JSON.parse(activeHistory.request)}
                  />
                </HStack>
                <HStack style={activityStyles.row}>
                  <Text style={activityStyles.key}>{t('response')}</Text>
                  <JSONPretty
                    style={activityStyles.value}
                    data={activeHistory.response}
                    stringStyle="max-width: 450px; overflow: scroll; display: inline-block;"></JSONPretty>
                </HStack>
              </>
            ) : (
              <>
                <HStack style={activityStyles.row}>
                  <Text style={activityStyles.key}>{t('request')}</Text>
                  <JSONTree
                    data={JSON.parse(activeHistory.request)}
                    style={activityStyles.value}
                    shouldExpandNode={false}
                  />
                </HStack>
                <HStack style={activityStyles.row}>
                  <Text style={activityStyles.key}>{t('response')}</Text>
                  <JSONTree
                    data={JSON.parse(activeHistory.response)}
                    style={activityStyles.value}
                    shouldExpandNode={false}
                  />
                </HStack>
              </>
            )}
          </View>
        </ScrollView>
        <Footer navigation={navigation} />
        {isError && <ErrorModal error={isError} setIsError={setIsError} />}
      </ScrollView>
    );
  }
};

const styles = StyleSheet.create({
  scrollViewContainer: {
    //   flex: 1,
    alignItems: 'center', // Выравнивание по горизонтали по центру
  },
});

export default ActivityDetails;
