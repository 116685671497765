import {Platform, StyleSheet} from 'react-native';

const platformStyles = {
  web: (wp, hp) =>
    StyleSheet.create({
      contentHead: {
        marginTop: 10,
      },
      btnGroup: {
        marginTop: 31,
        gap: 12,
        marginBottom: 32,
        flex: 1,
      },
      btnProfile: {
        width: 184,
        height: 39,
        paddingHorizontal: 40,
        paddingVertical: 10,
        borderWidth: 1,
        borderStyle: 'solid',
        borderRadius: 10,
        borderColor: '#000000',
        // backgroundColor: '#D9D9D9',
        alignItems: 'center',
        justifyContent: 'center',
      },
      btnProfileText: {
        color: '#000000',
      },
      divider: {
        flex: 1,
      },
    }),
  android: (wp, hp) =>
    StyleSheet.create({
      contentHead: {
        marginTop: 22,
      },
      btnGroup: {
        marginTop: 31,
        gap: 12,
        marginBottom: 80,
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
        width: wp('80%'),
      },
      btnProfile: {
        width: 140,
        height: 30,
        paddingHorizontal: 5,
        paddingVertical: 5,
        borderWidth: 1,
        borderStyle: 'solid',
        borderRadius: 10,
        borderColor: '#000000',
        color: '#000000',
        // backgroundColor: '#D9D9D9',
        alignItems: 'center',
        justifyContent: 'center',
      },
      btnProfileText: {
        color: '#000000',
      },
      divider: {
        flex: 1,
      },
    }),
  ios: (wp, hp) =>
    StyleSheet.create({
      contentHead: {
        marginTop: 22,
      },
      btnGroup: {
        marginTop: 31,
        gap: 12,
        marginBottom: 80,
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
        width: wp('80%'),
      },
      btnProfile: {
        width: 140,
        height: 30,
        paddingHorizontal: 5,
        paddingVertical: 5,
        borderWidth: 1,
        borderStyle: 'solid',
        borderRadius: 10,
        borderColor: '#000000',
        color: '#000000',
        // backgroundColor: '#D9D9D9',
        alignItems: 'center',
        justifyContent: 'center',
      },
      btnProfileText: {
        color: '#000000',
      },
      divider: {
        flex: 1,
      },
    }),
};

export default platformStyles[Platform.OS];
