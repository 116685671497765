// Нужен только для привязки событий на изменение ориентации экрана и только для веб версии при ширине экрана < 1080
import React from 'react';
import { View } from 'react-native';
import {
  listenOrientationChange as lor,
  removeOrientationListener as rol
} from 'react-native-responsive-screen';

class Empty extends React.Component {
  componentDidMount() {
    lor(this);
    console.log('componentWillMount');
  }

  // componentWillUnmount() {
  //   rol();
  //   console.log('componentWillUnmount');
  // }

  render() {
    return (
      <></>
    );
  }
}

export default Empty;