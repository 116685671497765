// AuthProvider.js
import React, {createContext, useState, useEffect} from 'react';
import {Dimensions, Platform} from 'react-native';
import AsyncStorage from '@react-native-async-storage/async-storage';
import axios from 'axios';
import AxiosInstance from './common/axiosInstance';
import {useTranslation} from 'react-i18next';
import '../i18n';

import {default as theme} from '../config/theme_config.js';

import Loader from './common/Loader';
import Config from '../config/config.js';

const AuthContext = createContext();

const AuthProvider = ({children}) => {
  const {t, i18n} = useTranslation();
  const [user, setUser] = useState(null);
  const [inputedData, setInputedData] = useState({
    phone: '',
    password: '',
    email: '',
    role: 1,
    tmpJwt: '',
    userId: 0,
  });
  const [loading, setLoading] = useState(true);
  const [errors, setErrors] = useState(null);
  const [passwordVerifying, setPasswordVerifying] = useState(false);
  const [emailCreating, setEmailCreating] = useState(false);
  const [activeTrio, setActiveTrio] = useState(null);
  const [activeReferal, setActiveReferal] = useState(null);
  const [activeOrder, setActiveOrder] = useState(null);
  const [activeHistory, setActiveHistory] = useState(null);
  const [breadcrumbs, setBreadcrumbs] = useState([]);
  const [screenWidth, setScreenWidth] = useState(
    Dimensions.get('window').width
  );
  const [browseHistory, setBrowseHistory] = useState([]);
  const [screenParams, setScreenParams] = useState({});

  const resetAllStates = () => {
    setErrors(null);
    setActiveTrio(null);
    setActiveReferal(null);
    setActiveOrder(null);
    setActiveHistory(null);
    setBreadcrumbs([]);
  };

  const pushBreadcrumb = breadcrumb => {
    setBreadcrumbs(prevBreadcrumbs => [...prevBreadcrumbs, breadcrumb]);
  };

  const popBreadcrumb = () => {
    setBreadcrumbs(prevBreadcrumbs =>
      prevBreadcrumbs.slice(0, prevBreadcrumbs.length - 1)
    );
  };

  const regenerateBreadcrumbs = index => {
    setBreadcrumbs(prevBreadcrumbs => prevBreadcrumbs.slice(0, index + 1));
  };

  const sendSms = async phone => {
    // setLoading(true);
    // setErrors(null);
    // Make a request to the login endpoint
    let result;
    try {
      let response = await axios.post(`${Config.API_URL}/api/sendsms`, {
        phone: phone,
      });
      console.log('sendSms response', response);
      setInputedData({...inputedData, phone: phone});
      if (response && response.data && response.data.message && response.data.message.status === 'success') {
        result = {result: true, response: response};
      } else {
        result = {result: false, response: response};
      }
    } catch (error) {
      if (error.response) {
        console.log('error.response', error.response.data.message);
        setErrors(error.response.data.message);
        result = {result: false, response: error.response.data.message};
      } else if (error.request) {
        console.log('error.request', error);
        setErrors(error.message);
      } else {
        console.log('unknown error', error);
        setErrors(error);
      }
      setInputedData({...inputedData, phone: phone});
      // result = {result: false, response: error};
    } finally {
      // Mark loading as false
      // setLoading(false);
      return result;
    }
  };
  // Function to log in the user

  const login = async (phone, password, nav) => {
    // Make a request to the login endpoint
    await axios
      .post(`${Config.API_URL}/api/login`, {
        phone,
        password,
      })
      .then(async response => {
        const userData = response.data.message;

        if (userData.activated !== undefined && userData.activated === 1) {
          await AsyncStorage.setItem('user', JSON.stringify(userData));
          console.log('setUser');
          setBrowseHistory([]);
          setUser(userData);
          // nav.navigate('Login');
        } else {
          // просим указать email и ввести пароль
          setInputedData(prevState => {
            return {...prevState, token: userData.Authorization};
          });
          console.log('setEmailCreating');
          nav.navigate('EmailCreating');
        }
        return true;
      })
      .catch(error => {
        if (error.response) {
          console.log('error.response', error.response);
          setErrors(t(error.response.data.message));
        } else if (error.request) {
          console.log('error.request', error.message);
          setErrors(error.message);
        } else {
          console.log('unknown error', error);
          setErrors(t('unknown_error'));
        }
        setInputedData({phone: phone, password: password});
      })
  };

  const loginAsUser = async user_item => {
    setLoading(true);
    resetAllStates();
    const api = AxiosInstance({user, logout});
    await api(`${Config.API_URL}/api/loginAsUser`, {
      method: 'POST',
      mode: 'cors',
      data: {
        user_phone: user_item.phone,
      },
    })
      .then(async response => {
        await AsyncStorage.setItem('admin', JSON.stringify(user));
        const userData = response.data.message;
        await AsyncStorage.setItem('user', JSON.stringify(userData));
        setUser(userData);
      })
      .catch(error => {
        setErrors(JSON.stringify(error));
      })
      .finally(() => {
        setLoading(false);
      });
  };

  // Function to log out the user
  const logout = async () => {
    //   console.log('Logout');
    // Clear the user data from AsyncStorage
    await AsyncStorage.removeItem('user');
    // Reset the user state
    setErrors(null);
    setUser(null);
    setBrowseHistory([]);
  };

  const goToAdmin = async () => {
    const adminData = await AsyncStorage.getItem('admin');
    if (adminData && adminData !== 'undefined') {
      await AsyncStorage.setItem('user', adminData);
      await AsyncStorage.removeItem('admin');
      setUser(JSON.parse(adminData));
    }
  };

  const addHistory = (route, params=false) => {
    for (let i in browseHistory) {
      // console.log(browseHistory[i]);
      if (browseHistory[i] === route && params === false) {
        // console.log('browseHistory exists', browseHistory[i], route);
        return true;
      }
    }
    setBrowseHistory(prevHistory => [...prevHistory, route]);
  };

  const goBack = () => {
    // console.log('goBack', browseHistory);
    setBrowseHistory(prevHistory => prevHistory.slice(0, -1));
  };

  const lastRoute = () => {
    // console.log('lastRoute', browseHistory[browseHistory.length - 2]);
    return browseHistory[browseHistory.length - 2];
  };

  // Run the checkLoggedIn function on component mount
  useEffect(() => {
    // console.log('Auth Context useEffect');
    const checkLoggedIn = async () => {
      try {
        // Check AsyncStorage for user data
        const userData = await AsyncStorage.getItem('user');
        if (userData && userData !== 'undefined') {
          setUser(JSON.parse(userData));
        }
      } catch (error) {
        setErrors(error);
        console.error('Error checking logged in:', error);
      } finally {
        // Mark loading as false
        setLoading(false);
      }
    };
    checkLoggedIn();
  }, []);

  // Тут обработчики событий на изменение размеров/ориентации экрана
  useEffect(() => {
    const updateScreenDimensions = () => {
      if (Platform.OS !== 'web') {
        console.log('updateScreenDimensions ', Dimensions.get('window').width);
        setScreenWidth(Dimensions.get('window').width);
      } else {
        console.log('updateScreenDimensions ', window.innerWidth);
        setScreenWidth(window.innerWidth);
      }
    };

    if (Platform.OS !== 'web') {
      // console.log('updating screenWidth to ', Dimensions.get('window').height, 'Platform: ', Platform.OS);
      Dimensions.addEventListener('change', updateScreenDimensions);
    } else {
      console.log(
        'addEventListener resize',
        window.innerWidth,
        'Platform: ',
        Platform.OS
      );
      window.addEventListener('resize', updateScreenDimensions);
    }

    return () => {
      if (Platform.OS !== 'web') {
        // Dimensions.removeEventListener('change', updateScreenDimensions);
      } else {
        console.log(
          'removeEventListener resize',
          window.innerWidth,
          'Platform: ',
          Platform.OS
        );
        window.removeEventListener('resize', updateScreenDimensions);
      }
    };
  }, []);

  if (loading) {
    return <Loader />;
  }
  // Render the AuthContext.Provider with the necessary values
  return (
    <AuthContext.Provider
      value={{
        Config,
        user,
        setUser,
        activeTrio,
        setActiveTrio,
        activeReferal,
        setActiveReferal,
        activeOrder,
        setActiveOrder,
        loading,
        setLoading,
        errors,
        setErrors,
        inputedData,
        setInputedData,
        passwordVerifying,
        setPasswordVerifying,
        emailCreating,
        setEmailCreating,
        sendSms,
        login,
        logout,
        breadcrumbs,
        pushBreadcrumb,
        popBreadcrumb,
        regenerateBreadcrumbs,
        setBreadcrumbs,
        activeHistory,
        setActiveHistory,
        loginAsUser,
        goToAdmin,
        t,
        i18n,
        screenWidth,
        setScreenWidth,
        theme,
        browseHistory,
        addHistory,
        goBack,
        lastRoute,
        screenParams,
        setScreenParams,
      }}>
      {children}
    </AuthContext.Provider>
  );
};

export {AuthProvider, AuthContext};
