import {StyleSheet} from 'react-native';

const styles = (wp, theme) =>
  StyleSheet.create({
    footerContainer: {
      // flex: 1,
      flexWrap: 'wrap',
      width: wp('100%'),
      maxWidth: 1080,
      // height: 280,
      backgroundColor: theme.BLACK,
      justifyContent: 'center',
      alignItems: 'flex-start',
      paddingBottom: 40,
    },
    footerLogoContainer: {
      flex: 2,
      width: 150,
      // height: 50,
      paddingTop: 40,
      paddingLeft: 30,
    },
    footerLinksContainer: {
      flex: 7,
    },
    footerLinksBlock: {
      wordWrap: 'break-word',
      marginTop: 30,
      paddingLeft: 30,
      gap: 10,
    },
    logoText: {
      fontFamily: theme.FONT_FAMILY,
      fontSize: 25,
      lineHeight: 25,
      fontWeight: 'bold',
      color: theme.FONT_COLOR_WHITE,
    },
    statLabel: {
      color: theme.FONT_COLOR_WHITE,
      fontFamily: theme.FONT_FAMILY,
      fontSize: 16,
      fontStyle: 'normal',
      fontWeight: 'normal',
      lineHeight: 16,
    },
    empty: {
      width: 0,
      height: 0,
    },
  });

export default styles;
