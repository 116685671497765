import React, {useContext, useState, useEffect} from 'react';
import {
  View,
  Text,
  TextInput,
  StyleSheet,
  Pressable,
  ActivityIndicator,
  TouchableOpacity,
  Dimensions,
  Platform,
  KeyboardAvoidingView,
  ScrollView,
  Keyboard,
  TouchableWithoutFeedback,
  Image,
} from 'react-native';
import {KeyboardAwareScrollView} from 'react-native-keyboard-aware-scroll-view';

import {HStack} from 'native-base';
import axios from 'axios';
import Icon from 'react-native-vector-icons/FontAwesome';
import Ionicons from 'react-native-vector-icons/Ionicons';

import {
  widthPercentageToDP as wp,
  heightPercentageToDP as hp,
} from 'react-native-responsive-screen';

import {validateEmail} from '../../components/common/commonFunctions';
import ErrorModal from '../../components/common/ErrorModal';
import InfoModal from '../../components/common/InfoModal';
import Header from '../../components/common/Header';
import Footer from '../../components/common/Footer';

import HideIcon from '../../assets/img/hide.svg';
import ShowIcon from '../../assets/img/show.svg';

import {default as commonStylesMobile} from '../../styles/common_mobile';
import {default as theme} from '../../config/theme_config';

import Config from '../../config/config';
import {t} from 'i18next';
import {AuthContext} from '../../components/AuthProvider';

const ResetPasswordUpdate = ({navigation, route}) => {
  const {t, addHistory, inputedData, setInputedData} = useContext(AuthContext);

  const commonStyles = commonStylesMobile(wp, hp, theme);
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [newPassword, setNewPassword] = useState('');
  const [repeatedPassword, setRepeatedPassword] = useState('');
  const [isShowKeyboard, setIsShowKeyboard] = useState(false);
  const [error, setError] = useState(false);
  const [info, setInfo] = useState(null);
  const [loading, setLoading] = useState(false);


  const keyboardDismiss = () => {
    if (Platform.OS === 'ios') {
      Keyboard.dismiss();
    }
    return false;
  }

    const updatePassword = async () => {
    if (newPassword.length < 6) {
      setError(t('error_password_length'));
      return false;
    }

    if (newPassword !== repeatedPassword) {
      setError(t('password_mismatch'));
      return false;
    }

    setLoading(true);

    await axios
      .put(
        `${Config.API_URL}/api/v1/users/${inputedData.userId}`,
        {
          password: newPassword,
          activated: 1,
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${inputedData.tmpJwt}`,
          },
        }
      )
      .then(response => {
        console.log('update password response', response.data);
        if (response.data && response.data.status === 'success') {
          navigation.navigate('PasswordVerifying');
        } else if (response.data && response.data.status === 'error') {
          setError(response.data.message);
        }
      })
      .catch(error => {
        console.log('update password error', error);
        if (error.response !== undefined) {
          console.log('setError');
          setError(error.response.data.message);
        } else {
          setError(t('server_error'));
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <TouchableWithoutFeedback onPress={() => keyboardDismiss()}>
      <ScrollView contentContainerStyle={commonStyles.container}>
        <Header navigation={false} route={false} />
        <View style={commonStyles.contentContainer}>
          <View
            style={
              Platform.OS === 'ios' ? styles.iosContentHead : styles.contentHead
            }>
            <Text
              style={
                Platform.OS === 'ios'
                  ? styles.iosContentHeadText
                  : commonStyles.contentHeadText
              }>
              {t('password_restoring')}
            </Text>
          </View>
          <KeyboardAvoidingView
            // behavior={Platform.OS === 'ios' ? 'padding' : 'height'}
            style={styles.content}>
            <View
              style={
                Platform.OS === 'ios' ? styles.iosContent : styles.content
              }>
              {Platform.OS !== 'ios' ? (
              <>
                <Text style={styles.infoText}>
                  {t('enter_new_password_twice')}
                </Text>
                <Text style={styles.label}>{t('enter_new_password')}*</Text>
                <HStack style={styles.inputContainer}>
                  <TextInput
                    secureTextEntry={!isPasswordVisible}
                    type={'password'}
                    placeholder={t('password')}
                    value={newPassword}
                    onChangeText={text => setNewPassword(text)}
                    style={styles.phoneInput}
                  />

                  <TouchableOpacity
                    style={styles.eye}
                    onPress={() => setIsPasswordVisible(!isPasswordVisible)}>
                    {Platform.OS === 'web' ? (
                      <Image
                        source={isPasswordVisible ? HideIcon : ShowIcon}
                        style={
                          isPasswordVisible ? styles.eyeHide : styles.eyeShow
                        }
                      />
                    ) : isPasswordVisible ? (
                      <HideIcon style={styles.eyeHide} />
                    ) : (
                      <ShowIcon style={styles.eyeShow} />
                    )}
                  </TouchableOpacity>
                </HStack>
                <Text style={styles.label}>{t('repeat_password')}*</Text>
                <HStack style={styles.inputContainer}>
                  <TextInput
                    secureTextEntry={!isPasswordVisible}
                    type={'password'}
                    placeholder={t('password')}
                    value={repeatedPassword}
                    onChangeText={text => setRepeatedPassword(text)}
                    style={styles.phoneInput}
                    onFocus={() => setIsShowKeyboard(false)}
                  />
                  <TouchableOpacity
                    style={styles.eye}
                    onPress={() => setIsPasswordVisible(!isPasswordVisible)}>
                    {Platform.OS === 'web' ? (
                      <Image
                        source={isPasswordVisible ? HideIcon : ShowIcon}
                        style={
                          isPasswordVisible ? styles.eyeHide : styles.eyeShow
                        }
                      />
                    ) : isPasswordVisible ? (
                      <HideIcon style={styles.eyeHide} />
                    ) : (
                      <ShowIcon style={styles.eyeShow} />
                    )}
                  </TouchableOpacity>
                </HStack>
                <HStack style={styles.btnGroup}>
                  <Pressable
                    onPress={() => {
                      // setCodeSended(true);
                      // setCodeVerified(false);
                      navigation.navigate('ResetPasswordCodeVerification');
                    }}
                    style={styles.button}>
                    <Text style={styles.buttonText}>{t('back')}</Text>
                  </Pressable>
                  <Pressable onPress={updatePassword} style={styles.button}>
                    <Text style={styles.buttonText}>{t('send')}</Text>
                  </Pressable>
                </HStack>
              </>
              ) : (
              <View
                style={{
                  ...styles.iosContent,
                  marginBottom: isShowKeyboard ? 250 : 170,
                }}>
                <Text style={styles.infoText}>
                  {t('enter_new_password_twice')}
                </Text>
                <Text style={styles.label}>{t('enter_new_password')}*</Text>
                <HStack>
                  <TextInput
                    secureTextEntry={!isPasswordVisible}
                    type={'password'}
                    placeholder={t('password')}
                    value={newPassword}
                    onChangeText={text => setNewPassword(text)}
                    style={styles.phoneInput}
                    onFocus={() => setIsShowKeyboard(false)}
                  />
                  <TouchableOpacity
                    style={styles.eye}
                    onPress={() => setIsPasswordVisible(!isPasswordVisible)}>
                    {/* <Text>{isPasswordVisible ? t('hide') : t('show')}</Text> */}
                    <Ionicons
                      name={isPasswordVisible ? 'eye' : 'eye-off'}
                      size={20}
                      color="#000"
                    />
                  </TouchableOpacity>
                </HStack>
                <Text style={styles.label}>{t('repeat_password')}ь*</Text>
                <HStack>
                  <TextInput
                    secureTextEntry={!isPasswordVisible}
                    type={'password'}
                    placeholder={t('password')}
                    value={repeatedPassword}
                    onChangeText={text => setRepeatedPassword(text)}
                    style={styles.phoneInput}
                    onFocus={() => setIsShowKeyboard(false)}
                  />

                  <TouchableOpacity
                    style={styles.eye}
                    onPress={() => setIsPasswordVisible(!isPasswordVisible)}>
                    {/* <Text>{isPasswordVisible ? t('hide') : t('show')}</Text> */}
                    <Ionicons
                      name={isPasswordVisible ? 'eye' : 'eye-off'}
                      size={20}
                      color="#000"
                    />
                  </TouchableOpacity>
                </HStack>
                <HStack style={styles.btnGroup}>
                  <Pressable
                    onPress={() => {
                      // setCodeSended(true);
                      // setCodeVerified(false);
                      navigation.navigate('ResetPasswordCodeVerification');
                    }}
                    style={styles.button}>
                    <Text style={styles.buttonText}>{t('back')}</Text>
                  </Pressable>
                  <Pressable onPress={updatePassword} style={styles.button}>
                    <Text style={styles.buttonText}>{t('send')}</Text>
                  </Pressable>
                </HStack>
              </View>
              )}
            </View>
          </KeyboardAvoidingView>
        </View>
        <Footer />
        {error ? <ErrorModal error={error} setIsError={setError} /> : <></>}
        {info ? <InfoModal info={info} setInfo={setInfo} /> : <></>}
        {loading === true && (
          <ActivityIndicator
            style={styles.activityIndicator}
            color="#0C090D"
            size="large"
          />
        )}
      </ScrollView>
    </TouchableWithoutFeedback>
  );
};

const styles = StyleSheet.create({
  iosContentHead: {
    height: 100,
    marginTop: 50,
    padding: 0,
  },
  iosContentHeadText: {
    fontFamily: 'Inter',
    fontSize: 40,
    fontWeight: 'normal',
    lineHeight: 48,
    textAlign: 'center',
  },
  contentHead: {
    flex: 1,
    marginTop: 10,
    flexGrow: 1,
    flexShrink: 0,
    backgroundColor: theme.BLUE,
    borderRadius: 20,
    width: 250,
    maxHeight: 80,
    alignItems: 'center',
    justifyContent: 'center',
    alignSelf: 'center',
    padding: 10,
  },
  iosContent: {
    marginHorizontal: 40,
    resizeMode: 'cover',
    alignItems: 'center',
  },
  content: {
    flex: 1,
    marginTop: 28,
    width: 251,
    alignSelf: 'center',
    alignItems: 'center',
  },
  infoText: {
    paddingBottom: 20,
    fontFamily: theme.FONT_FAMILY_MEDIUM,
    color: theme.FONT_COLOR_BLACK,
    fontSize: 14,
    lineHeight: 16,
    textAlign: 'left',
  },
  label: {
    fontFamily: theme.FONT_FAMILY_MEDIUM,
    fontSize: 12,
    lineHeight: 15,
    textAlign: 'left',
    color: theme.FONT_COLOR_BLUE,
  },
  phoneInput: {
    width: 251,
    height: 42,
    padding: 10,
    borderRadius: 10,
    borderWidth: 2,
    gap: 10,
    borderColor: theme.BLACK,
    fontFamily: theme.FONT_FAMILY,
    fontSize: 20,
    lineHeight: 20,
    textAlign: 'left',
  },
  btnGroup: {
    gap: 10,
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: 20,
  },
  button: {
    marginTop: 18,
    width: 120,
    height: 40,
    paddingVertical: 10,
    paddingHorizontal: 15,
    borderRadius: 10,
    gap: 10,
    backgroundColor: theme.BLUE,
    alignItems: 'center',
    justifyContent: 'center',
  },
  buttonText: {
    fontFamily: theme.FONT_FAMILY_MEDIUM,
    color: theme.FONT_COLOR_WHITE,
    fontSize: 16,
    lineHeight: 19,
    textAlign: 'center',
  },
  activityIndicator: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'transparent', // Прозрачный фон
  },
  eyeShow: {
    width: 20,
    height: 0,
    alignItems: 'center',
    justifyContent: 'center',
    // marginLeft: -50,
  },
  eyeHide: {
    width: 21,
    height: 15,
    alignItems: 'center',
    justifyContent: 'center',
    // marginLeft: -40,
  },
  eye: {
    width: 30,
    height: 40,
    alignItems: 'center',
    justifyContent: 'center',
    marginLeft: -30,
    paddingRight: 10,
  },
});

export default ResetPasswordUpdate;